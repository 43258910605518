import React from "react";
import instagram from "../assets/images/ic_instagram.png";
import ic_linkedin from "../assets/images/ic_linkedin.svg";
import ic_fb from "../assets/images/ic_fb.svg";
import ic_youtube from "../assets/images/ic_youtube.svg";
import ic_twitter from "../assets/images/ic_twitter.svg";
import copyright from "../assets/slide/icons8-copyright-32.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <main className="main-footer ">
      
      <div className="top-div">
        <div className="term-policy">
          <label htmlFor="" className="policy">
          <a href="https://bharatkrushiseva.com/terms_condition.html" target="_blank" className="term">
           <span className="ccf">
           { t('Privacy Policy') }
           
           </span>
            
            
           
            </a>
          </label>
          <label htmlFor="" className="term">
          <a href="https://bharatkrushiseva.com/terms_condition.html" target="_blank" className="term">
         <span className="ccf">
         { t('Terms & Conditions') }
         
         </span>
            
            
            </a>
          </label>
          {/* <Link to="/About" htmlFor="" className="about"> */}
          <a href="https://bharatkrushiseva.com/about.html" target="_blank" className="about">
           <span className="ccf">
           { t('About Us') }
           
           </span>
           
            
          
            </a>
          {/* </Link> */}
        </div>
      </div>
      <div className="bottom-div">
        <div className="social-media-icons">
          <a href="https://www.facebook.com/smartfarmingappp" target="_blank">
            <img src={ic_fb} alt="facebook" className="icon-social" />
          </a>
          <a href="https://www.twitter.com/krushi_seva/" target="_blank">
            <img src={ic_twitter} alt="facebook" className="icon-social" />
          </a>
          <a href="https://www.instagram.com/bharat_krushi_seva/" target="_blank">
            <img src={instagram} alt="facebook" className="icon-social" />
          </a>
          <a href="https://www.youtube.com/@bharatkrushiseva-smartfarm4253" target="_blank">
            <img src={ic_youtube} alt="facebook" className="icon-social" />
          </a>
          <a href="https://www.linkedin.com/company/bharat-krushi-seva" target="_blank">
            <img src={ic_linkedin} alt="facebook" className="icon-social" />
          </a>
        </div>
        <div>

        <a href="https://bharatkrushiseva.com/" target="_blank" className="">
          <label htmlFor="" className="ccf">
          <img src={copyright} alt="facebook" className="icon-social" /> 
          { t('Powered by') }<u className="ccf">{ t('Bharat Krushi Seva') }</u> 
          </label>
          </a>
          
        
        </div>
      </div>
    </main>
  );
};

export default Footer;
