import React, {useEffect, useState } from "react";
import call from "../../assets/img/ic_call.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import ApiConfig from "../../api/ApiConfig";
import logo_new from "../../assets/slide/BKS Logo - webp-01 (1).png";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import swal from "sweetalert";
import { notifyError, notifySuccess } from "../../SharedComponents/notify";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";



function Login() {
  const navigate = useNavigate();
  // const [state, setState] = useState({ phone_number: "" , language: "en"});
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    mobile: "",
    language: "en",
  });

  const [errMsg, setErrMsg] = useState({ mobile: "" });
  const [data, setData] = useState({});



  const handleSubmit = (e) => {
  e.preventDefault();
  setLoading(true);
  if (state.mobile === "") {
    setErrMsg({ ...errMsg, mobile: t("Enter Your Mobile Number") });
    return;
  }
  else {
    postWithAuthCallWithErrorResponse(
      
      ApiConfig.LOGIN_OTP,
      JSON.stringify({ ...state, })
    )
    .then((res) => {
      setLoading(false);
      console.log("res", res.json);
      setData(res.json.data);
     
      if  (res.json.status==="true")
    {
      notifySuccess(res.json.message)
      navigate("/PasswordOTP", {
        state: { 
          message: res.json.message,
          mobile:state.mobile,
          language:state.language
         
       }
        });
    }
    else{
      notifyError(res.json.message)
      navigate("/Login")}
      // swal({
      //   title: "Success",
      //   text: "OTP sent successfully",
      //   icon: "success",
      //   button: true,
      // });
      console.log("mobile",state.mobile)
      

    })
    .catch((err) => console.log(err));
  }
  
};

const { t } = useTranslation();

useEffect(() => {}, []);
  return (
    <>
      <main className="main-login ">
        <div className="row ">
          {/* left section */}
          <div className="col-md-6 login-banner-img left">
            <div className="logo-rsponsive">
              
              <img src={logo_new} alt="" />
            </div>
            <div className="image-back">

            </div>
          </div>
          {/* right section */}
          <div className="col-md-6 login-form right">
            <div className="form-content">
              <form action="">
                <div className="top-form-text">
                  <label className="welcome-text">
                    {t("Welcome to Bharat Krushi Seva")}
                    
                  </label>
                  <p className="enter-text">{t("Enter Your Mobile Number")}</p>
                  <p className="enter-text">{t("We Will Send 4 digit Verification code")}</p>
                </div>
                <div className="email-main-login">
                  <label htmlFor="" className="email-label ">
                    {t("Mobile Number")}
                  </label>
                  <br />
                  <input
                    type="tel"
                    maxLength={10}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, mobile: "" });
                      setState({ ...state, mobile: e.target.value });
                    }}
                  className="email-input" 
                  placeholder={t("Enter Your Mobile Number")}
                   />
                  <span className="span-eye position-relative">
                  <img src={call} alt="" className="eye-icon" />
                  </span>
                </div>
                {
                  <div className="text-center text-danger">
                    <span> {errMsg.mobile}</span>
                  </div>
                }
                {/* <Link to="/PasswordOTP" onClick={handleSubmit}> */}
                <Link to="#" onClick={handleSubmit}>
                  <button className="login-button ">
                 
                    {t("GET OTP")}

                    {loading ? (
                    <div
                      class="spinner-border"
                      style={{ textAlign: "right", color: "#FFFBF3" }}
                    />
                  ) : (
                    ""
                  )}
                    {/* GET OTP */}
                    </button>
                </Link>

                <div className="d-flex justify-content-center ">
                <a href="https://bharatkrushiseva.com/" target="_blank" className="">
                  {/* <p className="have-account">
                    {t("By Clicking Continue You agree to our Terms of Use and acknowledge that you read our Privacy Policy")}
                    </p> */}
                    </a>
                  {/* <Link to="/Registration">
                  
                  <p className="register ">Register</p>
                  </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      
  
    </>
  );
}

export default Login;
