import React, { useContext, useEffect } from "react";
import Footer from "../SharedComponents/Footer";
import Navbar from "../SharedComponents/Navbar";
import ic_cart from "../assets/images/ic_cart.svg";
import ic_heart from "../assets/images/ic_heart.svg";
import ic_heart_active from "../assets/images/ic_heart_active.svg";
import offer2 from "../assets/img/offer2.jpg";
import offer1 from "../assets/img/offer1.jpg";
import offer3 from "../assets/img/offer3.jpg";
import offer4 from "../assets/img/offer4.jpg";
import { Link, NavLink } from "react-router-dom";

import { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { render } from "react-dom";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { AppContext } from "../context/AppContext ";
import { notifyError, notifySuccess } from "../SharedComponents/notify";
import { ScaleLoader } from "react-spinners";

import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import StarRating from './StarRating';

const Wishlist = () => {
  const [wishlist, setWishList] = useState(null);
  const [loading, setLoading] = useState(false);
  

  const image_url="http://bharatkrushiseva.in"
  const {customerData} = useContext(AppContext);
  console.log("customerData",customerData)

  const [wishlist_data, setWishlist_data] = useState([]);
    console.log("wishlist_data",wishlist_data)

    const lang = localStorage.getItem("lang")
    console.log("lang",lang)
    const [state, setState] = useState({
      token: customerData.token,
      language:lang
      // notify_type:"order"

    });
    useEffect(() => {
      getuser_wishlist();
     
    }, []);
    const getuser_wishlist = () => {
      setLoading(true)
      postWithAuthCallWithErrorResponse(
        ApiConfig.GET_USER_WISHLIST_PRODUCT,
        JSON.stringify({ ...state, })
      ) 
      .then((res) => {
        setLoading(false)
        console.log("wishlist", res.json);
        setWishlist_data(res.json.product_list);
       
      })
      .catch((err) => console.log(err));

    };

    const getWishlistId = (id) => {
      postWithAuthCallWithErrorResponse(
        ApiConfig.REMEVE_PRODUCT_FROM_USER_WISHLIST,
        JSON.stringify({ wishlist_id:id, token:customerData.token })
      
      ).then((res) => {
        console.log("wishlist_id", res.json);
       
        if  (res.json.status==="true")
          {
            notifySuccess(res.json.message)
            refresh();
          }
          else{
            notifyError(res.json.message)
          }
      })
      .catch((err) => console.log(err));

    };

const { t } = useTranslation();

useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])
  

const refresh = () => window.location.reload(true)
    


  return (
    <>
      <Navbar />
      <main className="">
        

        <div className="offers-bg">
        <div className="top-heading">
                <label htmlFor="" className="heading-black">
                  {t("Shop By Wishlist")}
                </label>{" "}
                
                <p className="paragraph">
                 {t("Shop from top Offers across the world")}
                </p>
              </div>
              {loading ?
                   (
      <ScaleLoader

        color={"#E27A7A"}
        loading={loading}
        // size={10}
        className="loading setloader"
      />
    ) : (
          <div className=" inner-card-section  ">
          {  wishlist_data &&
            wishlist_data.length > 0 ?   wishlist_data &&
            wishlist_data.map((user_wishlist_data) => {
              return (
                console.log("user_wishlist_data",user_wishlist_data.product.id),
                (
                  <div>
            <div className="offer-card ">
              <div className=" offer-card-inner">
                <div className="img-card">
                  <img src={image_url+user_wishlist_data.product.product_image} alt="" className="" />
                </div>
                <div className="offer-content ">
                  <p className="card-title"></p>
                  <p className="first-purchase ">
                    {user_wishlist_data.product.product_name}
                  </p>
                  <div className="text_set">
                  <div className="rating-container">

                  <StarRating rating={user_wishlist_data.product.average_rating} />
                  &nbsp;
                  <b className="rating-container1" class="btn btn-warning mrc3">
                  {user_wishlist_data.product.average_rating}
                  </b> 
                  </div>
                  </div>
                  <span className="current-date ">
                    <s>{user_wishlist_data.product.grand_total_without_discount} Rs</s>&nbsp;&nbsp;&nbsp; {user_wishlist_data.product.grand_total}
                  </span>

                  <div>
                    {user_wishlist_data.product.is_bks_assured === 1 ? (
                      <label className="text_settag">{user_wishlist_data.product.brand_tag}</label>
                      ) : null}
                  </div>
                  
                  <div>
                    <label className="exp-date ">
                    <span className="current-date ">
                      {user_wishlist_data && user_wishlist_data.product && user_wishlist_data.product.item_specification &&  user_wishlist_data.product.item_specification[0].spec_title}:-
                    </span>
                      {user_wishlist_data && user_wishlist_data.product && user_wishlist_data.product.item_specification && user_wishlist_data.product.item_specification[0].spec_desc}

                    </label>
                  </div>
                  
                  {/* <div>
                    <label className="exp-date ">
                    <span className="current-date ">
                    {user_wishlist_data && user_wishlist_data.product && user_wishlist_data.product.item_specification &&  user_wishlist_data.product.item_specification[1].spec_title}
                    </span >:-
                    {user_wishlist_data.product.item_specification[1].spec_desc}

                    </label>
                  </div> */}
                  

                  <div className="d-flex justify-content-between mt-3">
                    <div>
                      <label className="exp-date tac">{t("Brand Name")}</label>
                      <br />
                      <span className="current-date ">{user_wishlist_data.product.brand_names}</span>
                    </div>
                    <div>
                    <label className="exp-date tac">{t("Product Type")}</label>
                      <br />
                      <span className="current-date ">{user_wishlist_data.product.product_type}</span>
                      {/* <label className="exp-date tac">Rating</label>
                      <span className="current-date ">
                        <ReactStars
                          count={5}
                          size={24}
                          activeColor="#ffd700"
                        />
                      </span> */}
                    </div>
                      
                    <div>
                    <label className="exp-date tac">{t("Category Type")}</label>
                      <br />
                      <span className="current-date ">{user_wishlist_data.product.category.category_name}</span>
                      
                    </div>
                    
                    
                    <div>
                      <label className="exp-date "></label>
                      <br />
                      <span className="current-date ">
                      <Link to={`/Detailslist/${user_wishlist_data.product.id}`} state={user_wishlist_data.product.id}>
                          <button className="grab-btn " style={{ width: "120px" }}>
                            {t("Add to Cart")}
                          </button>
                        </Link>
                      </span>
                    </div>
                    <div>
                      <label className="exp-date "></label>
                      <br />
                      <span className="current-date ">
                      <Link to={`/Detailslist/${user_wishlist_data.product.id}`} state={user_wishlist_data.product.id}>
                          <button className="grab-btn " style={{ width: "120px" }}>
                            {t("Buy Now")}
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              
              <div className="btn-offer-grab">
                <div className="mb-4 text-end">
                <Link to="#" onClick={() => getWishlistId(user_wishlist_data.id)}>
                  <img src={ic_heart_active} className="me-3" />
                  </Link>
                </div>
              </div>
            </div>
           <hr/>
            </div>

                )
                );
                }) 
                : 
                <div className="textset"> {t("No Data avaliable in Wishlist")} </div>
                } 


            
          </div>
    )}


         

          

        </div>


        


        {/* <div className="wish-card">
          {wishlist &&
            wishlist.map((userWishList) => {
              return (
                console.log(userWishList),
                (
                  <div className="tab-card ">
                    <div className="image">
                      <img src={userWishList.product.images[0]} alt="" />
                    </div>
                    <div className="product-detail">
                      <p className="detail-top">{userWishList.product.name}</p>
                      <p>
                        <label htmlFor="" className="number">
                        {userWishList.product.number}
                        </label>
                      </p>                     
                      <div className="amt-add-card-main">
                        <div className="price"></div>
                        <div className="like-img">
                          <img src={ic_heart_active} onClick={()=>WishList(userWishList.product.id)} alt="" />
                          <img src={ic_cart} alt="" />
                        </div>
                      </div>
                      <div className="red-line"></div>
                    </div>
                  </div>
                )
              );
            })}
         
        </div> */}
      </main>
      <Footer />
    </>
  );
};

export default Wishlist;