import React, { useContext, useEffect, useState } from "react";
import Footer from "../../SharedComponents/Footer";
import Navbar from "../../SharedComponents/Navbar";
import ReactStars from "react-rating-stars-component";

import offer2 from "../../assets/img/offer2.jpg";
import offer4 from "../../assets/img/offer4.jpg";
import { Link } from "react-router-dom";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext ";
import { ScaleLoader } from "react-spinners";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import StarRating from "../StarRating";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { notifyError, notifySuccess } from "../../SharedComponents/notify";
import Modal from 'react-bootstrap/Modal';

import { FaSmile, FaRegSmile, FaRegThumbsUp, FaStar } from 'react-icons/fa'; // Import the desired icon from react-icons
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { postWithAuthCallWithFormDataResponse } from "../../api/ApiServices";

const MyOrders = () => {
  const [topRightModal, setTopRightModal] = useState(false);
  const toggleShow = () => setTopRightModal(!topRightModal);
  const logedIn = localStorage.getItem("logedin")
  console.log(logedIn);
  
  const image_url="http://bharatkrushiseva.in"
  const image_offer_url = "http://bharatkrushiseva.in/media/"
    const {customerData} = useContext(AppContext);
    console.log("customerData",customerData)
    const [loading, setLoading] = useState(false);

    const lang = localStorage.getItem("lang")
    console.log("lang",lang)

    const [my_order, setMyOrder] = useState([]);
    console.log("my_order",my_order)
 
    const [state, setState] = useState({
      token:customerData.token,
      language:lang,
    });
    useEffect(() => {
      getMy_Order_Data();
    }, []);
    const getMy_Order_Data= () => {
      setLoading(true)
      postWithAuthCallWithErrorResponse(
        ApiConfig.GET_USER_ORDER_LIST,
        JSON.stringify({ ...state, })
      )
      .then((res) => {
        setLoading(false)
        console.log("profile", res.json);
        setMyOrder(res.json.order_list);
       
      })
      .catch((err) => console.log(err));
    };


const { t } = useTranslation();
useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])

const statusIconMap = {
  pending: { icon: 'fa-clock', color: 'yellow' },
  confirm: { icon: 'fa-check', color: 'green' },
  accepted: { icon: 'fa-thumbs-up', color: 'blue' },
  on_hold: { icon: 'fa-pause', color: 'orange' },
  discard: { icon: 'fa-trash', color: 'red' },
  dispatch: { icon: 'fa-truck', color: 'purple' },
  in_transit: { icon: 'fa-shipping-fast', color: 'blue' },
  completed: { icon: 'fa-check-circle', color: 'green' },
  return: { icon: 'fa-undo', color: 'brown' },
  rejected: { icon: 'fa-times-circle', color: 'red' },
  denied: { icon: 'fa-ban', color: 'black' },
};

const OrderStatusIcon = ({ status }) => {
  const statusData = statusIconMap[status] || { icon: 'fa-question-circle', color: 'grey' };
  return <i className={`fa ${statusData.icon}`} aria-hidden="true" style={{ color: statusData.color }}></i>;
};



  

  // Create Review
  const [showReview, setShowReview] = useState(false);
  const handleCloseReview = () => setShowReview(false);
  const handleShowReview = () => setShowReview(true);
  const [myCreateReviewId, setMyCreateReviewId] = useState([]);
  console.log('myCreateReviewId*************',myCreateReviewId)
  console.log('customerData*************',customerData)

  const [selectedValueImoji, setSelectedImojiValue] = useState(null);
  console.log('selectedValueImoji*************',selectedValueImoji)
  const [selectedPhotoVideoValue, setSelectedPhotoVideoValue] = useState(null);
  console.log('selectedPhotoVideoValue*************',selectedPhotoVideoValue)

  const handleIconClick = (value) => {
    setSelectedImojiValue(value);
  };

  const handleIconClick1 = (value) => {
    setSelectedPhotoVideoValue(value);
  };

  const [formDataReview, setFormDataReview] = useState({
    reason: '',
    photo: null,
    video: null
  });
  
  

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormDataReview({
        ...formDataReview,
        [name]: files[0]
      });
    } else {
      setFormDataReview({
        ...formDataReview,
        [name]: value
      });
    }
  };



  const handleSubmit = () => {
    setLoading(true);
    const add_photovideo_review_state = {
     
      feedback_for: "order",
      language :"en",
      order_id: myCreateReviewId,
      feedback_note: formDataReview.reason,
      feedback_rating:selectedValueImoji,
      feedback_type:selectedPhotoVideoValue,
      feedback_images: formDataReview.photo,  // Assuming a single photo
      feedback_video: formDataReview.video,
      token: logedIn === "true" ? customerData.token : undefined,
    };
    console.log('***********',add_photovideo_review_state)
    const formData= new FormData()
    formData.append("feedback_for","order")
    formData.append("language","en")
    formData.append("order_id",myCreateReviewId)
    formData.append("feedback_note",formDataReview.reason)
    formData.append("feedback_rating",selectedValueImoji)
    formData.append("feedback_type",selectedPhotoVideoValue)

      // Conditionally append image or video
    if (selectedPhotoVideoValue === 'image') {
      formData.append("feedback_images", formDataReview.photo);
    } else if (selectedPhotoVideoValue === 'video') {
      formData.append("feedback_video", formDataReview.video);
    }
    
    formData.append("token",logedIn === "true" ? customerData.token : undefined)

    
    postWithAuthCallWithFormDataResponse(
      ApiConfig.ADD_USER_ECOM_ORDER_FEEDBACK,
      formData
    )
    .then((res) => {
      setLoading(false);
      console.log('feedback*************', res.json);
      // setFeedbackReview(res.json);
      if (res.json.status === "true") {
          notifySuccess(res.json.message)
          
        }

        else {
          notifyError(res.json.message)
        }
        handleCloseReview(); // Close the modal after submitting the report
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });

    console.log("Selected Value: ", selectedValueImoji);
    console.log("Form Data***********: ", formDataReview);
    console.log("Form Data reason***********: ", formDataReview.photo);
    // Call your API here with formData and selectedValue
    handleCloseReview();
  };
  

  return (
    <main>
      <Navbar />
        {/* model Create Review */}
        <Modal show={showReview} className="custom-modal">
                    <Modal.Header >
                    <Modal.Title>{t("Give your feedback")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="card-title">
                        <img src={`${image_offer_url}${customerData.user_image}`} className="set-text-view-more2" alt="User" />
                        &nbsp; {customerData.user_name}
                      </p>
                    </Modal.Body>
                      <p className="set-report-text">
                        {t("your feedback will be valuable for us. please give your feedback on these products")}
                       
                      </p>
                   
                        

                        <div class="form-check mt-2">
                        <Container>
                          <Row>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaSmile size={24} /> </span>
                              </div>
                              <p className="" onClick={() => handleIconClick(2)}>
                                {t("Not bad")} </p>
                              <br/>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaRegSmile size={24} onClick={() => handleIconClick(3)} /> </span>
                              </div>
                              <p className="">{t("Okay")} </p>
                              <br/>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaRegThumbsUp size={24} onClick={() => handleIconClick(4)}/> </span>
                              </div>
                              <p className="">{t("Good")} </p>
                              <br/>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaStar size={24} onClick={() => handleIconClick(5)}/> </span>
                              </div>
                              <p className="">{t("Amazing")} </p>
                              <br/>
                            </Col>
                          </Row>
                        </Container>
                        </div>
                        <hr/>

                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1">{t("What is the main reason for your rating?")}</p>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            name="reason"
                            value={formDataReview.reason}
                            onChange={handleChange}
                          />
                        </div>
                        <hr/>
                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1"> {t("Add Photo (Optional)")}</p>
                          <input
                            type="file"
                            name="photo"
                            onClick={() => handleIconClick1('image')}
                            className="btn-shop-home"
                            onChange={handleChange}
                          />
                        </div>
                        <hr/>
                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1">{t("Add Video (Optional)")}</p>
                          <input
                            type="file"
                            name="video"
                            onClick={() => handleIconClick1('video')}
                            className="btn-shop-home"
                            onChange={handleChange}
                          />
                        </div>

                      <p className="set-report-text">
                        {t("upload photos or video related to the product like unboxing, Spraying, Product Usage etc")}
                      </p>
                        
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReview}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        {t("confirm")}
                    </Button>
                    </Modal.Footer>
                </Modal>











        {/* <Modal show={showReview} >
                    <Modal.Header >
                    <Modal.Title>{t("Give your feedback")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="card-title">
                        <img src={`${image_offer_url}${customerData.user_image}`} className="set-text-view-more2" alt="User" />
                        &nbsp; {customerData.user_name}
                      </p>
                    </Modal.Body>
                      <p className="set-report-text">
                        {t("your feedback will be valuable for us. please give your feedback on these products")}
                      </p>
                   
                        

                        <div class="form-check mt-2">
                        <Container>
                          <Row>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaSmile size={24} /> </span>
                              </div>
                              <p className="" onClick={() => handleIconClick(2)}>
                                {t("Not bad")} </p>
                              <br/>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaRegSmile size={24} onClick={() => handleIconClick(3)} /> </span>
                              </div>
                              <p className="">{t("Okay")} </p>
                              <br/>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaRegThumbsUp size={24} onClick={() => handleIconClick(4)}/> </span>
                              </div>
                              <p className="">{t("Good")} </p>
                              <br/>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaStar size={24} onClick={() => handleIconClick(5)}/> </span>
                              </div>
                              <p className="">{t("Amazing")} </p>
                              <br/>
                            </Col>
                          </Row>
                        </Container>
                        </div>
                        <hr/>

                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1">{t("What is the main reason for your rating?")}</p>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            name="reason"
                            value={formDataReview.reason}
                            onChange={handleChange}
                          />
                        </div>
                        <hr/>
                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1">{t("Add Photo (Optional)")}</p>
                          <input
                            type="file"
                            name="photo"
                            onClick={() => handleIconClick1('image')}
                            className="btn-shop-home"
                            onChange={handleChange}
                          />
                        </div>
                        <hr/>
                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1">{t("Add Video (Optional)")}</p>
                          <input
                            type="file"
                            name="video"
                            onClick={() => handleIconClick1('video')}
                            className="btn-shop-home"
                            onChange={handleChange}
                          />
                        </div>

                      <p className="set-report-text">
                        {t("upload photos or video related to the product like unboxing, Spraying, Product Usage etc")}
                      </p>
                        
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReview}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        {t("confirm")}
                    </Button>
                    </Modal.Footer>
                </Modal> */}


      <div className="offers-bg">
        <div className="top-heading">
          <label htmlFor="">
           {t("My Order")}
          </label>
          <p className="paragraph">
            {t("Order History")}
          </p>
        </div>
        {loading ?
                   (
      <ScaleLoader

        color={"#E27A7A"}
        loading={loading}
        // size={10}
        className="loading setloader"
      />
    ) : (
        <div>
        
        { my_order &&
            my_order.length > 0 ?  my_order &&
            my_order.map((order_list_get) => {
              return (
                console.log("order_list",order_list_get.id),
              
                (
        <div className=" inner-card-section">

          <div className="offer-card ">
            <div className=" offer-card-inner">
              <div className="img-card">
                <img src={order_list_get&&order_list_get.order_product&&order_list_get.order_product[0]&&image_url+order_list_get.order_product[0].product_id_fk__product_image} alt="" className="" />
              </div>
              <div className="offer-content ">
                <p className="card-title"></p>
                <p className="first-purchase ">
                {order_list_get.order_code}
                {/* {order_list_get&&order_list_get.order_product&&order_list_get.order_product[0]&&order_list_get.order_product[0].product_id_fk__product_name} */}
                </p>
                <span className="current-date ">
                  <s>{order_list_get.total_amount_without_discount}&nbsp;  RS/-</s>&nbsp;&nbsp;&nbsp; 
                  {order_list_get.total_amount_with_discount}&nbsp; Rs/-
                </span>
                <div>
                  <label className="exp-date">
                  {t("Click here to see more about your order. you can also repeat your order from here and check all information your order")}
                  </label>
                </div>

                <div className="d-flex justify-content-between mt-3">
                  {/* <div>
                    <label className="exp-date tac">Order Code</label>
                    <br />
                    <span className="current-date ">{order_list_get.order_code}</span>
                  </div> */}
                  <div>
                    <label className="exp-date tac">{t("Order Status")}</label>
                    <br />
                    <span className="current-date tac">
                      <OrderStatusIcon status={order_list_get.order_status} />
                      &nbsp;
                      {order_list_get.order_status}
                      </span>
                  </div>
                  <div>
                    <label className="exp-date tac">{t("Payment Status")}</label>
                    <br />
                    <span className="current-date tac">
                      
                      {order_list_get.payment_status}
                      </span>
                  </div>
                  <div>
                    <label className="exp-date tac">{t("Payment Mode")}</label>
                    <br />
                    <span className="current-date tac">{order_list_get.payment_mode}</span>
                  </div>
                  <div>
                    <label className="exp-date tac">{t("Order Date")}</label>
                    <br />
                    <span className="current-date ">{order_list_get.created_at}</span>
                  </div>
                  <div>
                    {/* <label className="exp-date tac">Product Detail</label> */}
                    <br />
                    <Link to={`/Detailslist/${order_list_get && order_list_get.order_product[0] && order_list_get.order_product[0].product_id_fk__id}`}>
                    <span className="current-date ">
                    <button type="button" class="btn btn-outline-primary">{t("Buy Again")}!</button>
                      
                      </span>
                    </Link>
                  </div>
                  

                  <div>
                    {/* <label className="exp-date tac">Product Detail</label> */}
                    <br />
                    {/* <Link to={"/My_order_detail/"}> */}
                    <Link to={"/My_order_detail/"} 
                    state={
                      {
                      product_id_fk__id:order_list_get && order_list_get.id,
                      order_code:order_list_get.order_code,
                      created_at:order_list_get.created_at,
                      total_amount_with_discount:order_list_get.total_amount_with_discount,
                      total_amount_without_discount:order_list_get.total_amount_without_discount,
                      product_image:order_list_get&&order_list_get.order_product&&order_list_get.order_product[0]&&image_url+order_list_get.order_product[0].product_id_fk__product_image
                      }
                    }
                    >
                      
                    <span className="current-date ">
                    <button type="button" class="btn btn-outline-primary">{t("View Detail")}</button>
                      
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            

          </div>
         
          {order_list_get.feedback_count === 0 && order_list_get.order_status === 'completed' ? (
            <div
              onClick={() => {
                setMyCreateReviewId(order_list_get && order_list_get.id);
                handleShowReview();
              }}
              style={{
                color: '#e74c3c',
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <p style={{ margin: 0 }}>{t("Rate this product now")}</p>
              <StarRating rating="0" />
            </div>
          ) : (
            ""
          )}

          <hr/>
          <div>
            
          </div>
        </div>
          
        
      
        ) 
        );
      })
      :
      <div>{t("No Data avaliable in Order")}.</div>
      
      }

      </div>
    )}
        
      </div>

      <Footer />
    </main>
  );
};

export default MyOrders;
