import React, { useContext, useEffect, useState } from "react";
import Footer from "../SharedComponents/Footer";
import Navbar from "../SharedComponents/Navbar";
import ertugul from "../assets/images/accountp.png";
import banner_slide1 from "../assets/slide/profile.jpg";
import arrow_white from "../assets/images/view_all_arrow.svg";
import edit from "../assets/images/edit.svg";
import { Link } from "react-router-dom";
import { postWithAuthCallWithErrorResponse, simpleGet_postCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { notifyError, notifySuccess } from "../SharedComponents/notify";
import { AppContext } from "../context/AppContext ";
import { Container, Row, Col } from 'react-grid-system';

import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

const Profile = () => {
  
  const image_url = "http://bharatkrushiseva.in/media/"
  const refresh = () => window.location.reload(true)
  const [profile, setprofile] = useState(null);
  const [profile_data, setProfileData] = useState([]);
  console.log("profile_data", profile_data)
  console.log("image_url", image_url + profile_data.user_image)

  const [data, setData] = useState({});

  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData.token)
  const [state1, setState1] = useState({

    gender: "",
    mobile: "",
    email: "",
    birth_date: "",
    user_name: "",
    profile: ""
  });

  console.log("state1", image_url + state1.profile)

  const convert_to_base_64 = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(e.target.files[0])
    fileReader.onload = () => {
      console.log("fileReader", fileReader.result)
      // Convert to Base64 string
      const base64String = fileReader.result
        .replace('data:', '')
        .replace(/^.+,/, '');
      console.log(base64String);
      setState1({ ...state1, profile: base64String })

    }
    fileReader.onerror = (error) => {

    }
  }

  const { t } = useTranslation();

  useEffect(() => {
   //alert('Page is loaded successfully');
   //get the current language stored in the local storage
   let currentLang = localStorage.getItem('lang');
   i18n.changeLanguage(currentLang);
 }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    postWithAuthCallWithErrorResponse(
      ApiConfig.UPDATE_FRONT_USER_PROFILE,
      JSON.stringify({ ...state1, })
    )
      .then((res) => {
        console.log("res", res.json.output.user_image);
        setData(res.json.data);
        localStorage.setItem("user_image",res.json.output.user_image);
        if (res.json.status === "true") {
          notifySuccess(res.json.message)
          
        }

        else {
          notifyError(res.json.message)
        }

        profileDataGet()
        console.log("user_name", state1.user_name)
       
        setTimeout( refresh, 1000)
      });

  }
  const [state, setState] = useState({
    token: customerData.token,

  });
  useEffect(() => {
    profileDataGet();
  }, []);

  const profileDataGet = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_USER_PROFILE_DATA,
      JSON.stringify({ ...state, })
    )
      .then((res) => {
        console.log("profile", res.json.profile.user_image);
        const profile_obj = {
          token: customerData.token,
          user_id: customerData.id,
          gender: res.json.profile.gender,
          mobile: res.json.profile.user_mobile,
          email: res.json.profile.business_email,
          birth_date: res.json.profile.birth_date,
          user_name: res.json.profile.user_name,
          profile: res.json.profile.user_image
        }

        setState1({
          ...state1,
          ...profile_obj
        });
        
        

        console.log("res.json.profile", res.json.profile)
      })
      .catch((err) => console.log(err));
  };




  
  return (
    <main>
      <Navbar />
      <div className="main-profile">
        <div className="banner">
          <img src={banner_slide1} alt="" />
        </div>
        <div className="outer">

          <div className="main-profile-card">
            {/* <div className="img">
              <div>
                <img src={image_url+state1.profile}  alt="" />
              </div>
              <input type={"file" }id="profilePic"  onChange={convert_to_base_64}/> */}
            {/* <img src={edit}  alt="" />
              <input type="file"  onChange={convert_to_base_64}/> */}
            {/* </div> */}

            <div>
              <Container>
                <Row>
                  <Col xs={12} sm={6}>
                    <div>
                    <img src={image_url+state1.profile} className="image_round35 " alt="" />
                    </div>
                  </Col>
                  <Col xs={6} sm={4}>
                  <div className="image_round37">
                   <input type={"file" }id="profilePic"  className="btn btn-outline-primary image_round36"  onChange={convert_to_base_64}/>
                  </div>
                  </Col>
                  
                </Row>
              </Container>
            </div>
            <div className="form-profile row">
            {/* <div class="mb-3 col-6">
                <label class="form-label">To edit your profile enter your image below</label>
               <div>
                <img src={image_url+state1.profile}  alt="" />
              </div>
              </div>

              <div class="mb-3 col-6">
                <label class="form-label">To edit your name enter your name below</label>
                <div className="image_round37">
                   <input type={"file" }id="profilePic"  className="btn btn-outline-primary image_round36"  onChange={convert_to_base_64}/>
                  </div>

              </div> */}



              <div class="mb-3 col-6">
                <label class="form-label">{t("To edit your name enter your name below")}</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  value={state1.user_name}
                  onChange={(e) => {
                    setState1({ ...state1, user_name: e.target.value });
                  }}
                />

              </div>
              <div class="mb-3 col-6">
                <label class="form-label">{t("To change gender detail select the gender below")}</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  value={state1.gender}
                  onChange={(e) => {
                    setState1({ ...state1, gender: e.target.value });
                  }}
                />
              </div>

              <div class="mb-3 col-md-6">
                <label class="form-label">{t("To edit your verified number here, if you want to change your Number")}</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  value={state1.mobile}
                  onChange={(e) => {
                    setState1({ ...state1, mobile: e.target.value });
                  }}
                />
              </div>
              <div class="mb-3 col-md-6">
                <label class="form-label">{t("To edit your verified email here, if you want to change your Email")}</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  value={state1.email}
                  onChange={(e) => {
                    setState1({ ...state1, email: e.target.value });
                  }}
                />
              </div>
              <div class="mb-3 col-6">
                <label class="form-label">{t("to change your date of birth click below to open calendar")}</label>
                <input
                  type="date"
                  class="form-control"
                  id="exampleFormControlInput1"
                  value={state1.birth_date}
                  onChange={(e) => {
                    setState1({ ...state1, birth_date: e.target.value });
                  }}

                />
              </div>

              <div class="mb-3 col-6">
                <label class="form-label">{t("to change your Address")}</label>
                <div className="edit-btn">
                  <div className="btn-shop-home">
                    <Link to="#" onClick={handleSubmit}>

                      <button className="btn-gray-common" >
                        {t("Update")} <img src={arrow_white} alt="" className="ms-2" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>





          </div>

        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Profile;
