import React, { useContext, useEffect, useState } from "react";
import Navbar from "../SharedComponents/Navbar";
import Slider from "react-slick";

import banner_slide1 from "../assets/slide/slider1.jpg";
import banner_slide2 from "../assets/slide/slider2.jpg";
import banner_slide3 from "../assets/slide/slider3.jpg";
import banner_slide4 from "../assets/slide/slider4.jpg";
import banner_bg_03 from "../assets/img/slider3.jpg";


import banner_bg_04 from "../assets/img/slider4.jpeg";
import offer1 from "../assets/img/offer1.jpg";
import offer2 from "../assets/img/offer2.jpg";
import offer3 from "../assets/img/offer3.jpg";
import offer4 from "../assets/img/offer4.jpg";
import crop from "../assets/img/crop.jpeg";
import agriged from "../assets/img/agriged.jpeg";
import Crop_Protection from "../assets/img/Crop_Protection.jpg";



import product_image_04 from "../assets/images/product_image_04.png";

import image_01 from "../assets/images/image_01.png";

import graphic_about from "../assets/images/graphic_about.svg";
import image_02 from "../assets/images/image_02.png";
import arrow_white from "../assets/images/view_all_arrow.svg";

import product_image_01 from "../assets/img/offer1.jpg";
import ic_cart from "../assets/images/ic_cart.svg";
import ic_heart from "../assets/images/ic_heart.svg";
import ic_heart_active from "../assets/images/ic_heart_active.svg";
import product_image_02 from "../assets/img/offer2.jpg";
import Footer from "../SharedComponents/Footer";
import how_it_works_image from "../assets/images/how_it_works_image.svg";
import { Link, useNavigate } from "react-router-dom";
import ic_search_input from "../assets/images/ic_search_input.svg";

import ic_call_red from "../assets/images/ic_call_red.svg";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import brand1 from "../assets/new_images/Brand category-01.jpg";
import brand2 from "../assets/new_images/Brand category-02.jpg";
import brand3 from "../assets/new_images/Brand category-03.jpg";
import brand4 from "../assets/new_images/Brand category-04 (2).jpg";
import brand5 from "../assets/new_images/Brand category-05.jpg";
import brand6 from "../assets/new_images/Brand category-06 (2).jpg";
import brand7 from "../assets/new_images/Brand category-07 (2).jpg";
import brand8 from "../assets/new_images/Brand category-08.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



import howitwork1 from "../assets/slide/howitwork1.png";
import howitwork2 from "../assets/slide/howitwork2.png";
import howitwork3 from "../assets/slide/howitwork3.png";
import mahapeeek from "../assets/img/mahapeeek.jpg";




import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import { AppContext } from "../context/AppContext ";
import { multipartPostCallWithFormDataResponse, postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ScaleLoader } from "react-spinners";
import { notifyError, notifySuccess } from "../SharedComponents/notify";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";


const RefCode = () => {
    const [showDis, setShowDis] = useState(false);
    const [showDis1, setShowDis1] = useState(false);

    const discount = () => setShowDis(!showDis);
    const discount1 = () => setShowDis1(!showDis1);
    const [topRightModal, setTopRightModal] = useState(false);
    const toggleShow = () => setTopRightModal(!topRightModal);
    const settings = {
        centerMode: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false,
                },
            },
        ],
    };

    const bannerSlidea = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,

        autoplay: true,

        cssEase: "linear",
        beforeChange: function (currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            console.log("after change", currentSlide);
        },
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const navigate = useNavigate();
    const image_url = "http://bharatkrushiseva.in/media/"
    const { customerData } = useContext(AppContext);
    console.log("customerData", customerData)
    const [loading, setLoading] = useState(false);
    const lang = localStorage.getItem("lang")
    console.log("lang",lang)



    const [benner_list, setBennerList] = useState([]);
    console.log("benner_list", benner_list)

    const [benner_list_state, setBennerListState] = useState({
        language: lang,
        banner_on: "homescreen"
    });
    useEffect(() => {
        get_benner_list_offers();
        get_RefrelCode()
    }, []);

    const get_benner_list_offers = () => {
        let formdata = new FormData();
        formdata.append("banner_on", benner_list_state.banner_on);
        formdata.append("language", benner_list_state.language);

        setLoading(true)
        multipartPostCallWithFormDataResponse(
            ApiConfig.GET_ECOM_BANNER_LIST, formdata
            // JSON.stringify({ ...benner_list_state, })
        )
            .then((res) => {
                setLoading(false)
                console.log("get_benner_list_offers", res.json);
                setBennerList(res.json.banner_data);

            })
            .catch((err) => console.log(err));

    };

   
    const [ref_code_list, setReferelCodeList] = useState([]);
    console.log("ref_code_list", ref_code_list)

    const [my_ref_code_state, setMyRefCodeState] = useState({
        language: lang,
        token: customerData.token,
        
    });


    const get_RefrelCode = () => {
        postWithAuthCallWithErrorResponse(
            ApiConfig.GET_ALL_ACTIVE_REFER_TO_ME,
            JSON.stringify({ ...my_ref_code_state, })
        )
            .then((res) => {
              
                console.log("my_brand", res.json.referto_mobile);
                setReferelCodeList(res.json.my_reference_list);
                if (res.json.status === "true")
            {

                notifySuccess(res.json.message)
                
                

            }
            else
            {
                notifyError(res.json.message)
            }
                



            })
            .catch((err) => console.log(err));
    }

    
    const settings3 = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: false,
              centerMode: false,
            },
          },
        ]
      };

      const logedIn = localStorage.getItem("logedin")
      console.log(logedIn);

      const [my_category, setMyCategory] = useState([]);
      console.log("my_category", my_category)

     
    
      const [state1, setState1] = useState({
        language: lang,
      });
    
      const get_My_Category = () => {
        setLoading(true)
        postWithAuthCallWithErrorResponse(
          ApiConfig.GET_ECOM_CATEGORY,
          JSON.stringify({ ...state1, })
        )
          .then((res) => {
            setLoading(false)
            console.log("profile", res.json);
            setMyCategory(res.json.category_list);
    
    
    
          })
          .catch((err) => console.log(err));
    
      };
    


      const [brand_id_on, setBrand_id_on] = useState({
        language: lang,
        // token:customerData.token,
    
      });
    
      useEffect(() => {
        get_benner_list_offers();
        get_My_Category();
        if (logedIn === "true") {
          setBrand_id_on({ ...brand_id_on, token: customerData.token })
        }
      }, []);
      const getBrandId = (id) => {
        console.log("id", id)
        setLoading(true);
        postWithAuthCallWithErrorResponse(
    
          ApiConfig.GET_ECOM_PRODUCT_LIST_BRAND,
          JSON.stringify({
            ...brand_id_on, brand_id: id
    
          })
        ).then((res) => {
          setLoading(false);
          console.log("address_id", res.json.product_list[0].brand_names);
    
          navigate("/BrandProduct", {
            state: {
              data: res.json,
              search_key: res.json && res.json.product_list[0] && res.json.product_list[0].brand_names
    
            }
          });
        })
      };

const { t } = useTranslation();
useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])


    return (
        <div>

            <Navbar />
            <main className="home-main-section">
                


                <div className="main-below-slide">
                    <section className="bckground-color-shop">
                        <div className="shop-section">
                            <div className="top-heading">

                            <div className="sliding-image">
                    {loading ?
                        (
                            <ScaleLoader

                                color={"#E27A7A"}
                                loading={loading}
                                // size={10}
                                className="loading setloader"
                            />
                        ) : (
                            <Slider {...settings3} >
                            {benner_list &&
                              benner_list.map((benner_list_get) => {
                                return (
                                  console.log("benner_list_get", benner_list_get.redirect_id),
                                  (
                                    <div >
                                      <Link to="#" onClick={() => getBrandId(benner_list_get.redirect_id)}>
                                        {/* <img className="img_round52" src={image_url + benner_short_banner_advertise_get.banner_image} alt="" /> */}
                                        <div className="img-slide-inner ">
                                          <img className="img_round_set_new_slide " src={image_url + benner_list_get.banner_image} alt="" />
                                        </div>
                                      </Link>
                                    </div>
                                  )
                                );
                              })}
                          </Slider>
                        )}
                </div>
                <br/>

                                <div className="top-heading">

                                    <label htmlFor="" className="heading-red">
                                    {t("ActiveReferCode")}
                                    </label>
                                    <p className="paragraph">
                                    {t("You can see here all your referral messages and their status that you sent to your friends and family")}
                                    </p>
                                </div>

                                <div className="cupon">
                                <Container >
                                { ref_code_list &&
                                    ref_code_list .length > 0 ?  ref_code_list &&
                                    ref_code_list.map((ref_code_get_list1) => {
                                        return (
                                            console.log("ref_code_get_list1",ref_code_get_list1),

                                            (
                                        <Row className="cupon1">
                                        <Col xs={12} sm={2}>
                                           <label className="mouse1">{t("Refer Number")}</label> 
                                           <hr/>
                                           <p className="mouse">{ref_code_get_list1.refby_user_mobile}</p>
                                            </Col>

                                            <Col xs={12} sm={2}>
                                            <label className="mouse1">{t("Pending")}</label>
                                            <hr/>
                                            <p className="mouse">{ref_code_get_list1.ref_order_status}</p>
                                            </Col>

                                            <Col xs={12} sm={2}>
                                            <label className="mouse1">{t("Expiry Date")}</label>
                                            <hr/>
                                            <p className="mouse">{ref_code_get_list1.expiry_datetime}</p>
                                            </Col>

                                            <Col xs={12} sm={4}>
                                            <label className="mouse1">{t("Message")}</label>
                                            <hr/>
                                            <p className="mouse">
                                              {
                                                 lang==="en"
                                                 ?
                                                 ref_code_get_list1.ref_message_en
                                                 :
                                                 lang==="hi"
                                                 ?
                                                 ref_code_get_list1.ref_message_hi
                                                 :
                                                 lang==="mr"
                                                 ?
                                                 ref_code_get_list1.ref_message_mr
                                                 :
                                                 "none"
                                              }
                                              
                                              {/* {ref_code_get_list1.ref_message} */}
                                              
                                              </p>                                            
                                              </Col>

                                            <Col xs={12} sm={2}>
                                           <label className="mouse1">{t("RefCode")}</label>
                                           <hr/>
                                           <p className="mouse">{ref_code_get_list1.ref_code}</p>
                                            </Col>
                                            <hr />
                                        </Row>
                                        
                                       
                                        )
                                        );
                                    })
                                    :
                                    <div className="textset">{t("No Data avaliable in Active ReferCode")}</div>
                                  
                                  
                                  }

                                        
                                        </Container>
                                </div>
                                



                            </div>
                        </div>
                    </section>
                </div>

            </main>


            <Footer />
        </div>
    );
};

export default RefCode;
