import React, { useContext, useEffect, useState } from "react";
import Navbar from "../SharedComponents/Navbar";
import Slider from "react-slick";
import banner_slide1 from "../assets/new_images/Combo offers-01 (1).jpg";
import banner_slide2 from "../assets/new_images/Latest offer (1).jpg";
import banner_bg_03 from "../assets/new_images/Home- slider_1.jpg";
import banner_bg_04 from "../assets/new_images/Home- slider_4.jpg";

import offer1 from "../assets/img/offer1.jpg";
import offer2 from "../assets/img/offer2.jpg";
import offer3 from "../assets/img/offer3.jpg";
import offer4 from "../assets/img/offer4.jpg";
import crop from "../assets/img/crop.jpeg";
import agriged from "../assets/img/agriged.jpeg";
import Crop_Protection from "../assets/img/Crop_Protection.jpg";



import product_image_04 from "../assets/images/product_image_04.png";

import image_01 from "../assets/images/image_01.png";
import graphic_about from "../assets/images/graphic_about.svg";
import image_02 from "../assets/images/image_02.png";
import arrow_white from "../assets/images/view_all_arrow.svg";
import product_image_01 from "../assets/img/offer1.jpg";
import ic_cart from "../assets/images/ic_cart.svg";
import ic_heart from "../assets/images/ic_heart.svg";
import ic_heart_active from "../assets/images/ic_heart_active.svg";
import product_image_02 from "../assets/img/offer2.jpg";
import Footer from "../SharedComponents/Footer";
import how_it_works_image from "../assets/images/how_it_works_image.svg";
import { Link, useNavigate } from "react-router-dom";
import ic_search_input from "../assets/images/ic_search_input.svg";

import ic_call_red from "../assets/images/ic_call_red.svg";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HoverImage from "react-hover-image";

import sponsord_product17 from "../assets/img/sponsord_product17.jpg";
import sponsord_product18 from "../assets/img/sponsord_product18.png";
import sponsord_product19 from "../assets/img/sponsord_product19.png";
import sponsord_product20 from "../assets/img/sponsord_product20.png";
import sponsord_product21 from "../assets/img/sponsord_product21.avif";
import sponsord_product22 from "../assets/img/sponsord_product22.avif";
import sponsord_product23 from "../assets/img/sponsord_product23.avif";
import sponsord_product24 from "../assets/img/sponsord_product24.avif";
import sponsord_product25 from "../assets/img/sponsord_product25.avif";
import sponsord_product26 from "../assets/img/sponsord_product26.avif";
import sponsord_product27 from "../assets/img/sponsord_product27.avif";
import sponsord_product28 from "../assets/img/sponsord_product28.jpg";
import sponsord_product29 from "../assets/img/sponsord_product29.jpg";
import sponsord_product30 from "../assets/img/sponsord_product30.avif";
import sponsord_product31 from "../assets/img/sponsord_product31.avif";
import sponsord_product32 from "../assets/img/sponsord_product32.avif";

import sponsord_product33 from "../assets/img/sponsord_product33.jpg";
import sponsord_product34 from "../assets/img/sponsord_product34.jpg";
import sponsord_product35 from "../assets/img/sponsord_product35.png";
import sponsord_product36 from "../assets/img/sponsord_product36.avif";
import sponsord_product37 from "../assets/img/sponsord_product37.avif";
import sponsord_product38 from "../assets/img/sponsord_product38.avif";
import sponsord_product39 from "../assets/img/sponsord_product39.avif";
import sponsord_product40 from "../assets/img/sponsord_product40.avif";
import howitwork1 from "../assets/slide/howitwork1.png";
import howitwork2 from "../assets/slide/howitwork2.png";
import howitwork3 from "../assets/slide/howitwork3.png";


import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

import { AppContext } from "../context/AppContext ";
import { multipartPostCallWithFormDataResponse, postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ScaleLoader } from "react-spinners";
import ReactGA from 'react-ga';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import StarRating from './StarRating';


const SpecialComboOffers = () => {
  const [showDis, setShowDis] = useState(false);
  const [showDis1, setShowDis1] = useState(false);

  const discount = () => setShowDis(!showDis);
  const discount1 = () => setShowDis1(!showDis1);
  const [topRightModal, setTopRightModal] = useState(false);
  const toggleShow = () => setTopRightModal(!topRightModal);
  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  const bannerSlidea = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    autoplay: true,

    cssEase: "linear",
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const image_url = "http://bharatkrushiseva.in/media/"
  const image_url1 = "http://bharatkrushiseva.in"
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData)
  const [loading, setLoading] = useState(false);

  const [todays_offers, setTodayOffers] = useState([]);
  console.log("todays_offers", todays_offers)
  const lang = localStorage.getItem("lang")
  console.log("lang",lang)

  useEffect(()=>
  {
   ReactGA.pageview(window.location.pathname)
  },[]);

  const [todays_offers_state, setTodayOffersState] = useState({
    language: lang===null ? "en" : lang,
    token:customerData.token,
  });
  useEffect(() => {
    get_today_offer();
  }, []);

  const get_today_offer= () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_TRANDING_PRODUCT_LIST,
      JSON.stringify({ ...todays_offers_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("my_brand", res.json);
        setTodayOffers(res.json.product_list);

      })
      .catch((err) => console.log(err));

  };


  const settings3 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ]
  };

  const navigate = useNavigate();
  const logedIn = localStorage.getItem("logedin")
  console.log(logedIn);

  const [brand_id_on, setBrand_id_on] = useState({
    language: lang===null ? "en" : lang,
    // token:customerData.token,

  });

  useEffect(() => {
    get_benner_list_offers();
    get_My_Category();
    if (logedIn === "true") {
      setBrand_id_on({ ...brand_id_on, token: customerData.token })
    }
  }, []);
  const getBrandId = (id) => {
    console.log("id", id)
    setLoading(true);
    postWithAuthCallWithErrorResponse(

      ApiConfig.GET_ECOM_PRODUCT_LIST_BRAND,
      JSON.stringify({
        ...brand_id_on, brand_id: id

      })
    ).then((res) => {
      setLoading(false);
      console.log("address_id", res.json.product_list[0].brand_names);

      navigate("/BrandProduct", {
        state: {
          data: res.json,
          search_key: res.json && res.json.product_list[0] && res.json.product_list[0].brand_names

        }
      });
    })
  }


  

  const [benner_list, setBennerList] = useState([]);
  console.log("benner_list", benner_list)

  const [benner_list_state, setBennerListState] = useState({
    language: lang===null ? "en" : lang,
    banner_on: "homescreen"
  });

  const get_benner_list_offers = () => {
    let formdata = new FormData();
    formdata.append("banner_on", benner_list_state.banner_on);
    formdata.append("language", benner_list_state.language);

    setLoading(true)
    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_ECOM_BANNER_LIST, formdata
      // JSON.stringify({ ...benner_list_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("get_benner_list_offers", res.json);
        setBennerList(res.json.banner_data);

      })
      .catch((err) => console.log(err));

  };

  const [my_category, setMyCategory] = useState([]);
  console.log("my_category", my_category)

  const [state, setState] = useState({
    language: lang===null ? "en" : lang,
  });

  const get_My_Category = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_CATEGORY,
      JSON.stringify({ ...state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("profile", res.json);
        setMyCategory(res.json.category_list);



      })
      .catch((err) => console.log(err));

  };

const { t } = useTranslation();
useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])




  return (
    <div>
      <Navbar />
      <main className="home-main-section">
        {/* <Link to="/Getquote"> */}
       
        {/* </Link> */}
        <MDBModal
          animationDirection='right'
          show={topRightModal}
          tabIndex='-1'
          setShow={setTopRightModal}
        >

          <MDBModalDialog position='top-right' side>
            <MDBModalContent>
              <MDBModalHeader className='bg-success text-white'>
                <MDBModalTitle>Filter By</MDBModalTitle>
                <MDBBtn
                  color='none'
                  className='btn-close btn-close-white'
                  onClick={toggleShow}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className='row'>

                  <div className='col-12'>
                    <Container>
                      <Row>
                        <Col sm={4}>
                          Price
                        </Col>
                        <Col sm={8}>
                          <label>
                            <input type="radio" id="age1" name="age" value="30" />
                            &nbsp; Rs. 0 - Rs.200
                          </label>
                          <br/>
                        
                          <label>
                            <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 200 - Rs.500
                          </label>
                        </Col>
                         <Col sm={4}>
                          Category
                        </Col>
                        <Col sm={8}>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 500 - Rs.1000
                          </label>
                          <br/>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 1000 - Rs.5000
                          </label>
                        </Col>
                        <Col sm={4}>
                          Brand
                        </Col>
                        <Col sm={8}>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 5000 - Rs.10,000
                          </label>
                          <br/>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 10,000 - Rs.50,000
                          </label>
                        </Col>
                        <Col sm={4}>
                          Unit
                        </Col>
                        <Col sm={8}>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 50,000 - Rs.100,000
                          </label>
                          <br/>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 100,000 - More...
                          </label>
                        </Col>
                  
                      </Row>
                    </Container>
                  </div>
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        
     


        <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section">
            <div className="sliding-image">
         
         <Slider {...settings3} >
             {benner_list &&
               benner_list.map((benner_list_get) => {
                 return (
                   console.log("benner_list_get", benner_list_get.redirect_id),
                   (
                     <div >
                       <Link to="#" onClick={() => getBrandId(benner_list_get.redirect_id)}>
                         {/* <img className="img_round52" src={image_url + benner_short_banner_advertise_get.banner_image} alt="" /> */}
                         <div className="img-slide-inner ">
                           <img className="img_round_set_new_slide " src={image_url + benner_list_get.banner_image} alt="" />
                         </div>
                       </Link>
                     </div>
                   )
                 );
               })}
           </Slider>
       </div>
       <br/>
            <div className="top-heading">
                <Container>
                  <Row>
                    <Col sm={12}>
                    <label htmlFor="" className="heading-black">
                    {t("Tranding Product")}</label>
                    &nbsp;
                 
                  <p className="paragraph">
                    {t("Boost your farm s productivity with our best-selling agriculture product - trusted and loved by farmers everywhere!")}
                  </p>
                
                    </Col>

                    <Col sm={8}>
                    {/* <div className="btn-shop-home">
                      <MDBBtn className="btn-gray-common" onClick={toggleShow}>Filter</MDBBtn><img src={arrow_white} alt="" className="ms-2" />
                    </div> */}
                    </Col>
                  </Row>
                </Container>
                <br/>
              </div>

              {/* ====================start latest offer================== */}
              {loading ?
                   (
      <ScaleLoader

        color={"#E27A7A"}
        loading={loading}
        // size={10}
        className="loading setloader"
        
      />
    ) : (
              <div className="latest-offer">
                <Container className="bs1">
                  <Row>
                  {todays_offers &&
                  todays_offers.map((todays_offers_get) => {
                    const results = todays_offers_get.grand_total_without_discount - todays_offers_get.grand_total;
                    const saved = results / todays_offers_get.grand_total_without_discount;
                    const dp = saved * 100;
                    const result1 = dp.toString().slice(0, 5);
                    return (
                      console.log("todays_offers_get", todays_offers_get),
                      (
                    <Col className="flex-0">
                      <Link to={`/Detailslist/${todays_offers_get.id}`} state={todays_offers_get.id}>
                      <Card className="shadowcard mrc" >
                      <div className="mrc2">
                          <button type="button" class="btn btn-warning mrc3">{result1} % OFF</button>
                        </div>
                        {/* <img src={offer1} alt="" /> */}
                        
                        <img className="image_height_set" src={image_url1+todays_offers_get.product_image} />
                        <Card.Body>
                        <Card.Text>
                           {todays_offers_get.product_name}
                          </Card.Text>
                          <Card.Title>
                            <div className="text_set">
                            <div className="rating-container">
                            <StarRating rating={todays_offers_get.average_rating} />
                            &nbsp;
                            <b className="rating-container1" class="btn btn-warning mrc3">
                            {todays_offers_get.average_rating}
                            </b> 
                            </div>
                            </div>
                            </Card.Title>

                          <Card.Text>
                            <label>{t("Available in")} : </label> &nbsp;
                            <label>{todays_offers_get.base_packet_quantity}</label>
                            <label>{todays_offers_get.base_packet_unit}</label>
                          </Card.Text>
                        
                          <Card.Text>
                            {todays_offers_get.category.category_name}
                          </Card.Text>
                          <Card.Text>
                            <s>{todays_offers_get.grand_total_without_discount} Rs</s>&nbsp;&nbsp; {todays_offers_get.grand_total} Rs
                          </Card.Text>
                          <Card.Text>
                            {todays_offers_get.is_bks_assured === 1 ? (
                            <label className="text_settag">{todays_offers_get.brand_tag}</label>
                            ) : null}
                            </Card.Text>
                          <Card.Text>
                          <div className="text_set1">
                            {results} Rs {t("Saved")}
                          </div>
                        </Card.Text>
                        </Card.Body>
                      </Card>
                      </Link>
                    </Col>

                      )
                      );
                      })}


                  </Row>
                </Container>
              </div>
               )}
              {/* ====================start latest offer================== */}

              {/* <div className="btn-shop-home">
                <Link to="/products">
                  <button className="btn-gray-common">
                    View more <img src={arrow_white} alt="" className="ms-2" />
                  </button>
                </Link>
              </div> */}
            </div>
          </section>
        </div>


        {/* ====================How it Works================= */}

        <div className="main-below-slide">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">

                <label htmlFor="" className="heading-red">
                  {t("How it Works")}
                </label>
                <p className="paragraph">
                  {t("To order from us, please follow these simple steps")}
                </p>
              </div>
              {/* ====================How it Works================== */}
              <div>

                <Container>

                  <Row className="hw">

                  <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork1} />
                        <h5 className="foothead">{t("Place an order")}</h5>
                        <p className="footparagraph">
                        {t("Choose from a wide range of daily essentials product's")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork2} />
                        <h5 className="foothead">{t("Your order is on its way")}</h5>
                        <p className="footparagraph">
                        {t("Your Delivery Partner Will Be At Your Door Shortly")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork3} />
                        <h5 className="foothead">{t("Enjoy")}</h5>
                        <p className="footparagraph">
                        {t("Avail the best quality products")}
                        </p>
                      </div>
                    </Col>

                  </Row>

                </Container>

                
              </div>
              {/* ====================How it Works================== */}

            </div>
          </section>
        </div>
{/* ====================How it Works================= */}
      </main>
      <Footer />
    </div>
  );
};

export default SpecialComboOffers;
