import React from "react";
import ProductListing from "./Products/ProductListing";

const Products = () => {
  return (
    <main>
      <ProductListing />
    </main>
  );
};

export default Products;
