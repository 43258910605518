import React, { useContext, useEffect, useState } from "react";
import Navbar from "../SharedComponents/Navbar";
import Slider from "react-slick";
import banner_slide1 from "../assets/new_images/hone-slider3.jpg";
import banner_slide2 from "../assets/new_images/Combo offers-01 (1).jpg";
import banner_bg_03 from "../assets/new_images/Home- slider_1.jpg";
import banner_bg_04 from "../assets/new_images/Home- slider_4.jpg";
import offer1 from "../assets/img/offer1.jpg";
import offer2 from "../assets/img/offer2.jpg";
import offer3 from "../assets/img/offer3.jpg";
import offer4 from "../assets/img/offer4.jpg";
import arrow_white from "../assets/images/view_all_arrow.svg";
import Footer from "../SharedComponents/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ic_search_input from "../assets/images/ic_search_input.svg";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "react-multi-carousel/lib/styles.css";
import HoverImage from "react-hover-image";
import ic_heart from "../assets/images/ic_heart.svg";

import sponsord_product17 from "../assets/img/sponsord_product17.jpg";
import sponsord_product18 from "../assets/img/sponsord_product18.png";
import sponsord_product19 from "../assets/img/sponsord_product19.png";
import sponsord_product20 from "../assets/img/sponsord_product20.png";
import sponsord_product21 from "../assets/img/sponsord_product21.avif";
import sponsord_product22 from "../assets/img/sponsord_product22.avif";
import sponsord_product23 from "../assets/img/sponsord_product23.avif";
import sponsord_product24 from "../assets/img/sponsord_product24.avif";
import sponsord_product25 from "../assets/img/sponsord_product25.avif";
import sponsord_product26 from "../assets/img/sponsord_product26.avif";
import sponsord_product27 from "../assets/img/sponsord_product27.avif";
import sponsord_product28 from "../assets/img/sponsord_product28.jpg";
import sponsord_product29 from "../assets/img/sponsord_product29.jpg";
import sponsord_product30 from "../assets/img/sponsord_product30.avif";
import sponsord_product31 from "../assets/img/sponsord_product31.avif";
import sponsord_product32 from "../assets/img/sponsord_product32.avif";

import sponsord_product33 from "../assets/img/sponsord_product33.jpg";
import sponsord_product34 from "../assets/img/sponsord_product34.jpg";
import sponsord_product35 from "../assets/img/sponsord_product35.png";
import sponsord_product36 from "../assets/img/sponsord_product36.avif";
import sponsord_product37 from "../assets/img/sponsord_product37.avif";
import sponsord_product38 from "../assets/img/sponsord_product38.avif";
import sponsord_product39 from "../assets/img/sponsord_product39.avif";
import sponsord_product40 from "../assets/img/sponsord_product40.avif";

import howitwork1 from "../assets/slide/howitwork1.png";
import howitwork2 from "../assets/slide/howitwork2.png";
import howitwork3 from "../assets/slide/howitwork3.png";
import bksassurred from "../assets/slide/bksassurred.png";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { AppContext } from "../context/AppContext ";
import { multipartPostCallWithFormDataResponse, postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import ReactPaginate from 'react-paginate';
import { ScaleLoader } from "react-spinners";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import StarRating from './StarRating';


const Category = () => {
  const [showDis, setShowDis] = useState(false);
  const [showDis1, setShowDis1] = useState(false);

  const discount = () => setShowDis(!showDis);
  const discount1 = () => setShowDis1(!showDis1);
  const [topRightModal, setTopRightModal] = useState(false);
  const toggleShow = () => setTopRightModal(!topRightModal);
  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  const bannerSlidea = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    autoplay: true,

    cssEase: "linear",
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

   
  const settings3 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ]
  };
  
  const responsive = {
    superLargeDesktop: {

      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };


  const navigate = useNavigate();
  const image_url="http://bharatkrushiseva.in"
  const image_url1 = "http://bharatkrushiseva.in/media/"
  const {customerData} = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  console.log("customerData",customerData)
  console.log("image_url",image_url+customerData.user_image)
  const previous_data = useLocation().state;
  // console.log("previous_data", previous_data)
  const logedIn=localStorage.getItem("logedin")
  console.log(logedIn);

  const lang = localStorage.getItem("lang")
  console.log("lang",lang)

  const [my_category, setMyCategory] = useState([]);
  console.log("my_category", my_category)


  
  useEffect(() => {
    if (logedIn==="true")
    {
      setState({...state,token:customerData.token})
    }
    
  }, []);


  const [state, setState] = useState({
    language: lang===null ? "en" : lang,
    // token:customerData.token,
    category_id:previous_data && previous_data.id,
    page_no:1
  });


  useEffect(() => {
    get_My_Category();
    get_benner_list_offers();
  }, [state]);


  const changePage = ({ selected }) => {
   setState({
    ...state,
    page_no:selected+1
   })
  };

  const get_My_Category = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_PRODUCT_LIST,
      JSON.stringify({ ...state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("profile", res.json);
        setMyCategory(res.json);

      })
      .catch((err) => console.log(err));

  };
  const [brand_id_on, setBrand_id_on] = useState({
    language: lang===null ? "en" : lang,
    // token:customerData.token,

  });
  
  const getBrandId = (id) => {
    console.log("id", id)
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_PRODUCT_LIST_BRAND,
      JSON.stringify({
        ...brand_id_on, brand_id: id
      })
    ).then((res) => {
      setLoading(false);
      console.log("address_id", res.json);

      navigate("/BrandProduct", {
        state: {
          data: res.json,
          search_key: res.json && res.json.product_list[0] && res.json.product_list[0].brand_names

        }
      });
    })
  }

  
  const [benner_list, setBennerList] = useState([]);
  console.log("benner_list", benner_list)

  const [benner_list_state, setBennerListState] = useState({
    language: lang===null ? "en" : lang,
    banner_on: "homescreen"
  });

  const get_benner_list_offers = () => {
    let formdata=new FormData();
    formdata.append("banner_on",benner_list_state.banner_on);
    formdata.append("language",benner_list_state.language);

    setLoading(true)
    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_ECOM_BANNER_LIST, formdata
      // JSON.stringify({ ...benner_list_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("get_benner_list_offers", res.json);
        setBennerList(res.json.banner_data);

      })
      .catch((err) => console.log(err));

  };

  const { t } = useTranslation();

useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])



  return (
    <div>
      <Navbar />
      <main className="home-main-section">
      <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section">
        <div className="sliding-image">
       
        <Slider {...settings3} >
            {benner_list &&
              benner_list.map((benner_list_get) => {
                return (
                  console.log("benner_list_get", benner_list_get),
                  (
                    <div>
                      <Link to="#" onClick={() => getBrandId(benner_list_get.redirect_id)}>
                        {/* <Link to="#" state={benner_list_get.redirect_id}> */}

                        <div className="img-slide-inner ">
                          <img className="img_round_set_new_slide " src={image_url1 + benner_list_get.banner_image} alt="" />
                        </div>
                      </Link>
                    </div>
                  )
                );
              })}
          </Slider>
        </div>
        {/* </Link> */}
        <MDBModal
          animationDirection='right'
          show={topRightModal}
          tabIndex='-1'
          setShow={setTopRightModal}
        >

          <MDBModalDialog position='top-right' side>
            <MDBModalContent>
              <MDBModalHeader className='bg-success text-white'>
                <MDBModalTitle>Filter By</MDBModalTitle>
                <MDBBtn
                  color='none'
                  className='btn-close btn-close-white'
                  onClick={toggleShow}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className='row'>

                  <div className='col-12'>
                    <Container>
                      <Row>
                        <Col sm={4}>
                          Price
                        </Col>
                        <Col sm={8}>
                          <label>
                            <input type="radio" id="age1" name="age" value="30" />
                            &nbsp; Rs. 0 - Rs.200
                          </label>
                          <br/>
                        
                          <label>
                            <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 200 - Rs.500
                          </label>
                        </Col>
                         <Col sm={4}>
                          Category
                        </Col>
                        <Col sm={8}>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 500 - Rs.1000
                          </label>
                          <br/>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 1000 - Rs.5000
                          </label>
                        </Col>
                        <Col sm={4}>
                          Brand
                        </Col>
                        <Col sm={8}>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 5000 - Rs.10,000
                          </label>
                          <br/>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 10,000 - Rs.50,000
                          </label>
                        </Col>
                        <Col sm={4}>
                          Unit
                        </Col>
                        <Col sm={8}>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 50,000 - Rs.100,000
                          </label>
                          <br/>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 100,000 - More...
                          </label>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        



        
              <div className="top-heading">
              <Container>
                  <Row>
                    <Col sm={4} className="cat_title">
                    <label htmlFor="" className="heading-black">
                       {previous_data && previous_data.category_name}
                    </label>
                    &nbsp;
              
                 <p className="paragraph">
                  {t("Shop from top Offers across the world")}
                </p>
                 
                
                    </Col>

                    <Col sm={8}>
                    {/* <div className="btn-shop-home">
                      <MDBBtn className="btn-gray-common" onClick={toggleShow}>Filter</MDBBtn><img src={arrow_white} alt="" className="ms-2" />
                    </div> */}
                    </Col>
                  </Row>
                </Container>
                {/* <label htmlFor="" className="heading-black">
                 Shop By
                </label>{" "}
                <label htmlFor="" className="heading-red">
                {previous_data.category_name}
                </label>{" "}
                
                <p className="paragraph">
                  Shop from top Offers across the world
                </p> */}
              </div>

              {/* ====================start latest offer================== */}
              {loading ?
                   (
      <ScaleLoader

        color={"#E27A7A"}
        loading={loading}
        // size={10}
        className="loading setloader"
      />
    ) : (
      <div className="latest-offer">
      <Container className="bs1">
        <Row>
          {my_category.product_list && my_category.product_list.length > 0 ? (
            my_category.product_list.map((my_category_get) => {
              const results = my_category_get.grand_total_without_discount - my_category_get.grand_total;
              const saved = results / my_category_get.grand_total_without_discount;
              const dp = saved * 100;
              const result1 = dp.toString().slice(0, 5);
    
              return (
                <Col className="flex-0" key={my_category_get.id}>
                  <Link to={`/Detailslist/${my_category_get.id}`} state={my_category_get.id}>
                    <Card className="shadowcard mrc">
                      <div className="mrc2">
                        <button type="button" className="btn btn-warning mrc3">
                          {result1} % OFF
                        </button>
                      </div>
                      <img
                        className="image_height_set"
                        src={image_url + my_category_get.product_image}
                        alt={my_category_get.product_name}
                      />
                      <Card.Body>
                        <Card.Title>
                          <div className="text_set">{my_category_get.product_name}</div>
                        </Card.Title>
                        <Card.Title>
                          <div className="text_set">
                            <div className="rating-container">
                              <StarRating rating={my_category_get.average_rating} />
                              &nbsp;
                              <b className="rating-container1 btn btn-warning mrc3">
                                {my_category_get.average_rating}
                              </b>
                            </div>
                          </div>
                        </Card.Title>
                        <Card.Text>
                          <div>
                            <label>{t("Available in")}: </label>
                            <label>{my_category_get.base_packet_quantity}</label> &nbsp;
                            <label>{my_category_get.base_packet_unit}</label>
                          </div>
                        </Card.Text>
                        <Card.Text>
                          <div className="text_set">{my_category_get.category.category_name}</div>
                        </Card.Text>
                        <Card.Text>
                          <s>{my_category_get.grand_total_without_discount} Rs</s>&nbsp;&nbsp;
                          {my_category_get.grand_total} Rs
                        </Card.Text>
                        <Card.Text>
                          {my_category_get.is_bks_assured === 1 && (
                            <img className="set-images" src={bksassurred} alt="BKS Assured" />
                          )}
                        </Card.Text>
                        <Card.Text>
                          <div className="text_set1">
                            {results} Rs {t("Saved")}
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            })
          ) : (
            <Col style={{textAlign:"center"}}>
              <p>{t("No products available")}</p>
            </Col>
          )}
        </Row>
      </Container>
    </div>
    
    )}
              {/* ====================start latest offer================== */}

              <div className="btn-shop-home">
              <ReactPaginate
                      previousLabel={t("Previous")}
                      nextLabel={t("Next")}
                      pageCount={
                        my_category.next_page
                      }
                      onPageChange={changePage}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                      
                      // forcePage={currentPage}
                    />
                {/* <Link to="/products">
                  <button className="btn-gray-common">
                    View more <img src={arrow_white} alt="" className="ms-2" />
                  </button>
                </Link> */}
              </div>
            </div>
          </section>
        </div>

        {/* ====================How it Works================= */}

        <div className="main-below-slide">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">

                <label htmlFor="" className="heading-red">
                  {t("How it Works")}
                </label>
                <p className="paragraph">
                  {t("To order from us, please follow these simple steps")}.
                </p>
              </div>
              {/* ====================How it Works================== */}
              <div>

                <Container>

                  <Row className="hw">

                  <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork1} />
                        <h5 className="foothead">{t("Place an order")}</h5>
                        <p className="footparagraph">
                        {t("Choose from a wide range of daily essentials product's")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork2} />
                        <h5 className="foothead">{t("Your order is on its way")}</h5>
                        <p className="footparagraph">
                        {t("Your Delivery Partner Will Be At Your Door Shortly")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork3} />
                        <h5 className="foothead">{t("Enjoy")}</h5>
                        <p className="footparagraph">
                        {t("Avail the best quality products")}
                        </p>
                      </div>
                    </Col>

                  </Row>

                </Container>

                
              </div>
              {/* ====================How it Works================== */}

            </div>
          </section>
        </div>
{/* ====================How it Works================= */}
      </main>
      <Footer />
    </div>
  );
};

export default Category;
