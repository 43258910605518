import React, { useContext, useEffect, useState } from "react";
import Footer from "../SharedComponents/Footer";
import Navbar from "../SharedComponents/Navbar";
import ReactStars from "react-rating-stars-component";

import offer2 from "../assets/img/offer2.jpg";
import offer4 from "../assets/img/offer4.jpg";
import arrow_white from "../assets/images/view_all_arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext ";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { notifyError, notifySuccess } from "../SharedComponents/notify";
import { ScaleLoader } from "react-spinners";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";


const MyAddress = () => {
    const navigate = useNavigate();
    const image_url="http://bharatkrushiseva.in/media/"
    const {customerData} = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const lang = localStorage.getItem("lang")
    console.log("lang",lang)
    
    
   
    console.log("customerData",customerData)
    console.log("image_url",image_url+customerData.user_image)

  const [addressdata, setaddressData] = useState([]);
    const [state, setState] = useState({
      user_id: customerData.id,
      language: lang
    });
    useEffect(() => {
      addressList();
      
    }, []);
    const addressList = () => {
      setLoading(true)
      postWithAuthCallWithErrorResponse(
        ApiConfig.ADDRESS_LIST,
        JSON.stringify({ ...state, })
      )
        .then((res) => {
          setLoading(false)
          console.log("profile", res.json);
          setaddressData(res.json.address_list);
         
        })
        .catch((err) => console.log(err));
    };

    const getAddressId = (id) => {
      postWithAuthCallWithErrorResponse(
        ApiConfig.DELETE_ADDRESS,
        JSON.stringify({ address_id:id })
      ).then((res) => {
        console.log("address_id", res.json);
       
        if  (res.json.status==="true")
          {
            addressList()
            notifySuccess(res.json.output)
          }
          else{
            notifyError(res.json.output)
          }
      })
      .catch((err) => console.log(err));

    };

const { t } = useTranslation();
useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])
    
  return (
    <main>
      <Navbar />
      <div className="offers-bg">
        <div className="top-heading">
          <label htmlFor="" className="heading-red">
            {t("MyAddress")}
          </label>
          <p className="paragraph">
            {/* {t("Shop from top Offers across the world")} */}
          </p>
        </div>
        <div className="btn-shop-home">
                <Link to="/AddAddress">
                <button type="button" class="btn btn-outline-success">
                    {t("Add Address")} <img src={arrow_white} alt="" className="ms-2" />
                    </button>
                </Link>
              </div>
              {loading ?
                   (
      <ScaleLoader

        color={"#E27A7A"}
        loading={loading}
        // size={10}
        className="loading setloader"
      />
    ) : (
              <div>
              { addressdata &&
            addressdata .length > 0 ?  addressdata &&
            addressdata.map((address_list) => {
              return (
                console.log(address_list),
                (
        <div className=" inner-card-section  ">
      
          <div className="offer-card ">

            
            <div className=" offer-card-inner">
              <div className="img-card">
                <img src={image_url+customerData.user_image} alt="" className="" />
              </div>
              <div className="offer-content ">
                <p className="card-title"></p>
                <p className="first-purchase ">
                 {address_list && address_list.user.user_name}
                </p>
             
                <div>
                  <label className="exp-date ">
                  {address_list && address_list.house_name},
                  {address_list && address_list.area_name},
                  {address_list && address_list.landmark},
                  {address_list && address_list.pincode},
                  {address_list && address_list.state},
                  {address_list && address_list.district},
                  {address_list && address_list.taluka},
                  {address_list && address_list.village},
                  {address_list && address_list.mobile},
                  </label>
                </div>

                <div className="d-flex justify-content-between mt-3">
                
                <div>
                    <label className="exp-date ">{t("Mobile Number")}</label>
                    <br />
                    <span className="current-date ">   {address_list && address_list.user.user_mobile}</span>
                  </div>

                  <div>
                    <label className="exp-date ">{t("Pin Code")}</label>
                    <br />
                    <span className="current-date ">
                    {address_list && address_list.pincode}
                    </span>
                  </div>
                  
                  <div>
                    <label className="exp-date ">{t("district")}</label>
                    <br />
                    <span className="current-date "> {address_list && address_list.district}</span>
                  </div>
                  <div>
                    <label className="exp-date ">{t("state")}</label>
                    <br />
                    <span className="current-date ">{address_list && address_list.state}</span>
                  </div>
                  
                 
                  <div>
                    
                    <span className="current-date ">
                    <Link to={"/EditAddress/"} state={address_list.id}>
                    <button type="button" class="btn btn-outline-warning">{t("Edit")}</button>
                    </Link>
                    </span>
                  </div>
                  <div>
                    
                    <span className="current-date ">
                    <Link to="#" onClick={() => getAddressId(address_list.id)}>
                    <button type="button" class="btn btn-outline-success">{t("Delete")}</button>
                    </Link>
                    </span>
                  </div>


                </div>
              </div>
            </div>

          </div>

         

        </div>

         )
               );
             }) 
             :
             <div className="textset">{t("No Data avaliable in My Address")}.</div>
            } 
             </div>
    )}

      </div>

      <Footer />
    </main>
  );
};

export default MyAddress;
