import React from "react";
import eye from "../../assets/images/ic_eye.svg";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import eyeclose from "../../assets/images/ic_eye_closed.svg";

const NewPassword = () => {

  return (
    <>
      <main className="main-login ">
        <div className="row ">
          {/* left section */}
          <div className="col-md-6 login-banner-img left">
            <div className="logo-rsponsive">
              <img src={logo} alt="" />
            </div>
            <div className="image-back"></div>
          </div>
          {/* right section */}
          <div className="col-md-6 login-form right">
            <div className="form-content">
              <form action="">
                <div className="top-form-text">
                  <div className="back-main">
                    <p className="welcome-text">Forgot Password</p>

                    <p className="back-btn-red">
                      <Link to="/PasswordOTP">Go Back</Link>
                    </p>
                  </div>

                  
                </div>

                <div className="pass-main-log">
                  <label htmlFor="" className="email-label">
                    Enter New Password
                  </label>
                  <br />
                  <input
                    type="password"
                    className="email-input"
                    maxLength={10}
                  />
                  <span className="span-eye position-relative">
                    <img src={eye} alt="" className="eye-icon" />

                  </span>
                </div>
                <div className="pass-main-log">
                  <label htmlFor="" className="email-label">
                    Confirm Password
                  </label>
                  <br />
                  <input
                    type="password"
                    className="email-input"
                    maxLength={10}
                  />
                  <span className="span-eye position-relative">
                    <img src={eyeclose} alt="" className="eye-icon" />

                  </span>
                </div>
                <Link to="/home">
                  <button className="login-button forgot-btn">Continue</button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default NewPassword;
