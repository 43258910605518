const BASE_URL = "https://bharatkrushiseva.in/api_user_v2/";
const BASE_URL_ONE = "https://bharatkrushiseva.in/api_user/";
const BASE_URL_TWO = "https://bharatkrushiseva.in/api_user_v2/ecommerce/";
const ApiConfig = {
  // BASE_URL:BASE_URL,
 
  
  LOGIN_OTP:BASE_URL+"getOtpToContinue",
  VALIDATEOTPFORMOBILE:BASE_URL+"validateOtpForMobile",
  UPDATE_USER_ECOM_DATA:BASE_URL+"updateUserEcomData",
  GET_USER_PROFILE_DATA:BASE_URL+"getUserProfileData",
  UPDATE_FRONT_USER_PROFILE:BASE_URL_ONE+"UpdateFrontuserProfile",
  ADDRESS_LIST:BASE_URL_ONE+"AddressList",
  ADD_NEW_ADDRESS:BASE_URL_ONE+"AddNewAddress",
  DELETE_ADDRESS:BASE_URL_ONE+"DeleteAddress",
  ADDRESS_DETAIL:BASE_URL_ONE+"AddressDetails",
  UPDATE_ADDRESS:BASE_URL_ONE+"UpdateAddress",
  GET_USER_NOTIFICATION:BASE_URL_ONE+"getUserNotificationFilter",
  CONTCAT_US_FORM:BASE_URL_ONE+"ContactUsForm",
  GET_USER_ORDER_LIST:BASE_URL_TWO+"getUserOrderList",
  GET_ECOM_CATEGORY:BASE_URL_TWO+"getEcomCategory",
  GET_ECOM_BRAND:BASE_URL_TWO+"getEcomBrand",
  GET_USER_WISHLIST_PRODUCT:BASE_URL_TWO+"getUserWishlistProduct",
  REMEVE_PRODUCT_FROM_USER_WISHLIST:BASE_URL_TWO+"removeProductFromUserWishlist",
  GET_ECOM_TRANDING_PRODUCT_LIST:BASE_URL_TWO+"getEcomTrendingProductList",
  GET_ECOM_TRANDING_COMBO_PRODUCT_LIST:BASE_URL_TWO+"getEcomTrendingComboProductList",
  GET_ECOM_PRODUCT_LIST:BASE_URL_TWO+"getEcomProductList",
  GET_ECOM_PRODUCT_DETAIL_BYID:BASE_URL_TWO+"getEcomProductDetailById",
  ADD_PRODUCT_TO_USER_WISHLIST:BASE_URL_TWO+"addProductToUserWishlist",
  ADD_PRODUCT_TO_CART:BASE_URL_TWO+"addProductToCart",
  ADD_PRODUCT_LIST_IN_CART:BASE_URL_TWO+"getProductListInCart",
  DELETE_ONE_PRODUCT_FROM_CART:BASE_URL_TWO+"deleteOneProductFromCart",
  DELETE_All_PRODUCT_FROM_CART:BASE_URL_TWO+"deleteAllProductFromCart",
  GET_SELLING_ADDRESS:BASE_URL_ONE+"GetSellingAddress",
  GET_CART_DELIVERY_ADDRESS:BASE_URL_TWO+"getCartDeliveryInfo",
  ADDPREORDERV2:BASE_URL_TWO+"addPreOrderV2",
  GET_CART_COUNT:BASE_URL_TWO+"getCartCount",
  SEARCH_PRODUCT:BASE_URL_TWO+"getEcomProductList",
  GET_ECOM_PRODUCT_LIST_BRAND:BASE_URL_TWO+"getEcomProductList",
  GET_ECOM_SECTION:BASE_URL_TWO+"getEcomSection",
  GET_ECOM_BANNER_LIST:BASE_URL_TWO+"getEcomBannerList",
  ORDER_GENERATE:"https://sandbox.cashfree.com/pg/orders",
  CREATE_RP_ORDER_SESSION:BASE_URL_TWO+"createRpOrderSession",
  UPDATE_ORDER_PAYMENT_STATUS:BASE_URL_TWO+"updateOrderPaymentStatus",
  GET_OFFERS_TYPE:BASE_URL_TWO+"getOfferType",
  GET_OFFERS_TYPE_By_Id:BASE_URL_TWO+"getOfferTypeById",
  GET_CART_DATA_WITH_APPLY_OFFER:BASE_URL_TWO+"getCartDataWithApplyOffer",
  REFER_USE_FOR_ORDER:BASE_URL_TWO+"referUserForOrder",
  GET_USER_REFER_BY_ME:BASE_URL_TWO+"getUserReferByMe", 
  GET_ALL_ACTIVE_REFER_TO_ME:BASE_URL_TWO+"getAllActiveReferToMe",
  GET_USER_ORDER_DETAIL_BY_ID:BASE_URL_TWO+"getUserOrderDetailById",
  GET_PRODUCT_REVIEWS:BASE_URL_TWO+"getProductReviewList",
  ADD_PRODUCT_FEEDBACK:BASE_URL_TWO+"addReportedFeedback",
  ADD_USER_ECOM_ORDER_FEEDBACK:BASE_URL_TWO+"addUserEcomOrderFeedback",
  GET_LATEST_VIEW_PRODUCT:BASE_URL_TWO+"getLatestViewProductList",
   

};
export default ApiConfig;