import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

const CreateProfile = () => {
  return (
    <>
      <main className="main-login ">
        <div className="row ">
          {/* left section */}
          <div className="col-md-6 login-banner-img left">
            <div className="logo-rsponsive">
              <img src={logo} alt="" />
            </div>
            <div className="image-back"></div>
          </div>
          {/* right section */}
          <div className="col-md-6 login-form right">
            <div className="form-content">
              <form action="">
                <div className="top-form-text">
                  <div className="back-main">
                    <p className="welcome-text">Edit Profile</p>

                    <p className="back-btn-red">
                      <Link to="/Login">Go Back</Link>
                    </p>
                  </div>

                  <p className="enter-text">
                    Please enter your Name to continue
                  </p>
                </div>
                <div className="email-main-login">
                  <label htmlFor="" className="email-label ">
                    Name
                  </label>
                  <br />
                  <input type="text" className="email-input" />
                </div>
                <Link to="/home">
                  <button className="login-button forgot-btn">Continue</button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateProfile;
