import React, { useContext, useEffect, useState } from "react";
import Navbar from "../SharedComponents/Navbar";

import Footer from "../SharedComponents/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";

import PhonePe_logo from "../assets/pay/unnamed.png";
import google_pay from "../assets/pay/unnamed (1).png";
import Qr from "../assets/pay/unnamed (2).png";
import Qrscan from "../assets/img/Qrscan.png";
import creadit_card from "../assets/img/card.png";
import upi from "../assets/img/upi.png";
import wallet from "../assets/img/wallet.png";
import net_banking from "../assets/img/net_banking.png";
import cash_on_delevery from "../assets/img/cash_on_delevery.png";
import BKS_logo from "../assets/slide/BKS app m-01.png";
import vlogo from "../assets/slide/fevicon (1).png";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "react-multi-carousel/lib/styles.css";

import howitwork1 from "../assets/slide/howitwork1.png";
import howitwork2 from "../assets/slide/howitwork2.png";
import howitwork3 from "../assets/slide/howitwork3.png";
import ic_cart1 from "../assets/new_images/icon 25-25-01 (1).jpg";
import ic_cart2 from "../assets/new_images/icon 25-25-02 (2).jpg";
import ic_cart3 from "../assets/new_images/icon 25-25-04 (1).jpg";

import mahapeeek from "../assets/img/mahapeeek.jpg";
import HoverImage from "react-hover-image";
import sponsord_product1 from "../assets/img/sponsord_product1.avif";
import sponsord_product2 from "../assets/img/sponsord_product2.avif";
import sponsord_product3 from "../assets/img/sponsord_product3.avif";
import sponsord_product4 from "../assets/img/sponsord_product4.png";
import sponsord_product5 from "../assets/img/ps5.avif";
import sponsord_product6 from "../assets/img/sponsord_product6.avif";

import sponsord_product7 from "../assets/img/sponsord_product7.png";
import sponsord_product8 from "../assets/img/sponsord_product8.avif";
import sponsord_product9 from "../assets/img/sponsord_product9.avif";
import sponsord_product10 from "../assets/img/sponsord_product10.avif";
import sponsord_product11 from "../assets/img/sponsord_product11.avif";
import sponsord_product12 from "../assets/img/sponsord_product12.avif";
import sponsord_product13 from "../assets/img/sponsord_product13.avif";
import sponsord_product14 from "../assets/img/sponsord_product14.avif";
import sponsord_product15 from "../assets/img/sponsord_product15.png";
import sponsord_product16 from "../assets/img/sponsord_product16.avif";
import backed from "../assets/img/backed.svg";




import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { AppContext } from "../context/AppContext ";
import { getWithpaymentGatway, multipartPostCallWithFormDataResponse, postWithAuthCallWithErrorResponse, postWithpaymentGatway } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { notifyError, notifySuccess } from "../SharedComponents/notify";

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import useRazorpay from "react-razorpay";

const Payment = () => {
  const [showDis, setShowDis] = useState(false);
  const [showDis1, setShowDis1] = useState(false);

  const discount = () => setShowDis(!showDis);
  const discount1 = () => setShowDis1(!showDis1);
  const [topRightModal, setTopRightModal] = useState(false);
  const toggleShow = () => setTopRightModal(!topRightModal);
  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  const bannerSlidea = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    autoplay: true,

    cssEase: "linear",
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };


  const navigate = useNavigate();
  const image_url = "http://bharatkrushiseva.in"
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData)
  console.log("image_url", image_url + customerData.user_image)
  const previous_data = useLocation().state;
  console.log("previous_data", previous_data)
  const [loading, setLoading] = useState(false);

  let final_total_cart_price = sessionStorage.getItem("final_total_cart_price")
  let offer_deducted_amount = sessionStorage.getItem("offer_deducted_amount")
  let cupon_status = sessionStorage.getItem("cupon_status")
  console.log("cupon_status",typeof(cupon_status))

  let address_id = sessionStorage.getItem("addres_id")

  let total_amount_with_discount = sessionStorage.getItem("total_amount_with_discount")
  let total_amount_without_discount = sessionStorage.getItem("total_amount_without_discount")
  let payment_order_id = sessionStorage.getItem("payment_order_id")
  let offer_type_id = sessionStorage.getItem("offer_type_id")

  
  // let grand_total_cart_price=sessionStorage.getItem("grand_total_cart_price")
  console.log("payment_order_id", payment_order_id)

  const [cart_delivery_info, setCartDeliveryAddress] = useState([]);
  console.log("cart_delivery_info", cart_delivery_info.delivery_charges)
  const [delivery_info_state, setDeliveryInfoState] = useState({
    language: "en",
    token: customerData.token,
    // address_id:7414
    address_id: address_id
  });
  useEffect(() => {
    cart_info_address();
    // get_Payment_Order_Generated();
  }, []);
  console.log("delivery_info_state", delivery_info_state)
  const cart_info_address = () => {

    let formdata = new FormData();
    formdata.append("address_id", delivery_info_state.address_id);
    formdata.append("language", delivery_info_state.language);
    formdata.append("token", delivery_info_state.token);


    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_CART_DELIVERY_ADDRESS,
      formdata
      // JSON.stringify(formdata)
    )
      .then((res) => {
        console.log("cart_delivery", res.json);
        setCartDeliveryAddress(res.json);

      })
      .catch((err) => console.log(err));
  };


  const [set_order_id_generated, setOrderIdGenareted] = useState([]);
  console.log("set_order_id_generated",set_order_id_generated.order_id)


  const getOrderPlaceOrderGenerate=()=>{
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.ADDPREORDERV2,
      JSON.stringify({
        token: customerData.token,
        language: "en",
        payment_mode: "online",
        address_id: address_id,

        total_amount_with_discount: total_amount_with_discount,
        total_amount_without_discount: total_amount_without_discount,
        grand_total: total_amount_with_discount,
        final_total_cart_price: total_amount_with_discount,


        delivery_charge: cart_delivery_info.delivery_charges,
        distance: cart_delivery_info.distance,
        from_pincode: cart_delivery_info.from_pincode,
        to_pincode: cart_delivery_info.to_pincode,



        has_offer: cupon_status==="true" ? "yes" : "no"  ,
        offer_type_id: cupon_status==="true" ? offer_type_id : "",
        ref_code: 0,
        offer_deducted_amount: cupon_status==="true" ? offer_deducted_amount : "",
        total_paid_amount: cupon_status==="true" ? final_total_cart_price : total_amount_with_discount,

      })
    )
    .then((res) => {
      setLoading(false)
      sessionStorage.setItem("payment_order_id",res.json.order_id)
      console.log("message", res.json);
      setOrderIdGenareted(res.json)

      if (res.json.status === "true") {
        // setTimeout( get_Payment_Order_Generated, 1000)
        get_Payment_Order_Generated(res.json.order_id)
        
      }
      

      else {
        notifyError(res.json.message)
      }

    })
    .catch((err) => console.log(err));
};


// useEffect(() => {
//   get_Payment_Order_Generated();
// }, []);



const [set_order_id_generated_data, setOrderIdGenaretedData] = useState([]);
// console.log("set_order_id_generated_data",set_order_id_generated_data.order_id.id)


const [state_order_gen_Razorpay, setStateOrderGenRazorPay] = useState({
  token: customerData.token,
  order_amount:cupon_status==="true" ? final_total_cart_price : total_amount_with_discount,
  customer_id:customerData.id,
  customer_name:customerData.user_name,
  customer_email:customerData.business_email,
  customer_phone:customerData.user_mobile,
  order_currency:"INR",
  order_note:"Order Place Done",
  // order_id:3920,
  // order_id:set_order_id_generated
  
});

const get_Payment_Order_Generated = (order_id) => {
  let formdata=new FormData();
    formdata.append("token",state_order_gen_Razorpay.token); 
    formdata.append("order_amount",state_order_gen_Razorpay.order_amount);
    formdata.append("customer_id",state_order_gen_Razorpay.customer_id);
    formdata.append("customer_name",state_order_gen_Razorpay.customer_name);
    formdata.append("customer_email",state_order_gen_Razorpay.customer_email);
    formdata.append("customer_phone",state_order_gen_Razorpay.customer_phone);
    formdata.append("order_currency",state_order_gen_Razorpay.order_currency);
    formdata.append("order_note",state_order_gen_Razorpay.order_note);
    formdata.append("order_id",order_id); 
  setLoading(true)
  multipartPostCallWithFormDataResponse(
    ApiConfig.CREATE_RP_ORDER_SESSION,formdata
    // JSON.stringify({ ...state_order_gen_Razorpay,order_id:order_id })
  )
    .then((res) => {
      setLoading(false)
      console.log("order_payment_generated", res.json.order_id.id);
      setOrderIdGenaretedData(res.json);
      handlePayment(res.json.order_id.id,order_id);
      
     
    })
    .catch((err) => console.log(err));
};





  
  const [set_order_id, setOrderId] = useState([]);
  console.log("set_order_id", set_order_id)

  const getOrderPlace = () => {

    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.ADDPREORDERV2,
      JSON.stringify({
        token: customerData.token,
        language: "en",
        payment_mode: "cod",
        address_id: address_id,

        total_amount_with_discount: total_amount_with_discount,
        total_amount_without_discount: total_amount_without_discount,
        grand_total: cupon_status==="true" ? final_total_cart_price : total_amount_with_discount,
        final_total_cart_price: total_amount_with_discount,


        delivery_charge: cart_delivery_info.delivery_charges,
        distance: cart_delivery_info.distance,
        from_pincode: cart_delivery_info.from_pincode,
        to_pincode: cart_delivery_info.to_pincode,



        has_offer: cupon_status==="true" ? "yes" : "no"  ,
        offer_type_id: cupon_status==="true" ? offer_type_id : "",
        ref_code: 0,
        offer_deducted_amount: cupon_status==="true" ? offer_deducted_amount : "",
        total_paid_amount: cupon_status==="true" ? final_total_cart_price : total_amount_with_discount,
      })
      
    )
      .then((res) => {
        setLoading(false)
        console.log("message", res.json);
        setOrderId(res.json)

        if (res.json.status === "true") {
          notifySuccess(res.json.message)
          sessionStorage.clear()
          navigate("/OrderPlace");
        }

        else {
          notifyError(res.json.message)
        }

      })
      .catch((err) => console.log(err));
  };


 

  



  const [cart_product, setCartProduct] = useState([]);
  console.log("cart_product", cart_product)
  const [state, setState] = useState({
    token: customerData.token,
    language: "en",
  });
  useEffect(() => {
    get_Cart_Data();
    get_Order_Generate();
  }, []);

  const get_Cart_Data = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_PRODUCT_LIST_IN_CART,
      JSON.stringify({ ...state, })
    )
      .then((res) => {
        console.log("CART", res.json);
        setCartProduct(res.json);
      })
      .catch((err) => console.log(err));
  };


  const get_Order_Generate= () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_PRODUCT_LIST_IN_CART,
      JSON.stringify({ ...state, })
    )
      .then((res) => {
        console.log("CART", res.json);
        setCartProduct(res.json);
      })
      .catch((err) => console.log(err));
  };



  
  const [update_payment_status, setUpdatePaymentStatus] = useState({
    token: customerData.token,
    language: "en",
    // order_id:payment_order_id,
    payment_status:"paid",
    txn_id:123,
  });
  const get_update_status_order_payment= (order_id1) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UPDATE_ORDER_PAYMENT_STATUS,
      JSON.stringify({ ...update_payment_status,order_id:order_id1 })
    )
      .then((res) => {
        console.log("CART", res.json);
        setCartProduct(res.json);
        if (res.json.status === "true") {
          notifySuccess(res.json.message)
          sessionStorage.clear()
          navigate("/OrderPlace");
        }

        else {
          notifyError(res.json.message)
        }
      })
      .catch((err) => console.log(err));
  };



  



  const Razorpay = useRazorpay();
  const handlePayment = async (order_id,order_id1) => {
    const options = {
      key: "rzp_live_esI4UQ42mv3wBV", // Enter the Key ID generated from the Dashboard
      key_secret:"PRLv2KkrvaicpjAGfw4jmaWT",
      amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Bharat Krushi Seva",
      description: "Test Transaction",
      image: vlogo,
      // order_id:"order_Lk5ksIn1ntaglh",
      order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
        console.log("payment done===============================")
        get_update_status_order_payment(order_id1);
      },
      prefill: {
        name: customerData.user_name,
        email: customerData.business_email,
        contact: customerData.user_mobile,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

  
    rzp1.open();
  };



  const { t } = useTranslation();
  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem('lang');
    i18n.changeLanguage(currentLang);
  }, [])
 





  return (
    <div>
      <Navbar />
      <main className="home-main-section">
      <MDBModal
            animationDirection='right'
            show={topRightModal}
            tabIndex='-1'
            setShow={setTopRightModal}
          >

          <MDBModalDialog position='top-right' side>
            <MDBModalContent>
              <MDBModalHeader className='bg-success text-white'>
                <MDBModalTitle>Click On Payment Option For Payment</MDBModalTitle>
                <MDBBtn
                  color='none'
                  className='btn-close btn-close-white'
                  onClick={toggleShow}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className='row'>

                  <div className='col-12'>
                    <Container>
                      <Row>
                        <Col sm={3}>
                        
                        </Col>
                        <Col sm={6}>
                          <label>
                          <button type="button" class="btn btn-danger">Payment</button>
                            
                          </label>
                          
                        </Col>
                        
                        <Col sm={3}>
                          <label>
                          
                          </label>
                        </Col>
                       
                        
                      </Row>
                    </Container>
                  </div>
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        {/* <div className="btn-shop-home">
                      <MDBBtn className="btn-gray-common"  onClick={toggleShow}>dsd</MDBBtn>
                    </div> */}

        {/* <Link to="/Getquote"> */}
        <div className="main-below-slide">
          {/* <marquee width="60%" direction="left" height="100px" className="mrq">
            Note: Only Cash on delivery option is available!!.
          </marquee> */}

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">
                <label htmlFor="" className="heading-red">
                  {t("Payment Options")}
                </label>
              </div>
              <br />
              {/* ====================How it Works================== */}
              <div className="latest-offer">
                <div>
                  <Container>
                    <Row>
                      <Col xs={12} sm={6}>


                        <Col xs={12} sm={12}>
                          <Row>
                            <Col xs={4} sm={2}>
                              <div >
                                <img className="img_round19" src={PhonePe_logo} alt="" />
                              </div>
                            </Col>
                            <Col xs={6} sm={4}>
                              <div className="setpay">
                              <Link to="#" onClick={() => getOrderPlaceOrderGenerate(
                                )}>
                                  {loading ? (
                                    <div
                                      class="spinner-border changecolorpaymenttext"
                                      style={{ textAlign: "right", color: "black" }}
                                    />
                                  ) : (
                                    <div className="changecolorpaymenttext"> {t("PhonePe")} </div>
                                  )}
                                
                                </Link>
                                {/* Phone Pay */}
                              </div>
                            </Col>
                            <Col xs={2} sm={2}>
                              <div className="setpay">
                                <img className="img_round23" src={backed} alt="" />
                              </div>
                            </Col>
                          </Row>

                        </Col>
                        <hr />
                        <Col xs={12} sm={12}>
                          <Row>
                            <Col xs={4} sm={2}>
                              <div>
                                <img className="img_round20" src={google_pay} alt="" />
                              </div>
                            </Col>
                            <Col xs={6} sm={4}>
                              <div className="setpay">
                              <Link to="#" onClick={() => getOrderPlaceOrderGenerate(
                                )}>
                                  {loading ? (
                                    <div
                                      class="spinner-border"
                                      style={{ textAlign: "right", color: "black" }}
                                    />
                                  ) : (
                                    <div className="changecolorpaymenttext"> {t("Google Pay")} </div>
                                    
                                  )}
                                
                                </Link>
                                {/* Google Pay */}

                              </div>
                            </Col>
                            <Col xs={2} sm={2}>
                              <div className="setpay">
                                <img className="img_round23" src={backed} alt="" />
                              </div>
                            </Col>
                          </Row>

                        </Col>
                        <hr />
                        <Col xs={12} sm={12}>
                          <Row>
                            <Col xs={4} sm={2}>
                              <div>
                                <img className="img_round13" src={Qr} alt="" />
                              </div>
                            </Col>
                            <Col xs={6} sm={4}>
                              <div className="setpay">
                              <Link to="#" onClick={() => getOrderPlaceOrderGenerate(
                                )}>
                                  {loading ? (
                                    <div
                                      class="spinner-border"
                                      style={{ textAlign: "right", color: "black" }}
                                    />
                                  ) : (
                                    <div className="changecolorpaymenttext"> {t("QR Code")} </div>
                                    
                                  )}
                                
                                </Link>
                                {/* QR Code */}

                              </div>
                            </Col>
                            <Col xs={2} sm={2}>
                              <div className="setpay">
                                <img className="img_round23" src={backed} alt="" />
                              </div>
                            </Col>
                          </Row>

                        </Col>
                        <hr />
                        <Col xs={12} sm={12}>
                          <Row>
                            <Col xs={4} sm={2}>
                              <div>
                                <img className="img_round13" src={creadit_card} alt="" />
                              </div>
                            </Col>
                            <Col xs={6} sm={4}>
                              <div className="setpay">
                              <Link to="#" onClick={() => getOrderPlaceOrderGenerate(
                                )}>
                                  {loading ? (
                                    <div
                                      class="spinner-border"
                                      style={{ textAlign: "right", color: "black" }}
                                    />
                                  ) : (
                                    <div className="changecolorpaymenttext"> {t("Creadit/Debit card")} </div>
                                    
                                  )}
                                
                                </Link>
                                {/* Creadit/Debit card */}

                              </div>
                            </Col>
                            <Col xs={2} sm={2}>
                              <div className="setpay">
                                <img className="img_round23" src={backed} alt="" />
                              </div>
                            </Col>
                          </Row>

                        </Col>
                        <hr />
                        <Col xs={12} sm={12}>
                          <Row>
                            <Col xs={4} sm={2}>
                              <div>
                                <img className="img_round13" src={upi} alt="" />
                              </div>
                            </Col>
                            <Col xs={6} sm={4}>
                              <div className="setpay">
                              <Link to="#" onClick={() => getOrderPlaceOrderGenerate(
                                )}>
                                  {loading ? (
                                    <div
                                      class="spinner-border"
                                      style={{ textAlign: "right", color: "black" }}
                                    />
                                  ) : (
                                    <div className="changecolorpaymenttext"> {t("Upi Payment")} </div>
                                    
                                  )}
                                
                                </Link>
                                {/* Upi Payment */}

                              </div>
                            </Col>
                            <Col xs={2} sm={2}>
                              <div className="setpay">
                                <img className="img_round23" src={backed} alt="" />
                              </div>
                            </Col>

                          </Row>

                        </Col>
                        <hr />
                        <Col xs={12} sm={12}>
                          <Row>
                            <Col xs={4} sm={2}>
                              <div>
                                <img className="img_round13" src={wallet} alt="" />
                              </div>
                            </Col>
                            <Col xs={6} sm={4}>
                              <div className="setpay">
                              <Link to="#" onClick={() => getOrderPlaceOrderGenerate(
                                )}>
                                  {loading ? (
                                    <div
                                      class="spinner-border"
                                      style={{ textAlign: "right", color: "black" }}
                                    />
                                  ) : (
                                    <div className="changecolorpaymenttext">{t("Paytm/Wallets")}</div>
                                    
                                    
                                  )}
                                
                                </Link>

                              </div>
                            </Col>
                            <Col xs={2} sm={2}>
                              <div className="setpay">
                                <img className="img_round23" src={backed} alt="" />
                              </div>
                            </Col>

                          </Row>

                        </Col>
                        
                        <hr />
                        <Col xs={12} sm={12}>
                          <Row>
                            <Col xs={4} sm={2}>
                              <div>
                                <img className="img_round13" src={cash_on_delevery} alt="" />
                              </div>
                            </Col>
                            <Col xs={6} sm={4}>
                              <div className="setpay">
                                <Link to="#" onClick={() => getOrderPlace(
                                
                                )}>
                                  {loading ? (
                                    <div
                                      class="spinner-border"
                                      style={{ textAlign: "right", color: "black" }}
                                    />
                                  ) : (
                                    <div className="changecolorpaymenttext">{t("Cash on delivery")}</div>
                                    
                                  )}


                                </Link>

                              </div>
                            </Col>
                            <Col xs={2} sm={2}>
                              <div className="setpay">
                                <img className="img_round23" src={backed} alt="" />
                              </div>
                            </Col>

                          </Row>

                        </Col>
                        <hr />
                      </Col>
                      <Col xs={12} sm={6}>
                        <img className="img_round22" src={BKS_logo} alt="" />
                      </Col>
                    </Row>
                  </Container>
                </div>


                <Container>
                  <Row>
                    <Col xs={12} sm={12}>
                      <Card className="">
                        <Card.Body>
                          <Card.Title>
                            <h6>{t("Price Details")}</h6>
                          </Card.Title>

                          <Table className="fontset1">
                            <Thead >
                              <Tr>
                                <Th>{t("Total MRP")}</Th>
                                <Th>{t("Discount On MRP")}</Th>
                                <Th>{t("Coupon Discount")}</Th>
                                <Th>{t("Total Amount")}</Th>
                                
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr className="fontset2">
                                <Td>{cart_product.grand_total_cart_price} Rs</Td>
                                <Td> 00 Rs</Td>
                                <Td> {
                                cupon_status==="true" ? offer_deducted_amount :  t("apply coupons")
                                 } Rs</Td>
                                <Td>{
                                cupon_status==="true" ? final_total_cart_price  :  cart_product.grand_total_cart_price
                                }Rs</Td>
                              </Tr>


                            </Tbody>
                          </Table>
                          {/* <Card.Text>
                      <Row>
                      <Col  xs={7} sm={6}>
                          <p className="sads">Total Cart Price</p>
                          <p className="sads">Total Cart Price without discount </p>
                          <p className="sads">grand total cart price</p>
                          <p className="sads">Total Del Charge</p>
                          <p className="sads">Grand Total Cart Price Without Discount</p>
                        </Col>
                        <Col  xs={5} sm={6}>
                          <p>{cart_product.total_cart_price} Rs</p>
                          <p>{cart_product.total_cart_price_without_discount} Rs</p>
                          <p>{cart_product.grand_total_cart_price}Rs</p>
                          <p>{cart_product.total_del_charge} Rs</p>
                          <p>{cart_product.grand_total_cart_price_without_discount} Rs</p>
                        </Col>


                      </Row>
                    </Card.Text> */}
                          <hr />
                          <Card.Text>
                            <Row>
                              <Col xs={4} sm={4}>

                                <h6 className="sads1"> <img src={ic_cart1} alt="" className="ms-2 img_round24" />{t("Original Product")}</h6>
                              </Col>
                              <Col xs={4} sm={4}>
                                <h6 className="sads1"> <img src={ic_cart2} alt="" className="ms-2 img_round24" />{t("Fast Delivery")}</h6>
                              </Col>
                              <Col xs={4} sm={4}>
                                <h6 className="sads1"> <img src={ic_cart3} alt="" className="ms-2 img_round24" />{t("Secure Payments")}</h6>
                              </Col>

                            </Row>
                            <br />
                            <Row>
                              <Col sm={12}>
                              <a href="https://bharatkrushiseva.com/terms_condition.html" target="_blank">
                                <span className="ccf">
                                  {t("Order processing and shipping time")}
                                  </span>
                                  <br/><br/>
                                  <span className="changecolorpaymenttext">
                                  {t("Title and risk of loss for all products ordered by you shall pass on to you upon Bharat Krushi Seva shipment to the shipping carrier. Rules on COD vary based on transaction value, products, shipping location and other relevant parameters. Bharat Krushi Seva shall retain the right to offer/deny COD for specific cases")}.
                             
                                  </span>
                                </a>


                              </Col>

                            </Row>
                          </Card.Text>



                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                </Container>
                <div className="cart-bg-main">
                  <div className="count-cart-bottom-main shadowcard">
                    <div className="inner">
                      <div className="left">
                        <label className="me-2">{t("grand total cart price")}</label>
                        <label className="tc-10">
                          (
                            {
                            cupon_status==="true" ? final_total_cart_price  :  cart_product.grand_total_cart_price
                            } Rs
                            )
                          </label>
                      </div>
                      <div className="right">

                        <Link to="#" onClick={() => getOrderPlace(

                        )}>
                          <b className="cc-bn">

                              {t("Place order")}
                              
                          </b>
                          {loading ? (
                              <div
                                class="spinner-border"
                                style={{ textAlign: "right", color: "#FFFBF3" }}
                              />
                            ): ""  }
                        </Link>
                        
                      </div>

                      {/* <div className="App">
                        <button onClick={handlePayment}>Click</button>
                      </div> */}

                    </div>
                  </div>
                </div>

              </div>
              {/* ====================How it Works================== */}


            </div>
          </section>
        </div>
        {/* </Link> */}








        {/* ====================How it Works================= */}
     
        <div className="main-below-slide">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">

                <label htmlFor="" className="heading-red">
                  {t("How it Works")}
                </label>
                <p className="paragraph">
                  {t("To order from us, please follow these simple steps")}.
                </p>
              </div>
              {/* ====================How it Works================== */}
              <div>

                <Container>

                  <Row className="hw">

                  <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork1} />
                        <h5 className="foothead">{t("Place an order")}</h5>
                        <p className="footparagraph">
                        {t("Choose from a wide range of daily essentials product's")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork2} />
                        <h5 className="foothead">{t("Your order is on its way")}</h5>
                        <p className="footparagraph">
                        {t("Your Delivery Partner Will Be At Your Door Shortly")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork3} />
                        <h5 className="foothead">{t("Enjoy")}</h5>
                        <p className="footparagraph">
                        {t("Avail the best quality products")}
                        </p>
                      </div>
                    </Col>

                  </Row>

                </Container>

                
              </div>
              {/* ====================How it Works================== */}

            </div>
          </section>
        </div>
        {/* ====================How it Works================= */}
      </main >
      <Footer />
    </div >

  );
};

export default Payment;
