import React, { useContext } from "react";
import ic_my_orders from "../assets/slide/unnamed (3).png";
import ic_my_orders1 from "../assets/slide/unnamed (2).png";
import ic_my_orders2 from "../assets/slide/unnamed (4).png";
import ic_my_orders3 from "../assets/slide/unnamed (5).png";
import ic_my_orders4 from "../assets/slide/unnamed (6).png";
import download from "../assets/slide/unnamed (7).png";
import about1 from "../assets/slide/unnamed (8).png"; 
import about2 from "../assets/slide/unnamed (9).png";
import heart1 from "../assets/images/ic_my_fav.svg";
// import download from "../assets/img/icons8-downloads-24 (2).png";

import back1 from "../assets/images/backed.svg";
import help1 from "../assets/images/ic_call.svg";
// import about1 from "../assets/images/ic_about.svg";
import logout1 from "../assets/images/ic_logout.svg";
import ertugul from "../assets/images/accountp.png";

import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext ";
import Swal from "sweetalert";
import { notifyError, notifySuccess } from "../SharedComponents/notify";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

const Account = () => {
   const image_url="http://bharatkrushiseva.in/media/"
  const {customerData} = useContext(AppContext);
  console.log("customerData_one",customerData.user_name)
  const { logedIn, setLoggedIn ,userDetails} = useContext(AppContext);

  const logOut = (e) =>{ 
  notifySuccess("logout")
  localStorage.clear();
  setLoggedIn(false);    
}

const { t } = useTranslation();

  // console.log("customerData_three",customerData)
  return (
    <main className="main-account-side ">
      <div
        class="offcanvas offcanvas-end profile-canvas"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header ">
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="profile-detail-mg">
          <Link to="/Profile">
            {/* <img src={ertugul} alt="" /> */}
            <img src={ image_url+customerData.user_image}  alt="" />
          </Link>
          <p className="u-name ">{customerData && customerData.user_name}</p>
          <p className="u-email">{customerData && customerData.business_email}</p>
        </div>

        <div class="offcanvas-body">
          <div className="account-list ">
            <div className="ac-icon">
              <img src={heart1} alt="" />
            
            </div>

            <Link to="/Wishlist">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>{t("Wishlist")}</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>

          <div className="account-list ">
            <div className="ac-icon">
              <img src={ic_my_orders} alt="" />
            </div>

            <Link to="/MyOrders">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>{t("My Orders")}</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>

          <div className="account-list ">
            <div className="ac-icon">
              <img src={ic_my_orders1} alt="" />
            </div>

            <Link to="/MyOffers">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>{t("My Offers")}</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>

          <div className="account-list ">
            <div className="ac-icon">
              <img src={ic_my_orders2} alt="" />
            </div>

            <Link to="/RefCode">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>{t("Ref Code")}</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>

          <div className="account-list ">
            <div className="ac-icon">
              <img src={ic_my_orders3} alt="" />
            </div>

            <Link to="/ActiveReferCode">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>{t("Your Referrals")}</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>

          


          
          <div className="account-list ">
            <div className="ac-icon">
              <img src={ic_my_orders4} alt="" />
            </div>
            {/* <Link to="/TermsConditions"> */}
            <Link to="/MyAddress">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>{t("My Address")}</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>

          
          {/* <div className="account-list ">
            <div className="ac-icon">
              <img src={ic_track_order} alt="" />
            </div>

            <Link to="#">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>Track Order</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div> */}
          <div className="account-list ">
            <div className="ac-icon">
              <img src={help1} alt="" />
            </div>

            <Link to="/ContactUs">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>{t("Contact Us")}</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>

          <div className="account-list ">
            <div className="ac-icon">
              <img src={download} alt="" />
            </div>

            <Link to="https://play.google.com/store/apps/details?id=app.bharatkrushiseva.com">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>{t("Download App")}</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>

        
          
          <div className="account-list ">
            <div className="ac-icon">
              <img src={about1} alt="" />
            </div>
            {/* <Link to="/TermsConditions"> */}
            <Link to="https://bharatkrushiseva.com/terms_condition.html">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>{t("Terms & Conditions")}</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div className="account-list ">
            <div className="ac-icon">
              <img src={about2} alt="" />
            </div>
            <Link to="https://bharatkrushiseva.com/terms_condition.html">
              <div className="inner-ac-list">
                <div className="ac-list-name">
                  <p>{t("Privacy Policy")}</p>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div className="account-list ">
            <div className="ac-icon">
              <img src={logout1} alt="" />
            </div>

            <Link to="/Login">
              <div className="inner-ac-list">
                
                <div className="ac-list-name">
                <Link to="#" onClick={()=>logOut()}>
                  <p>{t("Logout")}</p>
                  </Link>
                </div>

                <div className="arrow-icon">
                  <img
                    src={back1}
                    alt=""
                    style={{ height: "8px", width: "8px" }}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Account;
