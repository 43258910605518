import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./assets/style/main.scss";


import Home from "./pages/Home";
import Offers from "./pages/Offers";
import Products from "./pages/Products";
import Login from "./pages/Auth_Components/Login";
import Navbar from "./SharedComponents/Navbar";
// import CountDown from "./SharedComponents/CountDown";
import Detailslist from "./pages/Products/Detailslist";
import Cart from "./pages/Products/Cart";
import Account from "./SharedComponents/Account";
import ProductListing from "./pages/Products/ProductListing";
import Wishlist from "./pages/Wishlist";
import ContactUs from "./pages/ContactUs";
import CreateProfile from "./pages/Auth_Components/CreateProfile";
import NewPassword from "./pages/Auth_Components/NewPassword";
import PasswordOTP from "./pages/Auth_Components/PasswordOTP";
import MyOrders from "./pages/MyOrders/MyOrders";
import Notifications from "./pages/Notifications";
import Profile from "./pages/Profile";
import SpecialComboOffers from "./pages/SpecialComboOffers";

import RecentViewProduct from "./pages/RecentViewProduct";
import ShopByBrand from "./pages/ShopByBrand";
import Category from "./pages/Category";
import TrandingProduct from "./pages/TrandingProduct";
import Brand from "./pages/Brand";
import My_order_detail from "./pages/My_order_detail";
import Payment from "./pages/Payment";

import OrderPlace from "./pages/OrderPlace";
import MyAddress from "./pages/MyAddress";
import AddAddress from "./pages/AddAddress";
import EditAddress from "./pages/EditAddress";
import EditAddress1 from "./pages/EditAddress1";
import SearchProduct from "./pages/SearchProduct";
import BrandProduct from "./pages/BrandProduct";
import MyOffers from "./pages/MyOffers";
import RefCode from "./pages/RefCode";

import ActiveReferCode from "./pages/ActiveReferCode";
import PriceFilter from "./pages/PriceFilter";
import ProductReview from "./pages/productReview";
import { useContext, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from "./context/AppContext ";

import { Switch, Link } from 'react-router-dom'

import RouteMiddleware from "./SharedComponents/RouteMiddleware";


import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from 'react-router-dom';

const TRACKING_ID = "G-BW0HYHQKSN"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);






function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const {pathname}=useLocation();
  
  useEffect(() => {
    window.scrollTo(0,0)
  },[pathname])

  const currentRoute = useLocation().pathname;
  // const { logedIn, setLoggedIn } = useContext(AppContext);
  const navigate = useNavigate();
  const logedIn=localStorage.getItem("logedin")?true:false
  console.log(logedIn);

  useEffect(() => {
    if (!logedIn) {
      // navigate("/");
    } else 
      if(currentRoute === "/" )
          navigate("/home");
      else navigate(currentRoute);
  }, [logedIn]);

  // useEffect(() => {
  
  //   if (!logedIn) {
  //     navigate("/");
  //   } else {
  //     navigate("/home");
  //   }
  // }, [logedIn]);


  return (
    
    <>
     <ToastContainer />
      <div className="full-width-page"></div>
      
      <Routes>
        <Route path="Login" element={<Login />} />
  
        <Route path="CreateProfile" element={<CreateProfile />} />
        <Route path="NewPassword" element={<NewPassword />} />
        <Route path="PasswordOTP" element={<PasswordOTP />} />
      </Routes>

      <div className="App container">

    
        <Routes>
          {/* <Route path="navbar" element={<Navbar title="Navbar" />} /> */}
          <Route path="/" element={<Home />}  />
          <Route path="home" element={<Home />} />
          <Route path="products" element={<Products />}>
            <Route path="ProductListing" element={<ProductListing />} />
          </Route>
          <Route path="offers" element={<Offers />} />
          <Route path="SpecialComboOffers" element={<SpecialComboOffers />} />

          <Route path="RecentViewProduct" element={<RecentViewProduct />} />

          <Route path="ShopByBrand" element={<ShopByBrand />} />
          <Route path="Category" element={<Category />} />
          <Route path="Detailslist/:id" element={<Detailslist />} />
          <Route path="Cart" element={<Cart />} />
          <Route path="Account" element={<Account />} />
          <Route path="Wishlist" element={<Wishlist />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="MyOrders" element={<MyOrders />} />
          <Route path="Notifications" element={<Notifications />} />
          <Route path="Profile" element={<Profile />} />
          <Route path="TrandingProduct" element={<TrandingProduct />} />
          <Route path="Brand" element={<Brand />} Component={Brand} title={"Brand"} />
          <Route path="My_order_detail" element={<My_order_detail />} />
          <Route path="Payment" element={<Payment />} />
          <Route path="MyAddress" element={<MyAddress />} />
          <Route path="AddAddress" element={<AddAddress />} />
          <Route path="EditAddress" element={<EditAddress />} />
          <Route path="EditAddress1" element={<EditAddress1 />} />
          <Route path="OrderPlace" element={<OrderPlace />} />
          <Route path="SearchProduct" element={<SearchProduct />} />
          <Route path="BrandProduct" element={<BrandProduct />} />
          <Route path="MyOffers" element={<MyOffers />} />
          <Route path="RefCode" element={<RefCode />} />
          <Route path="ActiveReferCode" element={<ActiveReferCode />} />
          <Route path="PriceFilter" element={<PriceFilter />} />Review
          <Route path="productReview/:id" element={<ProductReview />} />
          
          

          

          {/* <Route path="CountDown" element={<CountDown />} /> */}
        </Routes>
        
      
        
      </div>
    </>
  );
}

export default App;
