import React, { useContext, useEffect, useState, useRef } from "react";
import { Routes, Route, useParams } from 'react-router-dom';
import Navbar from "../../SharedComponents/Navbar";
import { Detailsimages } from "../../SharedComponents/Detailsimages";
import Slider from "react-slick";
import heart from "../../assets/images/ic_heart.svg";
import Footer from "../../SharedComponents/Footer";
import bullet from "../../assets/images/ic_bullet_pt.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ic_call_red from "../../assets/images/ic_call_red.svg";
import country from "../../assets/slide/icons8-geography-30.png";
import grow from "../../assets/slide/grow.png";
import checkbuyer from "../../assets/slide/checkbuyer.png";
import country1 from "../../assets/slide/icons8-lock-32.png";
import country2 from "../../assets/slide/icons8-circle-30.png";
import bksassurred from "../../assets/slide/bksassurred.png";
import web_banner_offer from "../../assets/slide/web_banner_offer1.jpg";
import cashback from "../../assets/slide/cashback.jpg";

import best_deal from "../../assets/slide/best-deal-offer.png";
import percent from "../../assets/slide/discount-cou.png";

import country3 from "../../assets/slide/icons8-eye-30.png";
import ic_whatsapp from "../../assets/slide/icons8-whatsapp-30.png";
import ic_cart from "../../assets/slide/icons8-shopping-cart-24.png";

import country4 from "../../assets/slide/icon 25-2522-02.png";
import country7 from "../../assets/slide/icon 25-2522-03.png";
import country8 from "../../assets/slide/icon 25-2522-01.png";
import country9 from "../../assets/slide/icon 25-2522-04.png";
import ic_cart1 from "../../assets/slide/icons8-facebook-30 (2).png";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HoverImage from "react-hover-image";
import offer1 from "../../assets/img/offer1.jpg";
import offer2 from "../../assets/img/offer2.jpg";
import offer3 from "../../assets/img/offer3.jpg";
import offer4 from "../../assets/img/offer4.jpg";
import ic_heart from "../../assets/images/ic_heart.svg";
import arrow_white from "../../assets/images/view_all_arrow.svg";
import { Collapse } from 'react-collapse';
import { CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react'
import ScrollToTop from "react-scroll-to-top";
import { AppContext } from "../../context/AppContext ";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse, postWithAuthCallWithFormDataResponse } from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../SharedComponents/notify";
import { ScaleLoader } from "react-spinners";
import Modal from 'react-bootstrap/Modal';

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import StarRating from "../StarRating";
import { FaSmile, FaRegSmile, FaRegThumbsUp, FaStar } from 'react-icons/fa'; // Import the desired icon from react-icons
import logo from "../../assets/slide/BKS Logo - webp 300-106-01 (3).png";
import logo_bks from "../../assets/slide/BKS services2-01.png";
import call from "../../assets/img/ic_call.svg";
import download from "../../assets/slide/get-on-google-play_150x.webp";



function Detailslist() {

  const {id} = useParams();
  console.log("id**********",id)



  const responsive = {
    superLargeDesktop: {

      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };




  const image_url1 = "http://bharatkrushiseva.in"
  const [my_category, setMyCategory] = useState([]);
  console.log("my_category", my_category)
  const [topRightModal, setTopRightModal] = useState(false);

  const toggleShow = () => setTopRightModal(!topRightModal);
  const navigate = useNavigate();
  const image_url = "http://bharatkrushiseva.in"
  const image_offer_url = "http://bharatkrushiseva.in/media/"
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData)
  const [loading, setLoading] = useState(false);
  const logedIn = localStorage.getItem("logedin")




  const previous_data = useLocation().state;


  const lang = localStorage.getItem("lang")
  console.log("lang",lang)
  


  const [product_detail, setProductDetail] = useState([]);
  const [product_detail_images, setProductDetailimages] = useState([]);
  const [product_detail_item_packets, setProductDetaiitempackets] = useState([]);
  const [product_detail_items_pecifications, setProductDetailtemspecifications] = useState([]);
  const [product_detail_usemethod, setProductDetailitem_usemethod] = useState([]);
  const [product_detail_usemethod_item, setProductDetaiitempacketsitem] = useState([]);
  const [product_detail_offer_list, setProductDetailOfferList] = useState([]);

  console.log("product_detail_images************",product_detail_images);
   
 

  // console.log("product_detail**************", product_detail.product_detail.id)
  // console.log("product_detail", product_detail && product_detail.offer_list[0] && product_detail.offer_list[0].offer_type_title)

  const results = (product_detail && product_detail.product_detail && product_detail.product_detail.grand_total_without_discount)-(product_detail && product_detail.product_detail && product_detail.product_detail.grand_total) ;
  console.log("results", results)
  const grand_total_without_discount = product_detail && product_detail.product_detail && product_detail.product_detail.grand_total_without_discount

  // const saved = results / product_detail_usemethod_item && product_detail_usemethod_item[0].item_grand_price_without_discount;
  // // const dp = saved * 100;
  // // const result1 = dp.toString().slice(0, 5);

  const saved = results / grand_total_without_discount;
  const dp = saved * 100;
  const result1 = dp.toString().slice(0, 4);
  console.log("saved",result1)

  console.log("logedIn******************",logedIn)
  useEffect(() => {
    productdetail();
    
    if (logedIn==="true")
    {
    setletestOffersState({...latest_offers_state,token: customerData.token})
    }

   

  }, []);

  
  const [state, setState] = useState({
    language: lang===null ? "en" : lang,
    ...(logedIn === "true" && { token: customerData.token }),
    product_id: id,
  });

  const productdetail = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_PRODUCT_DETAIL_BYID,
      JSON.stringify({ ...state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("********************************");
      

        console.log("product_detail.average_rating", res.json.product_detail.average_rating);
        setProductDetail(res.json);
        setProductDetailimages(res.json.product_detail.item_images);
        setProductDetaiitempackets(res.json.product_detail.item_list);
        setProductDetailtemspecifications(res.json.product_detail.item_specification);
        setProductDetailitem_usemethod(res.json.product_detail.item_usemethod);
        setProductDetaiitempacketsitem(res.json.product_detail.item_list[0].item_packets);
        setProductDetailOfferList(res.json.offer_list);
        
        sessionStorage.setItem("product_packets_id", res.json.product_detail.item_list[0].item_packets[0].id)
      })
      .catch((err) => console.log(err));
  };

const [loadingHeart, setLoadingHeart] = useState(false);
const getproductId = (id) => {
    setLoadingHeart(true);
    if (logedIn === 'true') {
        postWithAuthCallWithErrorResponse(
            ApiConfig.ADD_PRODUCT_TO_USER_WISHLIST,
            JSON.stringify({ product_id: id, token: customerData.token })
        ).then((res) => {
            console.log("wishlist_add", res.json);
            if (res.json.status === "true") {
                notifySuccess(res.json.message);
            } else {
                notifyError(res.json.message);
            }
        }).catch((err) => {
            console.log(err);
            notifyError("Failed to add to wishlist.");
        }).finally(() => {
            setLoadingHeart(false); // Set loading to false regardless of success or error
        });
    } else {
        notifyError("Please login first.");
        setLoadingHeart(false);
    }
};

 

  const [latest_offers, setLatestOffers] = useState([]);
  console.log("latest_offers", latest_offers)

  const [latest_offers_state, setletestOffersState] = useState({
    language: lang===null ? "en" : lang,
    // token: customerData.token,
  });
  useEffect(() => {
    get_latest_offer();
    get_today_offer();
    getRecentViewProduct();
    if (logedIn==="true")
    {
    setletestOffersState({...latest_offers_state,token: customerData.token})
    }
    if (logedIn==="true")
    {
    setGetAddressIdState({...get_address_id_state,token: customerData.token})
    }
  }, []);

  const get_latest_offer = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_PRODUCT_LIST,
      JSON.stringify({ ...latest_offers_state, })
    )
      .then((res) => {
        console.log("setletestOffersState", res.json);
        setLatestOffers(res.json.product_list);

      })
      .catch((err) => console.log(err));

  };


  const [todays_offers, setTodayOffers] = useState([]);
  console.log("todays_offers", todays_offers)



  const [todays_offers_state, setTodayOffersState] = useState({
    language: lang===null ? "en" : lang,
    token: customerData.token,
  });

  const get_today_offer = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_TRANDING_PRODUCT_LIST,
      JSON.stringify({ ...todays_offers_state, })
    )
      .then((res) => {
        console.log("my_brand", res.json);
        setTodayOffers(res.json.product_list);

      })
      .catch((err) => console.log(err));

  };


  // recent view product api integrated code start //
  const [recentViewProduct, setRecentViewProduct] = useState([]);
  console.log("recentViewProduct**********", recentViewProduct)

  const [recentViewState, setRecentViewProductState] = useState({
    language: lang===null ? "en" : lang,
    token: logedIn === "true" ? customerData.token : undefined,
  });

  const getRecentViewProduct = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_LATEST_VIEW_PRODUCT,
      JSON.stringify({ ...recentViewState, })
    )
      .then((res) => {
        console.log("recentViewProduct***************", res.json);
        res.json.product_list && setRecentViewProduct(res.json.product_list);

      })
      .catch((err) => console.log(err));

  };
  // recent view product api integrated code end //

  

  const [p_packets_id, setp_packets_id] = useState([]);
  console.log("p_packets_id", p_packets_id)
  sessionStorage.setItem("p_packets_id", p_packets_id)


  // console.log("p_packets_id", p_packets_id===" " ?  product_detail_usemethod_item[0].id : p_packets_id)


  // product_detail_usemethod_item[0].id

  useEffect(() => {
    if (product_detail_usemethod_item && product_detail_usemethod_item.length > 0) {
      setSelectedItem(product_detail_usemethod_item[0]);
    }
  }, [product_detail_usemethod_item]);

  const getitempacketsId = (item) => {
    setSelectedItem(item);
  };

  const [selectedItem, setSelectedItem] = useState(null);


  // result = (marks >= 40) ? 'pass' : 'fail';
  let p_p_id = sessionStorage.getItem("product_packets_id")

  const product_type = product_detail && product_detail.product_detail && product_detail.product_detail.product_type
  console.log("product_type", product_type)

  const getAddToCart = (id) => {
   
      postWithAuthCallWithErrorResponse(
        ApiConfig.ADD_PRODUCT_TO_CART,

        product_type === "single" ?
          JSON.stringify({
            product_id: id,
            // packet_id: product_detail_usemethod_item[0].id,

            packet_id: selectedItem.id,
            token: customerData.token,
            product_count: 1,
            language: lang===null ? "en" : lang,
          })
          :
          JSON.stringify({
            product_id: id,
            token: customerData.token,
            product_count: 1,
            language: lang===null ? "en" : lang,
          })
      )
        .then((res) => {
          console.log("addtocart", res.json);

          if (res.json.status === "true") {
            notifySuccess(res.json.message)
            
           
          }
          else {
            notifyError(res.json.message)
          }
        })

        .catch((err) => console.log(err));
    
   
  };

  const getBuyNowToCart = (id) => {
    console.log("id", id)
    postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_PRODUCT_TO_CART,
      product_type === "single" ?
          JSON.stringify({
            product_id: id,
            // packet_id: product_detail_usemethod_item[0].id,

            packet_id: selectedItem.id,
            token: customerData.token,
            product_count: 1,
            language: lang===null ? "en" : lang,
          })
          :
          JSON.stringify({
            product_id: id,
            token: customerData.token,
            product_count: 1,
            language: lang===null ? "en" : lang,
          })
    )
      .then((res) => {
        console.log("addtocart", res.json);

        if (res.json.status === "true") {
          notifySuccess(res.json.message)

        }
        else {
          notifyError(res.json.message)
        }
        navigate("/Cart");
      })
      .catch((err) => console.log(err));
  };

  const [get_address_id_state, setGetAddressIdState] = useState({
    language: lang===null ? "en" : lang,
    // token: customerData.token,
  });

  const getAddressId = (id) => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_PRODUCT_DETAIL_BYID,
      JSON.stringify({
        ...get_address_id_state,
           product_id: id,
      })
    )
      .then((res) => {
        setLoading(false)
        console.log("*********************");
       
        setProductDetail(res.json);
        setProductDetailimages(res.json.product_detail.item_images);
        setProductDetaiitempackets(res.json.product_detail.item_list);
        setProductDetailtemspecifications(res.json.product_detail.item_specification);
        setProductDetailitem_usemethod(res.json.product_detail.item_usemethod);
        setProductDetaiitempacketsitem(res.json.product_detail.item_list[0].item_packets);
        // setProductDetail(res.json.product_detail.offer_list);

        sessionStorage.setItem("product_packets_id",res.json.product_detail && res.json.product_detail.item_list[0] && res.json.product_detail.item_list[0].item_packets[0] && res.json.product_detail.item_list[0].item_packets[0].id)
      })
      .catch((err) => console.log(err));
  };


  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};


const { t } = useTranslation();

useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])

useEffect(() => {

  if (logedIn === "true") {
    setBrand_id_on({ ...brand_id_on, token: customerData.token })
  }

  
}, []);




const [brand_id_on, setBrand_id_on] = useState({
  language: lang===null ? "en" : lang,
  // token:customerData.token,

});

const getBrandId = (id) => {
  console.log("id", id)
  setLoading(true);
  postWithAuthCallWithErrorResponse(

    ApiConfig.GET_ECOM_PRODUCT_LIST_BRAND,
    JSON.stringify({
      ...brand_id_on, brand_id: id
    })
  ).then((res) => {
    setLoading(false);


    navigate("/BrandProduct", {
      state: {
        data: res.json,
        search_key: res.json && res.json.product_list[0] && res.json.product_list[0].brand_names

      }
    });
  })
}


// review by product

const [my_review, setMyReview] = useState([]);

const [product_review, setProductReview] = useState({
  language: lang===null ? "en" : lang,
  product_id: id,
  feedback_type: 'image',
  feedback_for: 'product',
  ...(logedIn === "true" && { token: customerData.token }),
});

useEffect(() => {
  get_My_review();
}, []);

const get_My_review = () => {
  setLoading(true)
  postWithAuthCallWithErrorResponse(
    ApiConfig.GET_PRODUCT_REVIEWS,
    JSON.stringify({ ...product_review, })
  )
    .then((res) => {
      setLoading(false)
      setMyReview(res.json.feedback_list);
    })
    .catch((err) => console.log(err));
}


// review by product None
const [my_review_none, setMyReviewNone] = useState([]);

const [product_review_none, setProductReviewNone] = useState({
  language: lang===null ? "en" : lang,
  product_id: id,
  page_no:1,
  // feedback_type: 'none',
  feedback_for: 'product',
  ...(logedIn === "true" && { token: customerData.token }),
});

useEffect(() => {
  get_My_review_none();
}, []);

const get_My_review_none = () => {
  setLoading(true)
  postWithAuthCallWithErrorResponse(
    ApiConfig.GET_PRODUCT_REVIEWS,
    JSON.stringify({ ...product_review_none, })
  )
    .then((res) => {
      setLoading(false)
      setMyReviewNone(res.json.feedback_list);
    })
    .catch((err) => console.log(err));
}


// review by product video
const [my_review_video, setMyReviewVideo] = useState([]);

const [product_review_video, setProductReviewVideo] = useState({
  language: lang===null ? "en" : lang,
  product_id: id,
  feedback_type: 'video',
  feedback_for: 'product',
  ...(logedIn === "true" && { token: customerData.token }),
});

useEffect(() => {
  get_My_review_video();
}, []);

const get_My_review_video = () => {
  setLoading(true)
  postWithAuthCallWithErrorResponse(
    ApiConfig.GET_PRODUCT_REVIEWS,
    JSON.stringify({ ...product_review_video, })
  )
    .then((res) => {
      setLoading(false)
      setMyReviewVideo(res.json.feedback_list);
    })
    .catch((err) => console.log(err));
}


// model report

// ============check user login or not and the open login form==========

const [showLogin, setShowLogin] = useState(false);
const handleCloseLogin = () => setShowLogin(false);
const handleShowLogin = () => setShowLogin(true);

useEffect(() => {
  if (!logedIn) {
    const timer = setTimeout(() => {
      setShowLogin(true);
    }, 3000); // 3000 milliseconds = 3 seconds

    // Cleanup the timer if the component is unmounted before the timeout
    return () => clearTimeout(timer);
  }
}, [logedIn]);

// ============check user login or not and the open login form==========

//model how? used coupen
const [showHow, setShowHow] = useState(false);
const handleCloseHow = () => setShowHow(false);
const handleShowHow = () => setShowHow(true);




// model report
const [show, setShow] = useState(false);

const [myReviewId, setMyReviewId] = useState([]);
const [selectedReason, setSelectedReason] = useState(" ");
console.log('selectedReason***************',selectedReason)

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const handleReasonChange = (event) => {
  setSelectedReason(event.target.value);
};

const get_feedback_report = () => {
  setLoading(true);
  const add_feedback_review_state = {
    feedback_id: myReviewId,
    reporting_reason: selectedReason,
    token: logedIn === "true" ? customerData.token : undefined,
  };

  postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_PRODUCT_FEEDBACK,
      JSON.stringify(add_feedback_review_state)
    )
    .then((res) => {
      setLoading(false);
      console.log('feedback*************', res.json);
      // setFeedbackReview(res.json);
      if (res.json.status === "true") {
          notifySuccess(res.json.message)
          
        }

        else {
          notifyError(res.json.message)
        }
      handleClose(); // Close the modal after submitting the report
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
  };


  // Create Review
  const [showReview, setShowReview] = useState(false);
  const handleCloseReview = () => setShowReview(false);
  const handleShowReview = () => setShowReview(true);
  const [myCreateReviewId, setMyCreateReviewId] = useState([]);
  console.log('myCreateReviewId*************',myCreateReviewId)
  console.log('customerData*************',customerData)

  const [selectedValueImoji, setSelectedImojiValue] = useState(null);
  console.log('selectedValueImoji*************',selectedValueImoji)
  const [selectedPhotoVideoValue, setSelectedPhotoVideoValue] = useState(null);
  console.log('selectedPhotoVideoValue*************',selectedPhotoVideoValue)

  const handleIconClick = (value) => {
    setSelectedImojiValue(value);
  };

  const handleIconClick1 = (value) => {
    setSelectedPhotoVideoValue(value);
  };

  const [formDataReview, setFormDataReview] = useState({
    reason: '',
    photo: null,
    video: null
  });
  
  

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormDataReview({
        ...formDataReview,
        [name]: files[0]
      });
    } else {
      setFormDataReview({
        ...formDataReview,
        [name]: value
      });
    }
  };



  const handleSubmit = () => {
    setLoading(true);
    const add_photovideo_review_state = {
      feedback_for: "product",
      language :"en",
      product_id: myCreateReviewId,
      feedback_note: formDataReview.reason,
      feedback_rating:selectedValueImoji,
      feedback_type:selectedPhotoVideoValue,
      feedback_images: formDataReview.photo,  // Assuming a single photo
      feedback_video: formDataReview.video,
      token: logedIn === "true" ? customerData.token : undefined,
    };

    console.log('***********',add_photovideo_review_state)
    const formData= new FormData()
    formData.append("feedback_for","product")
    formData.append("language","en")
    formData.append("product_id",myCreateReviewId)
    formData.append("feedback_note",formDataReview.reason)
    formData.append("feedback_rating",selectedValueImoji)
    formData.append("feedback_type",selectedPhotoVideoValue)

      // Conditionally append image or video
    if (selectedPhotoVideoValue === 'image') {
      formData.append("feedback_images", formDataReview.photo);
    } else if (selectedPhotoVideoValue === 'video') {
      formData.append("feedback_video", formDataReview.video);
    }

    formData.append("token",logedIn === "true" ? customerData.token : undefined)


    
    postWithAuthCallWithFormDataResponse(
      ApiConfig.ADD_USER_ECOM_ORDER_FEEDBACK,
      formData
    )
    .then((res) => {
      setLoading(false);
      // setFeedbackReview(res.json);
      if (res.json.status === "true") {
          notifySuccess("Product Review Added Successfully")
          
        }

        else {
          notifyError(res.json.message)
        }
        handleCloseReview(); // Close the modal after submitting the report
        // Close the modal after submitting the report
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });

    console.log("Selected Value: ", selectedValueImoji);
    console.log("Form Data***********: ", formDataReview);
    console.log("Form Data reason***********: ", formDataReview.photo);
    // Call your API here with formData and selectedValue
    handleClose();
  };
  


  // *********************login functionality start*************************
  const [stateLogin, setStateLogin] = useState({
    mobile: "",
    language: "en",
  });


  const [errMsg, setErrMsg] = useState({ mobile: "" });
  const [data, setData] = useState({});


  const handleSubmitLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    if (stateLogin.mobile === "") {
      setErrMsg({ ...errMsg, mobile: t("Enter Your Mobile Number") });
      return;
    }
    else {
      postWithAuthCallWithErrorResponse(
        
        ApiConfig.LOGIN_OTP,
        JSON.stringify({ ...stateLogin, })
      )
      .then((res) => {
        setLoading(false);
        console.log("res", res.json);
        setData(res.json.data);
       
        if  (res.json.status==="true")
      {
        notifySuccess(res.json.message)
        navigate("/PasswordOTP", {
          state: { 
            message: res.json.message,
            mobile:stateLogin.mobile,
            language:stateLogin.language,
            product_id:id
     
           
         }
          });
      }
      else{
        notifyError(res.json.message)
        navigate("/Login")}
        // swal({
        //   title: "Success",
        //   text: "OTP sent successfully",
        //   icon: "success",
        //   button: true,
        // });
        console.log("mobile",stateLogin.mobile)
        
  
      })
      .catch((err) => console.log(err));
    }
    
  };
  // *********************login functionality start*************************



  
    


  const mainSliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);

  useEffect(() => {
    if (mainSliderRef.current && thumbnailSliderRef.current) {
      mainSliderRef.current.slickGoTo(0);
      thumbnailSliderRef.current.slickGoTo(0);
    }
  }, [product_detail_images]);

  const settingsMain = {
    asNavFor: thumbnailSliderRef.current,
    ref: mainSliderRef,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
  };

  const settingsThumbnails = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: mainSliderRef.current,
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true,
    ref: thumbnailSliderRef,
  };


  return (
    <>
      <Navbar />
      <main className="details-lists-main">
        {/* model for login */}
          <Modal show={showLogin} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >
          
          
          <Container className="setModelColorBg">
              <Row>
                <Col xs={12} sm={8} className="set-model-login1">
                <div>
                    <img src={logo} alt="logo" className="main-logo img_round27 set-login-logo" />
                </div>
               
                <div className="set-model-login">
                  <label>
                      {t("You don't want to miss this details")}
                  </label>
                </div>
                <div className="">
                  <label>
                      {t("Please login to view reviews and offers of this product")}
                  </label>
                </div>
                <div className="email-main-login mt-2">
                    <input
                      type="tel"
                      maxLength={10}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, mobile: "" });
                        setStateLogin({ ...stateLogin, mobile: e.target.value });
                      }}
                      className="email-input" 
                      placeholder={t("Enter Your Mobile Number")}
                    />
                </div>
                  {
                    <div className="text-danger">
                      <span> {errMsg.mobile}</span>
                    </div>
                  }

                <div className="">
                  <Link to="#" onClick={handleSubmitLogin}>
                    <button className="login-button animated-button1 mt-3 ">
                      {t("GET OTP")}
                      {loading ? (
                      <div
                        class="spinner-border"
                        style={{ textAlign: "right", color: "#FFFBF3" }}
                      />
                      ) : (
                      ""
                      )}
                    </button>
                  </Link>
                </div>
                <div className="mt-2 setmp">
                  <label>
                    {t("By continuing, you agree to our")}
                  </label>
                  <Link target="_blank" to={'https://bharatkrushiseva.com/terms_condition.html'}>
                    <div className="set-model-login2">
                      <label>
                        {t("Team of Service & Privacy Policy")}
                      </label>
                    </div> 
                  </Link>
                  <div>
                    <Button className="set-button-set" variant="secondary" onClick={handleCloseLogin}>
                        {t("Close")}
                    </Button>
                  </div>
                </div>



    
                
               

                  {/* <div>
                      <div>
                        <img src={logo} alt="logo" className="main-logo img_round27 set-login-logo" />
                      </div>
                      <div className="set-model-login">
                        <label>
                          {t("You don't want to miss this details")}
                        </label>
                      </div>
                      <div className="">
                        <label>
                          {t("Please login to view reviews and offers of this product")}
                        </label>
                      </div>
                      <div className="email-main-login mt-2">
                          <input
                            type="tel"
                            maxLength={10}
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, mobile: "" });
                              setStateLogin({ ...stateLogin, mobile: e.target.value });
                            }}
                            className="email-input" 
                            placeholder={t("Enter Your Mobile Number")}
                          />
                         
                      </div>
                        {
                            <div className="text-center text-danger">
                              <span> {errMsg.mobile}</span>
                            </div>
                          }
                      <div className="">
                      <Link to="#" onClick={handleSubmitLogin}>
                        <button className="login-button animated-button1 mt-3 ">
                      
                          {t("GET OTP")}

                          {loading ? (
                          <div
                            class="spinner-border"
                            style={{ textAlign: "right", color: "#FFFBF3" }}
                          />
                        ) : (
                          ""
                        )}
                      
                          </button>
                      </Link>
                      </div>
                      <div className="mt-2">
                        <label>
                          {t("By continuing, you agree to our")}
                        </label>
                        <Link target="_blank" to={'https://bharatkrushiseva.com/terms_condition.html'}>
                          <div className="set-model-login2">
                            <label>
                              {t("Team of Service & Privacy Policy")}
                            </label>
                          </div> 
                        </Link>
                      </div>

                      <div>
                        <Button className="set-button-set" variant="secondary" onClick={handleCloseLogin}>
                            {t("Close")}
                        </Button>
                      </div>

                  </div> */}
                </Col>
                <Col xs={12} sm={4} className="set-text1">
                  <div>
                    <div>
                      <img src={logo_bks} alt="logo" className="set-login-logo1"/>
                    </div>
                    <div className="set-model-login3">
                      <label>
                        {t("Order Faster & easier every time")}
                          <a href="https://play.google.com/store/apps/details?id=app.bharatkrushiseva.com" target="_blank" className="term">
                            <img className="topnavset4" src={download} alt="" />
                          </a>
                      </label>
                      
                    </div>
                   
                  </div>
                </Col>
              </Row>
          </Container>
        </Modal>

        {/* for button login */}
          {/* <button onClick={()=>{handleShowLogin()}}>login</button> */}
        {/* for button login */}




  {/* model how ? used */}
  <Modal show={showHow} className="custom-modal">
                    <Modal.Header >
                    <Modal.Title>{t("How to use cashback ?")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div>
                      <ul className="custom-list">
                        <li>{t("Use cashback discount on order summary and save more on your next order.")}</li>
                        <li>{t("Select Krushi Dhan to apply your Krushi Dhan discount on your next order.")}</li>
                      </ul>
                    </div>

                    </Modal.Body>
                      <img src={cashback} alt="BKS Assured"/>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseHow}>
                        {t("Close")}
                    </Button>
                    </Modal.Footer>
                </Modal>

      {/* model */}
                <Modal show={show} className="custom-modal">
                    <Modal.Header >
                    <Modal.Title>{t("Report this post")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t("Reason for reporting this post")}</Modal.Body>
                    <p className="set-report-text">{t("Your report is anonymous, except if your reporting an intellectual property infringement")}</p>
                   
                        <div class="form-check">
                            <input class="form-check-input" type="radio"  name="reportReason" value="Sexual content" id="flexRadioDefault1" onChange={handleReasonChange}  />
                            <label class="form-check-label" for="flexRadioDefault1">
                                {t("Sexual content")}
                            </label>
                        </div>

                        <div class="form-check mt-2">
                                <input class="form-check-input" type="radio" name="reportReason" value="Violent or repulsive content" id="flexRadioDefault2" onChange={handleReasonChange}  />
                                <label class="form-check-label" for="flexRadioDefault1">
                                    {t("Violent or repulsive content")}
                                </label>
                        </div>

                        <div class="form-check mt-2">
                            <input class="form-check-input" type="radio" name="reportReason" value="Spam or misleading" id="flexRadioDefault3"  onChange={handleReasonChange}/>
                            <label class="form-check-label" for="flexRadioDefault1">
                                {t("Spam or misleading")}
                            </label>
                        </div>
                        
                        <div class="form-check mt-2">
                            <input class="form-check-input" type="radio" name="reportReason" value="Other" id="flexRadioDefault4"  onChange={handleReasonChange}/>
                            <label class="form-check-label" for="flexRadioDefault1">
                                {t("Other")}
                            </label>
                        </div>
                        
                        <p class="mt-2 text-center" className="set-report-text">{t("This will help us to resolve future problems")}</p>
                        
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={get_feedback_report} disabled={loading}>
                      {loading ? (
                        <div className="spinner-border" style={{ textAlign: "right", color: "red" }} />
                      ) : (
                        t("Confirm")
                      )}
                    </Button>

                    </Modal.Footer>
                </Modal>


                 {/* model Create Review */}
                <Modal show={showReview} className="custom-modal">
                    <Modal.Header >
                    <Modal.Title>{t("Give your feedback")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="card-title">
                        <img src={`${image_offer_url}${customerData.user_image}`} className="set-text-view-more2" alt="User" />
                        &nbsp; {customerData.user_name}
                      </p>
                    </Modal.Body>
                      <p className="set-report-text">
                        {t("your feedback will be valuable for us. please give your feedback on these products")}
                       
                      </p>
                   
                        

                      <div className="form-check mt-2">
                        <Container>
                          <Row>
                            <Col xs={3} sm={3}>
                              <div 
                                className={`ecom_detail_list_changes ${selectedValueImoji === 2 ? 'selected' : ''}`} 
                                onClick={() => handleIconClick(2)}
                              >
                                <span> <FaSmile size={24} /> </span>
                              </div>
                              <p>{t("Not bad")}</p>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div 
                                className={`ecom_detail_list_changes ${selectedValueImoji === 3 ? 'selected' : ''}`} 
                                onClick={() => handleIconClick(3)}
                              >
                                <span> <FaRegSmile size={24} /> </span>
                              </div>
                              <p>{t("Okay")}</p>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div 
                                className={`ecom_detail_list_changes ${selectedValueImoji === 4 ? 'selected' : ''}`} 
                                onClick={() => handleIconClick(4)}
                              >
                                <span> <FaRegThumbsUp size={24} /> </span>
                              </div>
                              <p>{t("Good")}</p>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div 
                                className={`ecom_detail_list_changes ${selectedValueImoji === 5 ? 'selected' : ''}`} 
                                onClick={() => handleIconClick(5)}
                              >
                                <span> <FaStar size={24} /> </span>
                              </div>
                              <p>{t("Amazing")}</p>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                       <hr/>

                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1">{t("What is the main reason for your rating?")}</p>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            name="reason"
                            value={formDataReview.reason}
                            onChange={handleChange}
                          />
                        </div>
                        <hr/>
                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1"> {t("Add Photo (Optional)")}</p>
                          <input
                            type="file"
                            name="photo"
                            onClick={() => handleIconClick1('image')}
                            className="btn-shop-home"
                            onChange={handleChange}
                          />
                        </div>
                        <hr/>
                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1">{t("Add Video (Optional)")}</p>
                          <input
                            type="file"
                            name="video"
                            onClick={() => handleIconClick1('video')}
                            className="btn-shop-home"
                            onChange={handleChange}
                          />
                        </div>

                      <p className="set-report-text">
                        {t("upload photos or video related to the product like unboxing, Spraying, Product Usage etc")}
                      </p>
                        
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReview}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                      {loading ? (
                        <div className="spinner-border" style={{ textAlign: "right", color: "red" }} />
                      ) : (
                        t("Confirm")
                      )}
                    </Button>
                    </Modal.Footer>
                </Modal>



        <div className="details-bg ">
          <div className="inner-section-cart">
            <div className="row">
              <div className="col-lg-6">
                {loading ?
                  (
                    <ScaleLoader

                      color={"#E27A7A"}
                      loading={loading}
                      // size={10}
                      className="loading setloader"

                    />
                  ) : (
                    <div>
                     
                      {/* <img src={detailspic} alt="" style={{height:"375px",width:"375px"}} /> */}

                      <div className="slider_container">
                      <div className="thumbnail-slider">
                      <Slider {...settingsThumbnails}>
                        {product_detail_images &&
                          product_detail_images.map((image, index) => (
                            <div className="thumbnail-image-slide" key={index}>
                              <img
                                src={image_url + image.item_image}
                                alt={`thumbnail-${index}`}
                                className="thumbnail-image"
                                style={{
                                  width: "60px",
                                  height: "64px",
                                  objectFit: "contain",
                                  border: "none",
                                }}
                              />
                            </div>
                          ))}
                      </Slider>
                      </div>

                      <div className="main-image-slider">
                      <Slider {...settingsMain}>
                        {product_detail_images &&
                          product_detail_images.map((image, index) => (
                            <div
                              className="big-image-slide"
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={image_url + image.item_image}
                                alt={`main-${index}`}
                                className="set-imageslider"
                              />
                              {product_detail &&
                                product_detail.product_detail.is_bks_assured === 1 && (
                                  <p className="overlay-text1">
                                    <img className="set-images" src={bksassurred} alt="BKS Assured" />
                                  </p>
                                )}
                            </div>
                          ))}
                      </Slider>
                      </div>
                      
                    </div>
                 
                    </div>
                  )}

                   {/* for button */}
                   <div className="">      
                      <Container>
                        <Row>
                          <Col sm={4} xs={12}>
                            <div className="btn-detalil-products_one ">
                              <a aria-label="Chat on WhatsApp" href="https://wa.me/7838269440">
                                <button className="addcart-btn">
                                  <img src={ic_whatsapp} alt="" />
                                  &nbsp;
                                  {t("Whatsapp Order")}
                                </button>
                              </a>
                            </div>
                          </Col>
                          <Col sm={4} xs={12}>
                              <div className="btn-detalil-products_one ">
                                {
                                  logedIn==="true"
                                  ?
                                  <Link to="#" onClick={() => getAddToCart(
                                    product_detail && product_detail.product_detail.id,
                                  )}>
                                    <button className="addcart-btn">
                                      <img src={ic_cart} alt="" />
                                      &nbsp;
                                      {t("Add to Cart")}
                                    </button>
                                  </Link>
                                  :
                                  <Link to="/Login" onClick={() =>
                                    notifySuccess("Login First !!")
                                  }>
                                    <button className="addcart-btn">
                                      <img src={ic_cart} alt="" />
                                      &nbsp;
                                      {t("Add to Cart")}
                                    </button>
                                  </Link>
                                  }
                                </div>
                          </Col>
                          <Col sm={4} xs={12}>
                          <div className="btn-detalil-products_one">
                            {
                              logedIn==="true"
                              ?
                              <Link to="#" onClick={() => getBuyNowToCart(
                                product_detail && product_detail.product_detail.id,
                              )}>
                                <button className="getquote-btn1 ms-2"> {t("Buy Now")}</button>
                              </Link>
                              :
                              <Link to="/Login" onClick={() =>
                                notifySuccess("Login First !!")
                              }>
                                <button className="getquote-btn1 ms-2">
                                  <img src={ic_cart} alt="" />
                                  &nbsp;
                                  {t("Buy Now")}
                                </button>
                              </Link>
                            }
                          </div>
                          </Col>
                          
                        </Row>
                      </Container>
                  </div>
                  {/* for button */}
                
              </div>
              
              <div className="col-lg-6 mt-4 right-col">
                <div className="row right-form">
                  <div className="top-heading-heart heart-divide">
                    <div className="hot-text">
                      <label className="steel-text">
                        {t("Home")} <span className="breadcrumb-separator">&gt;</span> {product_detail && product_detail.product_detail && product_detail.product_detail.category.category_name}
                        <span className="breadcrumb-separator"> &gt;</span> {product_detail && product_detail.product_detail && product_detail.product_detail.product_name}
                        <span className="breadcrumb-separator"> &gt;</span> {product_detail && product_detail.product_detail && product_detail.product_detail.brand_names}
                      </label>
                      <p>{product_detail && product_detail.product_detail && product_detail.product_detail.product_name} | {product_detail && product_detail.product_detail && product_detail.product_detail.brand_names.replace(/,\s*$/, '')}</p>
                      
                      <div className="text_set">
                        {product_detail?.product_detail?.average_rating !== undefined && (
                          <div className="rating-container">
                            <StarRating rating={product_detail.product_detail.average_rating} />
                            &nbsp;
                            <b className="rating-container1 btn btn-warning mrc3">
                              {product_detail.product_detail.average_rating}
                            </b>

                            <label className="set-text-view-more1">
                              <img className="set-text-view-more2" src={checkbuyer} alt="" />
                              {product_detail && product_detail.product_detail && product_detail.product_detail.rating_count} {t("Valid Buyer")}
                              &nbsp;
                              {product_detail && product_detail.product_detail.is_bks_assured === 1 ? (
                                <img className="set-images bksassured-image" src={bksassurred} alt="" />
                              ) : null}
                            </label>

                          </div>
                        )}
                      </div>
                     <div className="hot-text">
                        <label  className="heading-black_n set-price">{t("Price")}: {product_detail && product_detail.product_detail && product_detail.product_detail.grand_total} {t("Rs")}</label>
                        &nbsp;&nbsp;
                      <label className=""> <s>  {product_detail && product_detail.product_detail && product_detail.product_detail.grand_total_without_discount} {t("Rs")} </s></label>
                        &nbsp;&nbsp;
                        <label className=""> 
                        <button type="button" class="btn btn-set-off">
                        {result1} % {t("off")} 
                        </button>
                      </label>
                      <br/>
                      <label className="heading-black_n set-price">
                        <img src={percent} alt="" className="set-text-view-more2"/>
                        &nbsp;
                        {t("Saved")} {t("Price")}: {results} {t("Rs")}
                        </label>
                   </div> 

                      
                      <div className="mt-2">
                      <Link to="#" onClick={() => getBrandId(product_detail && product_detail.product_detail && product_detail.product_detail.brand_id)}>
                        <p className="set-text-view-more">{t("and")} {product_detail && product_detail.product_detail && product_detail.product_detail.brand_names} {t("products")}
                        <span style={{fontSize:"10px"}} className="breadcrumb-separator"> &gt;</span>
                        </p>
                        </Link>
                        
                      </div>
                      <Container>
                  <Row>
                    <Col sm={12}>
                      <div className="mt-2">
                        <span> <img className="set-text-view-more2" src={grow} alt="" /> </span>
                        <label className="set-text-view-more1">{t("In last 7 days")} {product_detail && product_detail.product_detail && product_detail.product_detail.previous_order_count} {t("smart farmers ordered this")}</label>
                      </div>
                    </Col>
                  </Row>
                </Container>
                
                <button className="animated-button mt-3">
                  {result1 <= 10
                    ?  t('Best Offers')
                    : result1 <= 20
                    ? "Today's Great Offer"
                    : result1 <= 30
                    ? 'Lowest Price Offer'
                    : result1 <= 40
                    ? 'Festive Special Offer'
                    : 'Limited Time Offer'}
                </button>
                <br/>
              
                </div>

                <div className="heart">
                  <Link onClick={() => getproductId(product_detail.product_detail.id)}>
                    {loadingHeart ? (
                      <div
                        class="spinner-border"
                        style={{ textAlign: "right", color: "red" }}
                      />
                    ) : (
                      <img src={heart} alt="" className="me-3" />
                    )}
                  </Link>
                </div>
                  </div>

                  <br/>

              <div className="price-offer-section mt-2">
                  <div className=" detail-card top-heading-heart"> 
                  <div>
                    <div className="right-form" id="radioBtn"> 
                    <label>{t("Packing Size")} : &nbsp;</label>
                      
                    <label className="heading-black_n set-price">
                        {selectedItem ? (
                          <>
                            {selectedItem.packet_quantity}
                            {selectedItem.packingunit_id_fk__unit_name}
                          </>
                        ) : (
                          'Select an item'
                        )}
                      </label>
                    </div>
                  </div>
                  
              <Container>
                  <Row>
                    <Col xs={12} sm={12}>
                      <Card className="">
                        <Card.Body>
                          <Card.Text>
                            <Row>
                              {product_detail_usemethod_item &&
                                product_detail_usemethod_item.map((product_detail_usemethod_item_list) => {
                                  return (
                                    <Col className="pts" xs={6} sm={3} key={product_detail_usemethod_item_list.id}>
                                      <Link to="#" onClick={() => getitempacketsId(product_detail_usemethod_item_list)}>
                                        <div id="radioBtn">
                                          <button type="button" className="btn btn-outline-success">
                                            {product_detail_usemethod_item_list.packet_quantity}
                                            {product_detail_usemethod_item_list.packingunit_id_fk__unit_name}
                                            <br />
                                            {product_detail_usemethod_item_list.item_grand_price} {t("Rs")}
                                          </button>
                                        </div>
                                      </Link>
                                    </Col>
                                  );
                                })}
                            </Row>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
              </Container>

              <div className="set-mp">
                      {product_detail &&
                        product_detail.product_detail &&
                        product_detail.product_detail.grand_total &&
                        (
                          <div>
                            <p className="set-text-view-more1 ">{t("After delivery you will get up to")} {(product_detail.product_detail.grand_total * 0.05).toFixed(2)} {t("Rs cashback")}
                              {" "}{t("Use it to save on your next order")}{" "}
                              
                            </p>
                            {/* <span className="set-text-view-more" > how ?</span> */}
                          </div>

                        )}
              </div>

              <div>
              <p className="set-text-view-more1 ">
                {t("Use it to save on your next order")}{" "}
                <Button variant="link" className="btn-see-all set-report-button"
                  onClick={()=>{
                    handleShowHow()
                    }}
                  >
                  <span className="set-text-view-more" >{t("how ?")}</span>
                </Button>
                
              </p>
            </div>
              </div>
              </div> 
              </div>
            </div> 
            <div>
              {logedIn === 'true'
              ?
              <div className="price-offer-section mt-4">
            <Container className="set-bg-color">
              <Row>
                <Col xs={6} sm={6}>
                <div >
                  <img src={best_deal} alt="" className="set-text-view-more3" />

                  <p className="set-text-view-more1 ml6">
                    {t("Get this product at")}
                  </p>
                  &nbsp;
                  <span className="set-text-view-more">
                    {product_detail && product_detail.product_detail ? (
                      `${(product_detail.product_detail.grand_total * 0.95).toFixed(2)}`
                    ) : (
                      <span style={{ color: 'red' }}>Error: product_detail or grand_total is undefined</span>
                    )}
                    &nbsp;
                    {t("Rs")}
                  </span>
                  <p className="set-text-view-more ml5">
                    {t("Bank offer | Prepaid")}
                  </p>
                </div>

                </Col>

                <Col xs={6} sm={6} className="set-text-offers">
                <Link to="/MyOffers">
                  <div className="">
                    <div style={{display:"inline-flex"}}>
                      <img src={percent} alt="" className="set-text-view-more2" />
                      &nbsp;
                      <div className="">
                        <p className="set-text-view-more semp">
                          {t("View all offers and coupons")}
                        </p>
                      </div>
                    </div>  
                  </div>
                </Link>
                </Col>


              </Row>
            </Container>

            <div>
              <Container>
                <Row className="bordered-row">
                {product_detail_offer_list && product_detail_offer_list.map((product_offer_list_get) => {
                  console.log("product_offer_list_get",product_offer_list_get)
                  return (
                  <Col xs={12} md={4}>
                    <Card style={{ width: '100%' }}>
                      <Card.Img variant="top" src={image_offer_url + product_offer_list_get.offer_banner} style={{height:'180px'}} />
                      <Card.Body>
                        <Card.Title>
                          <span className="set-text-view-more">{product_offer_list_get.offer_type_title}</span>
                        </Card.Title>
                        <Card.Text>
                          <span className="set-text-view-more-offer">{product_offer_list_get.offer_type_desc}</span>
                        </Card.Text>
                        <button class="getquote-ntn2 ms-2"> {t("Grab Offers")} </button>

                      </Card.Body>
                    </Card>
                  </Col>

                    );
                  })}
                
                </Row>
              </Container>
            </div>
            </div>
              :
              <div className="">
                <Link to={`/Login`}>
                  <img style={{'width':'100%'}} src={web_banner_offer} alt="" />
                </Link>
              </div>
              }
            </div>

            <div className="set-text-view-more">
              {
              product_detail && product_detail.product_detail && product_detail.product_detail.has_specification_video === 1 ? (
                <div>
                  <div className="flex-container set-mp ">
                    <p className="set-text-view-more1 set-rating">
                      {t("How to use this product")}
                    </p>
                  </div>
                  <div className="flex-container set-mp">
                    <video controls autoPlay muted className="video-class setvideohw">
                      {product_detail.product_detail.specification_video ? (
                        <source
                          src={image_url + product_detail.product_detail.specification_video}
                          type="video/mp4"
                        />
                      ) : (
                        <p>Video not available</p>
                      )}
                    </video>
                  </div>
                </div>
              ) : (
                ""
              )
            }
            
        </div>

        <div>

          <div className="set-text-view-more">
            <div className="flex-container set-mp">
              <p className="set-text-view-more1 set-rating">
                    {t("Rating and Review")}
              </p>
            </div>
            <div className="text_set">
              {product_detail?.product_detail?.average_rating !== undefined && (
                <div className="rating-container">
                  <StarRating rating={product_detail.product_detail.average_rating} />
                  &nbsp;
                  <b className="rating-container1 btn btn-warning mrc3">
                    {product_detail.product_detail.average_rating}
                  </b>
                </div>
              )}
            </div>
          </div>
          <label className="set-text-view-more1">
            <img className="set-text-view-more2" src={checkbuyer} alt="" />
            {product_detail && product_detail.product_detail && product_detail.product_detail.rating_count} {t("Valid Buyer")}            
          </label>

        </div>

{/* review by video */}
        <div className="mt-4">
          <Container>
            <Row>
                <Col xs={6} sm={6} >
                    <p className="set-rating">
                        {t("Review by video")}
                    </p>
                </Col>
                <Col xs={6} sm={6} className="set-text-offers">
                  <Link target="_blank" to={`/productReview/${product_detail && product_detail.product_detail && product_detail.product_detail.id}`}>
                    <Button variant="link" className="btn-see-all set-report-button">
                      {t("See All")}
                    </Button>
                  </Link>
                </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Container>
            <Row className="bordered-row">
            {Array.isArray(my_review_video) && my_review_video ?.length > 0 ? (
                      my_review_video.slice(0, 4).map((review, index) => {
                        const feedback = review.feedback_id_fk;
                        const user = feedback.user_id_fk;
                        return (
                  <Col xs={6} md={2}>
                    <Card style={{ width: '100%', position: 'relative', overflow: 'hidden' }}>
                      <video autoPlay muted loop className="video-class setvideohw1">
                        <source src={`${image_url}${feedback.feedback_video}`} type="video/mp4" />
                      </video>
                      <div className="set-overlay">
                        <p className="set-reviewvideo" style={{ margin: 0 }}>
                          <StarRating rating={feedback.feedback_rating} />
                        </p>
                        <p className="set-reviewphoto">
                          <img src={`${image_offer_url}${user.user_image}`} className="set-text-view-more2" alt="User" />
                          &nbsp; {user.user_name} 
                        </p>
                      </div>
                    </Card>
                  </Col>
                    );
                    })
                  ) : (
                <p>
                  { logedIn==="true" ?
                    t("No reviews available") 
                    : 
                    // <div className="set-offerbanner">
                      <Link to={`/Login`}>
                        <p className="set-text-view-more">{t("Please login first to show Video review")}</p>
                      </Link>
                    // </div>
                    
                    }
                </p>
                  
                )}
            </Row>
          </Container>

        </div>

{/* review by images */}
<div className="mt-4">
  <Container>
    <Row>
        <Col xs={6} sm={6} >
            <p className="set-rating">
                {t("Review with images")}
            </p>
        </Col>
        <Col xs={6} sm={6} className="set-text-offers">
          <Link target="_blank" to={`/productReview/${product_detail && product_detail.product_detail && product_detail.product_detail.id}`}>
            <Button variant="link" className="btn-see-all set-report-button">
              {t("See All")}
            </Button>
          </Link>
        </Col>
    </Row>
  </Container>
</div>
<div>
  <Container>
    <Row className="bordered-row">
    {Array.isArray(my_review) && my_review.length > 0 ? (
        my_review.slice(0, 4).map((review, index) => {
        const feedback = review.feedback_id_fk;
        const user = feedback.user_id_fk;
        return (
          <Col xs={6} md={2}>
          <Card style={{ width: '100%', position: 'relative', overflow: 'hidden' }}>
            {feedback.feedback_images.map((image, imgIndex) => (
              <Card.Img 
                key={imgIndex} 
                variant="top" 
                src={`${image_url}${image.feedback_image}`} 
                className="card-image" 
              />
            ))}
            <div className="set-overlay">
              <p className="set-reviewvideo" style={{ margin: 0 }}>
                <StarRating rating={feedback.feedback_rating} />
                <label>
                  <img src={`${image_offer_url}${user.user_image}`} className="set-text-view-more2" alt="User" />
                  &nbsp; <label className="set-text-view-more-offer"> {user.user_name} </label>
                </label>
                
              </p>
              {/* <p className="set-reviewphoto">
               
              </p> */}
            </div>
          </Card>
        </Col>
            );
            })
          ) : (
        <p>
          { logedIn==="true" ?
            t("No reviews available") 
            : 
            // <div className="set-offerbanner">
              <Link to={`/Login`}>
                <p className="set-text-view-more">{t("Please login first to show images review")}</p>
              </Link>
            // </div>
            
            }
        </p>
          
        )}
    </Row>
  </Container>

    </div>
            
  {/* All Review */}
  <div className="mt-4">
  <Container>
    <Row>
        <Col xs={6} sm={6} >
            <p className="set-rating">
                {t("All Review")}
            </p>
        </Col>
        <Col xs={6} sm={6} className="set-text-offers">
          <Link target="_blank" to={`/productReview/${product_detail && product_detail.product_detail && product_detail.product_detail.id}`}>
            <Button variant="link" className="btn-see-all set-report-button">
              {t("See All")}
            </Button>
          </Link>
        </Col>
    </Row>
  </Container>
</div>

<div>
  <div className="review-details ">
      <Container>
          <Row>
            {Array.isArray(my_review_none) && my_review_none.length > 0 ? (
              my_review_none.slice(0, 10).map((review, index) => {
                const feedback = review.feedback_id_fk;
                const user = feedback.user_id_fk;
                return (
              <Col xs={12} sm={6}>
                  <Row>
                    <Col xs={8} sm={8}> 
                      <img src={`${image_offer_url}${user.user_image}`} className="set-text-view-more2" alt="User" />
                      &nbsp; {user.user_name}
                    </Col>
                      <Col xs={4} sm={4} className="set-text-offers1">
                        <Button variant="link" className="btn-see-all set-report-button"
                          onClick={()=>{
                            setMyReviewId(feedback.id)
                            handleShow()
                            }}
                          >
                          {t("report")}
                        </Button> 
                    </Col>
                  </Row>
                    <p>
                      <StarRating rating={feedback.feedback_rating} />
                      <label className="set-text-view-more1"><img className="set-text-view-more2"  src={checkbuyer} alt="" />{t( "Valid Buyer" )}</label>
                    </p>
                    <p>
                      <span className="set-review-font">{feedback.feedback_note}</span>
                    </p>
                    <p>{new Date(feedback.created_at).toLocaleString()}</p> 
                    <hr></hr>
              </Col>
             );
            })
            ) : (
            <p>
              { logedIn==="true" ?
                t("No reviews available") 
                : 
                <Link to={`/Login`}>
                  <p className="set-text-view-more">{t("Please login first to show review")}</p>
                </Link>
                
                }
            </p>
            )}

          </Row>
            
      </Container>
  </div>
</div>


    
    

          


           
                
           
{/* review by video old */}
              {/* <div className="Review by photos mt-3">
                <div className="flex-container set-mp">
                  <p className="set-rating">
                        {t("Review by video")}
                  </p>
                  <Link target="_blank" to={`/productReview/${product_detail && product_detail.product_detail && product_detail.product_detail.id}`}>
                    <Button variant="link" className="btn-see-all set-report-button">
                      {t("See All")}

                    </Button>
                    
                  </Link>
                </div>
                  <Container className="mt-2">
                    <Row>
                      <Col xs={12} sm={12}>
                      <Container>
                        <Row className="bordered-row">
                        {Array.isArray(my_review_video) && my_review_video ?.length > 0 ? (
                          my_review_video.slice(0, 4).map((review, index) => {
                            const feedback = review.feedback_id_fk;
                            const user = feedback.user_id_fk;
                            
                            return (
                                <Col key={index} xs={12} sm={3} className="set-review-font1">

                                <div className="review-details">
                                  <p>
                                    <img src={`${image_offer_url}${user.user_image}`} className="set-text-view-more2" alt="User" />
                                    &nbsp; {user.user_name}
                                  </p>
                                  <p>{new Date(feedback.created_at).toLocaleString()}</p> 
                                  <p>
                                    <StarRating rating={feedback.feedback_rating} />
                                  </p>
                                </div>

                                  
                                  <div className="text_set">
                                    <video controls autoPlay muted className="video-class setvideohw">
                                      <source src={`${image_url}${feedback.feedback_video}`} type="video/mp4" />
                                    </video>
                                  </div>
                                  

                                  <div className="text_set">
                                      <label className="set-review-font"><img className="set-text-view-more2"  src={checkbuyer} alt="" />{t( "Valid Buyer" )}</label>
                                  </div>
                                  <p>
                                    <span className="set-review-font">{feedback.feedback_note}</span>
                                  </p>
                                  
                                </Col>
                              );
                            })
                          ) : (

                          
                            <p>
                              { logedIn==="true" ?
                                t("No reviews available") 
                               : 
                               <Link to={`/Login`}>
                                  <p className="set-text-view-more">{t("Please login first to show review")}</p>
                               </Link>
                               
                               }
                            </p>
                            
                          )}
                        </Row>
                      </Container>
                      </Col>
                    </Row>
                  </Container>

              </div> */}
{/* review by images old */}
              {/* <div className="Review by photos">
                <div className="flex-container set-mp">
                  <p className="set-rating">
                        {t("Review from smart farmers")}
                  </p>
                  <Link target="_blank" to={`/productReview/${product_detail && product_detail.product_detail && product_detail.product_detail.id}`}>
                    <Button variant="link" className="btn-see-all set-report-button">
                      {t("See All")}
                    </Button>
                  </Link>
                </div>

                <Container className="mt-2">
                  <Row>
                    <Col xs={12} sm={12}>
                    <Container>
                      <Row className="">
                      {Array.isArray(my_review) && my_review.length > 0 ? (
                        my_review.slice(0, 4).map((review, index) => {
                          const feedback = review.feedback_id_fk;
                          const user = feedback.user_id_fk;
                          return (
                              <Col key={index} xs={12} sm={3} className="bordered-col1">

                              <div className="set-review-font1">
                                <p>
                                  <img src={`${image_offer_url}${user.user_image}`} className="set-text-view-more2" alt="User" />
                                  &nbsp; {user.user_name}
                                </p>
                                <p>{new Date(feedback.created_at).toLocaleString()}</p> 
                                <p>
                                  <StarRating rating={feedback.feedback_rating} />
                                </p>
                              </div>

                                {feedback.feedback_images.map((image, imgIndex) => (
                                  <div key={imgIndex}>
                                    <img src={`${image_url}${image.feedback_image}`} alt={`Feedback ${imgIndex}`} className="body-image" />
                                  </div>
                                ))}

                                <div className="">
                                    <label className="set-review-font"><img className="set-text-view-more2"  src={checkbuyer} alt="" />{t( "Valid Buyer" )}</label>
                                </div>
                                <p>
                                  <span className="set-review-font">{feedback.feedback_note}</span>
                                </p>
                                
                              </Col>
                            );
                          })
                        ) : (
                          
                          ""

                        )}
                      </Row>
                    </Container>
                    </Col>
                  </Row>
                </Container>

              </div> */}

              {/* <div className="">
              {Array.isArray(my_review_none) && my_review_none.length > 0 ? (
                        my_review_none.slice(0, 10).map((review, index) => {
                          const feedback = review.feedback_id_fk;
                          const user = feedback.user_id_fk;
                          return (
                          <div className="review-details">
                            <p>
                              <img src={`${image_offer_url}${user.user_image}`} className="set-text-view-more2" alt="User" />
                              &nbsp; {user.user_name}
                              
                              <Button variant="link" className="btn-see-all set-report-button"
                                onClick={()=>{
                                  setMyReviewId(feedback.id)
                                  handleShow()
                                  }}
                                >

                                {t("report")}
                              </Button>
                            </p>
                            
                            <p>
                              <StarRating rating={feedback.feedback_rating} />
                              <label className="set-text-view-more1"><img className="set-text-view-more2"  src={checkbuyer} alt="" />{t( "Valid Buyer" )}</label>

                            </p>
                            <p>
                              <span className="set-review-font">{feedback.feedback_note}</span>
                            </p>
                            <p>{new Date(feedback.created_at).toLocaleString()}</p> 
                            <hr></hr>
                            
                          </div>
                         
                          );
                        })
                      ) : (
                      <p>
                        { logedIn==="true" ?
                          t("No reviews available") 
                          : 
                          <Link to={`/Login`}>
                          <p className="set-text-view-more">{t("Please login first to show review")}</p>
                          
                          </Link>
                          
                          }
                      </p>
                      )}
                      
              </div> */}
                  
              {/* <Link target="_blank" to={`/productReview/${product_detail && product_detail.product_detail && product_detail.product_detail.id}`}>
                <div className="set-text-view-more">
                  {t("View all review...")}
                </div>
              </Link> */}

                  <div className="hot-text set-mp">
                    <p>{product_detail && product_detail.product_detail && product_detail.product_detail.item_specification[0].spec_title}</p>
                    <label className="steel-text">
                      {product_detail && product_detail.product_detail && product_detail.product_detail.item_specification[0].spec_desc}
                    </label>
                  </div>
                  <div >
                    {t("Ingredients Used")} :
                    {product_detail_item_packets &&
                      product_detail_item_packets.map((item_list) => {
                        return (
                          console.log(item_list),
                          (
                            <div>
                              <img src={bullet} alt="" />
                              <span className="ms-1">
                                {item_list && item_list.item_name}
                              </span>
                              <label htmlFor="" className="over-text  ">
                                {item_list && item_list.item_ingredient}
                              </label>
                            </div>

                          )
                        );
                      })}
                  </div>
                  {/* 
                  <div className="hot-text">
                    <p>{product_detail && product_detail.product_detail && product_detail.product_detail.item_specification[1].spec_title}</p>
                    <label className="steel-text">
                      {product_detail && product_detail.product_detail && product_detail.product_detail.item_specification[1].spec_desc}
                    </label>
                  </div> */}
                </div>
                <hr/>

              
                <div className="btn-detalil-products">
                  <button className="addcart-btn"
                    onClick={()=>{
                      setMyCreateReviewId(product_detail && product_detail.product_detail && product_detail.product_detail.id)
                      handleShowReview()
                      }
                      }
                      >
                    {t("Create a video review")}
                  </button>
                  &nbsp;&nbsp;

                  <button className="addcart-btn"
                    onClick={()=>{
                      setMyCreateReviewId(product_detail && product_detail.product_detail && product_detail.product_detail.id)
                      handleShowReview()
                    }
                    }
                    >
                      {t("Give review and rating")}
                    </button>
                    &nbsp;
                    
                    <a href="tel:7838269440">
                    <button className="addcart-btn">
                      <img src={ic_call_red} alt="" />
                      &nbsp;
                      {t("Expert Advice")}
                    </button>

                  </a>
                    &nbsp;

                    <a aria-label="Chat on WhatsApp" href="https://wa.me/7838269440">
                      <button className="addcart-btn">
                        <img src={ic_whatsapp} alt="" />
                        &nbsp;
                        {t("Whatsapp Order")}
                      </button>
                    </a>
                    &nbsp;

                    
                    {
                      logedIn==="true"
                      ?
                      <Link to="#" onClick={() => getAddToCart(
                        product_detail && product_detail.product_detail.id,
                      )}>
                        <button className="addcart-btn">
                          <img src={ic_cart} alt="" />
                          &nbsp;
                          {t("Add to Cart")}
                        </button>
                      </Link>
                      :
                      <Link to="/Login" onClick={() =>
                        notifySuccess("Login First !!")
                      }>
                        <button className="addcart-btn">
                          <img src={ic_cart} alt="" />
                          &nbsp;
                          {t("Add to Cart")}
                        </button>
                      </Link>
                      }
                    
                    

                      {
                      logedIn==="true"
                      ?
                      <Link to="#" onClick={() => getBuyNowToCart(
                        product_detail && product_detail.product_detail.id,
                      )}>
                        <button className="getquote-btn1 ms-2"> {t("Buy Now")}</button>
                      </Link>
                      :
                      <Link to="/Login" onClick={() =>
                        notifySuccess("Login First !!")
                      }>
                        <button className="getquote-btn1 ms-2">
                          <img src={ic_cart} alt="" />
                          &nbsp;
                          {t("Buy Now")}
                        </button>
                      </Link>
                    }
                      &nbsp;
                </div>

                <div className="">
                <Container>
                  <Row className="mt-3">
                    <Col sm={12}>
                      <div className="">
                        <span> <img  src={country} alt="" /> </span>
                        <label>{t("Country of origin: India")}</label>
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="" >
                        <span> <img  src={country1} alt="" /> </span>
                        <label>{t("Secure Payments")}</label>
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="">
                        <span> <img   src={country2} alt="" /> </span>
                        <label>{t("In stock, ready to ship")}</label>
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="">
                        {/* <span> <img  src={country3} alt="" /> </span>
                        <label><b>65520 </b> {t("people viewed this")}</label>
                        &nbsp;
                        <label className="image_round68">{t("product in last 30 days")} </label> */}
                      </div>
                      {/* product in last 30 days */}
                    </Col>
                  </Row>
                </Container>

                </div>

              <hr/>


            

            <div className="image_round69">
                <Container>
                  <Row>
                    <Col xs={3} sm={3}>
                      <div className="ecom_detail_list_changes">
                        <span> <img className="image_round70" src={country4} alt="" /> </span>
                      </div>
                      <label className="image_round68">{t("Trusted Brands")} </label>
                      <br/>
                      {/* <button className="addcart-btn">
                        <img src={ic_whatsapp} alt="" />
                        &nbsp;
                        Share
                      </button> */}
                    </Col>
                    <Col xs={3} sm={3}>
                      <div className="ecom_detail_list_changes" >
                        <span> <img className="image_round70" src={country7} alt="" /> </span>
                      </div>
                      <label className="image_round68">100% {t("Original Product")} </label>
                      <br/>
                      {/* <button className="addcart-btn">
                        <img src={ic_cart1} alt="" />
                        &nbsp;
                        Share
                      </button> */}
                    </Col>
                    <Col xs={3} sm={3}>
                      <div className="ecom_detail_list_changes">
                        <span> <img  className="image_round70" src={country8} alt="" /> </span>
                      </div>
                      <label className="image_round68">{t("Secure Payments")}</label>
                      <br/>
                      {/* <button className="addcart-btn">
                        <img src={ic_cart} alt="" />
                        &nbsp;
                        Share
                      </button> */}
                    </Col>
                    <Col xs={3} sm={3}>
                      <div className="ecom_detail_list_changes">
                        <span> <img className="image_round70" src={country9} alt="" /> </span>
                      </div>
                      <label className="image_round68">{t("2 lack")}+ {t("Happy Farmers")} </label>
                      <br/>
                      {/* <button className="addcart-btn">
                        <img src={ic_cart} alt="" />
                        &nbsp;
                        Share
                      </button> */}
                    </Col>
                  </Row>
                </Container>

                </div>
          </div>

          <div className=" detail-card top-heading-heart  mt-4">
            <div className="top-head">
              <div className=" bdr-btm  ">
                <p className="over-text product">{t("Overview")}</p>
              </div>
            </div>
            <Container>
              <Row>
                <Col xs={12} sm={3}>
                  <CAccordion activeItemKey={1}>
                    <CAccordionItem itemKey={1}>
                      <CAccordionHeader>
                        <div className="heading-p-i ">
                          <label htmlFor="" className=" product ">
                            {t("Product Specification")}
                          </label>

                        </div>
                      </CAccordionHeader>
                      {product_detail_items_pecifications &&
                        product_detail_items_pecifications.map((specification_list) => {
                          return (
                            console.log(specification_list),
                            (
                              <CAccordionBody>
                                <img src={bullet} alt="" />
                                <span className="inform-color ms-1">
                                  {specification_list && specification_list.spec_title}
                                </span>
                                <label htmlFor="" className="product">
                                  {specification_list && specification_list.spec_desc}
                                </label>
                              </CAccordionBody>

                            )
                          );
                        })}


                    </CAccordionItem>
                  </CAccordion>
                </Col>
                <Col xs={12} sm={3}>
                  <CAccordion activeItemKey={2}>
                    <CAccordionItem itemKey={2}>
                      <CAccordionHeader>
                        <div className="heading-p-i ">
                          <label htmlFor="" className=" product ">
                            {/* <p>{product_detail && product_detail.product_detail && product_detail.product_detail.item_specification[0].spec_title}</p> */}
                            {t("Product Information")}
                          </label>
                        </div>
                      </CAccordionHeader>

                      <CAccordionBody>
                        <label className="product">
                          {product_detail && product_detail.product_detail && product_detail.product_detail.item_specification[0].spec_desc}
                        </label>
                        {/* <img src={bullet} alt="" />
                            <span className="inform-color ms-1">
                            {item_list && item_list.item_name}
                            </span>
                            <label htmlFor="" className="over-text  ">
                            {item_list && item_list.item_ingredient}
                            </label> */}
                      </CAccordionBody>

                    </CAccordionItem>
                  </CAccordion>
                </Col>
                <Col xs={12} sm={3}>
                  <CAccordion activeItemKey={3}>
                    <CAccordionItem itemKey={3}>
                      <CAccordionHeader>
                        <div className="heading-p-i ">
                          <label htmlFor="" className=" product ">
                            {t("How To Used This Product")}
                          </label>

                        </div>
                      </CAccordionHeader>
                      {product_detail_usemethod &&
                        product_detail_usemethod.map((product_detail_usemethod_list) => {
                          return (
                            console.log(product_detail_usemethod_list),
                            (
                              <CAccordionBody>
                                <img src={bullet} alt="" />
                                <span className="inform-color ms-1">
                                  {product_detail_usemethod_list && product_detail_usemethod_list.usemethod_title}
                                </span>
                                <label htmlFor="" className="product  ">
                                  {product_detail_usemethod_list && product_detail_usemethod_list.usemethod_desc}
                                </label>
                              </CAccordionBody>
                            )
                          );
                        })}
                    </CAccordionItem>
                  </CAccordion>
                </Col>

                <Col xs={12} sm={3}>
                  <CAccordion activeItemKey={4}>
                    <CAccordionItem itemKey={4}>
                      <CAccordionHeader>
                        <div className="heading-p-i ">
                          <label htmlFor="" className=" product ">
                            {t("Disclaimer")}
                          </label>

                        </div>
                      </CAccordionHeader>

                      <CAccordionBody>
                        <label htmlFor="" className=" product ">
                          {t("Actual product packaging and materials may contain more and different information than what is shown on our application. We recommend that you do not rely solely on the information presented and that you always read labels, warnings, and directions before using or consuming a product. Product images shown are for illustration purposes only. Actual Product may vary depending on stock available with the vendor")}.
                        </label>
                      </CAccordionBody>

                    </CAccordionItem>
                  </CAccordion>
                </Col>

              </Row>
            </Container>

          </div>
          <br />



          <div className="top-heading">
            <label htmlFor="" className="heading-black">
            {t("Popular Item")}
            </label>{" "}
            {/* <label htmlFor="" className="heading-red">
              Trending Product
            </label> */}

            <p className="paragraph">
              {t("Boost your farm s productivity with our best-selling agriculture product - trusted and loved by farmers everywhere!")}
            </p>
          </div>

          <div className="latest-offer">
            <Carousel className="pad5"

              responsive={responsive}>
              {todays_offers &&
                todays_offers.map((todays_offers_get) => {
                  const results = todays_offers_get.grand_total_without_discount - todays_offers_get.grand_total;
                  const saved = results / todays_offers_get.grand_total_without_discount;
                  const dp = saved * 100;
                  const result1 = dp.toString().slice(0, 5);
                  return (
                    console.log("todays_offers_get******", todays_offers_get),
                    (
                      <div>
                        <div className="" style={{ width: '15rem' }} onClick={goToTop}>
                          <Link onClick={() => getAddressId(todays_offers_get.id)}>

                            <Card className="shadowcard mrc" >
                            <div className="mrc2">
                              <button type="button" class="btn btn-warning mrc3">{result1} % OFF</button>
                            </div>
                              
                              <HoverImage className="image_height_set" src={image_url1 + todays_offers_get.product_image} hoverSrc={image_url1 + todays_offers_get.product_image} />

                              <Card.Body>
                                <Card.Title>
                                  <div className="term"  >
                                    {todays_offers_get && todays_offers_get.product_name}
                                  </div>
                                </Card.Title>

                                <Card.Title>
                                  <div className="text_set">
                                      <div className="rating-container">
                                        <StarRating rating={todays_offers_get.average_rating} />
                                        &nbsp;
                                        <b className="rating-container1"  class="btn btn-warning mrc3">
                                          {todays_offers_get.average_rating}
                                        </b>  
                                      </div>
                                  </div>
                                </Card.Title>

                                
                                <Card.Text>
                                  <div>
                                    <label>{t("Available in")} :</label> &nbsp;
                                    <label> {todays_offers_get.base_packet_quantity}</label>
                                    <label> {todays_offers_get.base_packet_unit}</label>
                                  </div>
                                </Card.Text>
                                <Card.Text>
                                  <div className="text_set">
                                    {todays_offers_get.category.category_name}
                                  </div>
                                </Card.Text>
                                <Card.Text>
                                  <s>{todays_offers_get.grand_total_without_discount} Rs</s>&nbsp;&nbsp; {todays_offers_get.grand_total} Rs
                                </Card.Text>

                                <Card.Text>
                                  {todays_offers_get.is_bks_assured === 1 ? (
                                    <img className="set-images" src={bksassurred} alt=""/>
                                    ) : null}
                                </Card.Text>

                                <Card.Text>
                                <div className="text_set1">
                                  {results} Rs {t("Saved")}
                                </div>
                              </Card.Text>
                              </Card.Body>

                            </Card>
                          </Link>
                        </div>
                      </div>
                    )
                  );
                })}
            </Carousel>

          </div>
          <div className="btn-shop-home">
            <Link to="/TrandingProduct">
              <button className="btn-gray-common">
                {t("View All")} <img src={arrow_white} alt="" className="ms-2" />
              </button>
            </Link>
          </div>



          <div className="top-heading">
            <label htmlFor="" className="heading-black">
            {t("Find the newest discounts")}
            </label>{" "}
            {/* <label htmlFor="" className="heading-red">
              Latest Offers
            </label> */}

            <p className="paragraph">
            {t("Uncover the latest deals and save big on your farming needs - shop our newest discounts and take your farm to the next level!")}
            </p>
          </div>

          <div className="latest-offer">
            <Carousel className="pad5"

              responsive={responsive}>

              {latest_offers &&
                latest_offers.map((latest_offers_get) => {
                  const results = latest_offers_get.grand_total_without_discount - latest_offers_get.grand_total;
                  const saved = results / latest_offers_get.grand_total_without_discount;
                  const dp = saved * 100;
                  const result1 = dp.toString().slice(0, 5);
                  return (
                    console.log("latest_offers_get", image_url1 + latest_offers_get.product_image),
                    (
                      <div>
                        <div className="" style={{ width: '15rem' }} onClick={goToTop}>
                          <Link onClick={() => getAddressId(latest_offers_get.id)}>
                            <Card className="shadowcard mrc" >
                            <div className="mrc2">
                              <button type="button" class="btn btn-warning mrc3">{result1} % OFF</button>
                            </div>
                              

                              <HoverImage className="image_height_set" src={image_url1 + latest_offers_get.product_image} hoverSrc={image_url1 + latest_offers_get.product_image} />
                              <Card.Body>

                                <Card.Title>
                                  <div className="term">
                                      {latest_offers_get.product_name}
                                  </div>
                                </Card.Title>

                                <Card.Title>
                                  <div className="text_set">
                                      <div className="rating-container">
                                        <StarRating rating={latest_offers_get.average_rating} />
                                        &nbsp;
                                        <b className="rating-container1"  class="btn btn-warning mrc3">
                                          {latest_offers_get.average_rating}
                                        </b>  
                                      </div>
                                  </div>
                                </Card.Title>

                                <Card.Text>
                                  <div>
                                    <label>{t("Available in")} : </label>&nbsp;
                                    <label>{latest_offers_get.base_packet_quantity}</label>
                                    <label>{latest_offers_get.base_packet_unit}</label>
                                  </div>
                                </Card.Text>
                                <Card.Text>
                                  <div className="text_set">
                                    {latest_offers_get.category.category_name}
                                  </div>
                                </Card.Text>
                                <Card.Text>
                                  <s>{latest_offers_get.grand_total_without_discount} Rs</s>&nbsp;&nbsp;{latest_offers_get.grand_total} Rs
                                </Card.Text>
                                <Card.Text>
                                  {latest_offers_get.is_bks_assured === 1 ? (
                                    <img className="set-images" src={bksassurred} alt=""/>
                                    ) : null}
                                </Card.Text>
                                <Card.Text>
                                <div className="text_set1">
                                  {results} Rs {t("Saved")}
                                </div>
                              </Card.Text>
                              </Card.Body>
                            </Card>
                          </Link>

                        </div>
                      </div>
                    )
                  );
                })}

            </Carousel>

          </div>
          <div className="btn-shop-home">
            <Link to="/products">
              <button className="btn-gray-common">
                {t("View All")} <img src={arrow_white} alt="" className="ms-2" />
              </button>
            </Link>
          </div>


          {/* recent view product start */}

              {recentViewProduct && recentViewProduct.length>0 && 
               <div>
               <div className="top-heading">
                 <label htmlFor="" className="heading-black">
                 {t("Your recently viewed products")}
                 </label>{" "}
                 {/* <label htmlFor="" className="heading-red">
                   Trending Product
                 </label> */}
   
                 <p className="paragraph">
                   {t("Boost your farm s productivity with our best-selling agriculture product - trusted and loved by farmers everywhere!")}
                 </p>
               </div>
   
               <div className="latest-offer">
                 
                 <Carousel className="pad5"
                 
   
                   responsive={responsive}>
                   {recentViewProduct &&
                     recentViewProduct.map((recentViewProduct_get) => {
                       const results = recentViewProduct_get.grand_total_without_discount - recentViewProduct_get.grand_total;
                       const saved = results / recentViewProduct_get.grand_total_without_discount;
                       const dp = saved * 100;
                       const result1 = dp.toString().slice(0, 5);
                       return (
                         console.log("recentViewProduct_get", recentViewProduct_get),
                         (
                           <div>
                             <div className="" style={{ width: '15rem' }} onClick={goToTop}>
                               <Link onClick={() => getAddressId(recentViewProduct_get.id)}>
   
                                 <Card className="shadowcard mrc" >
                                 <div className="mrc2">
                                   <button type="button" class="btn btn-warning mrc3">{result1} % OFF</button>
                                 </div>
                                   
                                   <HoverImage className="image_height_set" src={image_url1 + recentViewProduct_get.product_image} hoverSrc={image_url1 + recentViewProduct_get.product_image} />
   
                                   <Card.Body>
                                     <Card.Title>
                                       <div className="term"  >
                                         {recentViewProduct_get && recentViewProduct_get.product_name}
                                       </div>
                                     </Card.Title>
                                     <Card.Title>
                                      <div className="text_set">
                                          <div className="rating-container">
                                            <StarRating rating={recentViewProduct_get.average_rating} />
                                            &nbsp;
                                            <b className="rating-container1"  class="btn btn-warning mrc3">
                                              {recentViewProduct_get.average_rating}
                                            </b>  
                                          </div>
                                      </div>
                                    </Card.Title>
                                     <Card.Text>
                                       <div>
                                         <label>{t("Available in")} :</label> &nbsp;
                                         <label> {recentViewProduct_get.base_packet_quantity}</label>
                                         <label> {recentViewProduct_get.base_packet_unit}</label>
                                       </div>
                                     </Card.Text>
                                     <Card.Text>
                                       <div className="text_set">
                                         {recentViewProduct_get.category.category_name}
                                       </div>
                                     </Card.Text>
                                     <Card.Text>
                                       <s>{recentViewProduct_get.grand_total_without_discount} Rs</s>&nbsp;&nbsp; {recentViewProduct_get.grand_total} Rs
                                     </Card.Text>
                                     <Card.Text>
                                      {recentViewProduct_get.is_bks_assured === 1 ? (
                                        <img className="set-images" src={bksassurred} alt=""/>
                                        ) : null}
                                    </Card.Text>
                                     <Card.Text>
                                     <div className="text_set1">
                                       {results} Rs {t("Saved")}
                                     </div>
                                   </Card.Text>
                                   </Card.Body>
   
                                 </Card>
                               </Link>
                             </div>
                           </div>
                         )
                       );
                     })}
                 </Carousel>
   
               </div>
               <div className="btn-shop-home">
                 <Link to="/RecentViewProduct">
                   <button className="btn-gray-common">
                     {t("View All")} <img src={arrow_white} alt="" className="ms-2" />
                   </button>
                 </Link>
               </div>
             </div>

              }  
         

          {/* recent view product end */}
        </div>


        


        <Footer />
      </main>
    </>
  );
}

export default Detailslist;
