import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../SharedComponents/Navbar";
import min from "../../assets/images/ic_minus.png";
import plus from "../../assets/images/ic_plus.png";
import delete1 from "../../assets/images/ic_delete.png";
import Footer from "../../SharedComponents/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import offer2 from "../../assets/img/offer2.jpg";
import offer1 from "../../assets/img/offer1.jpg";
import offer3 from "../../assets/img/offer3.jpg";
import ReactStars from "react-rating-stars-component";
import arrow_white from "../../assets/images/arrow_highlight.svg";
import ic_cart from "../../assets/images/ic_cart.svg";
import ic_cart1 from "../../assets/new_images/icon 25-25-01 (1).jpg";
import ic_cart2 from "../../assets/new_images/icon 25-25-02 (2).jpg";
import ic_cart3 from "../../assets/new_images/icon 25-25-04 (1).jpg";
import { Container, Row, Col } from 'react-grid-system';
import Card from 'react-bootstrap/Card';
import { AppContext } from "../../context/AppContext ";
import { multipartPostCallWithFormDataResponse, postWithAuthCallWithErrorResponse, postWithAuthCallWithFormDataResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../SharedComponents/notify";

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { ScaleLoader } from "react-spinners";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
function Cart() {
  const image_url="http://bharatkrushiseva.in"
  const {customerData} = useContext(AppContext);
  console.log("customerData",customerData)
  const [current, setCurrent] = useState({});
  const [loading, setLoading] = useState(false);
  // const previousPageData = useLocation().state;
  // console.log(previousPageData&&previousPageData.data&&previousPageData.data.cart_data&&previousPageData.data.cart_data.final_total_cart_price)
  const [cart_product, setCartProduct] = useState([]);
  console.log("cart_product",cart_product.status)

  
  const lang = localStorage.getItem("lang")
  console.log("lang",lang)

  const [state, setState] = useState({
    token:customerData.token,
    language:lang,
  });
  useEffect(() => {
    get_Cart_Data();
  }, []);
  const get_Cart_Data= () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_PRODUCT_LIST_IN_CART,
      JSON.stringify({ ...state, })
    )
    .then((res) => {
      setLoading(false)
      console.log("CART", res.json);
      setCartProduct(res.json);
      sessionStorage.setItem("total_amount_with_discount",res.json.grand_total_cart_price)
      sessionStorage.setItem("total_amount_without_discount",res.json.grand_total_cart_price_without_discount)
      res.json.status==="true" 
      ? 
      notifySuccess(res.json.message) 
      :
       notifyError(res.json.message)
     
    })
    .catch((err) => console.log(err));
  };


  const getAddressId = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.DELETE_ONE_PRODUCT_FROM_CART,
      JSON.stringify({ 
        cart_id:id,
        token:customerData.token,
        language:lang
       })
    ).then((res) => {
      console.log("address_id", res.json);
     
      if  (res.json.status==="true")
        {
          get_Cart_Data()
          notifySuccess(res.json.message
            )
        }
        else{
          notifyError(res.json.message
            )
        }
    })
    .catch((err) => console.log(err));

  };

  const getProductDe = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.DELETE_All_PRODUCT_FROM_CART,
      JSON.stringify({ 
        token:customerData.token,
        language:lang
       })
    ).then((res) => {
      console.log("address_id", res.json);
     
      if  (res.json.status==="true")
        {
          get_Cart_Data()
          notifySuccess(res.json.message
            )
        }
        else{
          notifyError(res.json.message
            )
        }
    })
    .catch((err) => console.log(err));

  };

  const [addressdata, setaddressData] = useState([]);
  console.log("addressdata",addressdata)
    const [state_address, setStateAddress] = useState({
      user_id: customerData.id,
      language: lang,
    });
    useEffect(() => {
      addressList();
      cart_info_address();
    }, []);
    const addressList = () => {
      postWithAuthCallWithErrorResponse(
        ApiConfig.GET_SELLING_ADDRESS,
        JSON.stringify({ ...state_address, })
      )
        .then((res) => {
          console.log("addressList", res.json);
          setaddressData(res.json);
          sessionStorage.setItem("addres_id",res.json.address.id)
         
        })
        .catch((err) => console.log(err));
    };

    


  const [cart_delivery_info, setCartDeliveryAddress] = useState([]);
  console.log("cart_delivery_info",cart_delivery_info.distance)
  const [delivery_info_state, setDeliveryInfoState] = useState({
    language:lang,
    token:customerData.token,
    // address_id:7414
    address_id: addressdata && addressdata.address && addressdata.address.id
  });
  console.log("delivery_info_state",delivery_info_state)
  const cart_info_address = () => {
    
    let formdata=new FormData();
    formdata.append("address_id",delivery_info_state.address_id);
    formdata.append("language",delivery_info_state.language);
    formdata.append("token",delivery_info_state.token);
 
 
    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_CART_DELIVERY_ADDRESS,
      formdata
      // JSON.stringify(formdata)
    )
      .then((res) => {
        console.log("cart_delivery", res.json);
        setCartDeliveryAddress(res.json);
       
      })
      .catch((err) => console.log(err));
  };
  

  const getAddcount = (id,id1) => {
    console.log("id",id)
    console.log("id",id1)
     postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_PRODUCT_TO_CART,
      JSON.stringify({ 
        product_id:id,
        packet_id:id1,
        token:customerData.token,
        product_count:cart_product&&cart_product.cart_list[0]&&cart_product.cart_list[0].no_of_product+1,
        language:lang
       })
    )
    .then((res) => {
      console.log("addtocart", res.json);
     
      if  (res.json.status==="true")
        {
          get_Cart_Data()
          notifySuccess(res.json.message)
        }
        else{
          notifyError(res.json.message)
        }
    })
    .catch((err) => console.log(err));
  };

  const getAddcountminus = (id,id1,no_of_product) => {
    console.log("id",no_of_product)
     postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_PRODUCT_TO_CART,
      JSON.stringify({ 
        product_id:id,
        packet_id:id1,
        token:customerData.token,
        product_count:no_of_product-1,
        language:lang
       })
    )
    .then((res) => {
      console.log("addtocart", res.json);
     
      if  (res.json.status==="true")
        {
          get_Cart_Data()
          notifySuccess(res.json.message)
        }
        else{
          notifyError(res.json.message)
        }
    })
    .catch((err) => console.log(err));
  };

  let final_total_cart_price = sessionStorage.getItem("final_total_cart_price")
  let offer_deducted_amount = sessionStorage.getItem("offer_deducted_amount")
  let cupon_status = sessionStorage.getItem("cupon_status")
  let coupon_code_status = sessionStorage.getItem("coupon_code_status")
  console.log("coupon_code_status",(coupon_code_status))
  

  const [data_ref, setDataRef] = useState({});
  console.log("data_ref",data_ref.final_total_cart_price)
  const [errMsg, setErrMsg] = useState({ ref_code: "" });
  const [ref_codestate_cart, setRefCodeStateCart] = useState({
    token:customerData.token,
    language:lang,
    coupon_code:"",
    offer_type_id:"4",
    offer_type_key:"seasonal"
  });
  const handleSubmit = (e) => {
    console.log("hello")
    e.preventDefault();
    if (ref_codestate_cart.coupon_code === "") {
      setErrMsg({ ...errMsg, coupon_code: t("Enter cupon Code")});
      return;
    }
    else {
      postWithAuthCallWithErrorResponse(
        ApiConfig.GET_CART_DATA_WITH_APPLY_OFFER,
        JSON.stringify({ ...ref_codestate_cart, })
      )
      .then((res) => {
        console.log("res", res.json.cart_data.final_total_cart_price);
        setDataRef(res.json.cart_data);
        if  (res.json.offer_status==="true")
      {
      
      sessionStorage.setItem("cupon_status",true)
      sessionStorage.setItem("final_total_cart_price", res.json.cart_data.final_total_cart_price)
      sessionStorage.setItem("offer_deducted_amount", res.json.cart_data.offer_deducted_amount)
      sessionStorage.setItem("offer_type_id", res.json.offer_type.id)
        notifySuccess(res.json.offer_message)
      }
      else{
        notifyError(res.json.offer_message)
      }
      })
      .catch((err) => console.log(err));
    }
  };


  const navigate = useNavigate();
  const getBuyNow = () => {
    if (cart_product.status==="false")
    {
      notifyError(cart_product.message)
      navigate("/cart")
    }
    else
    {
      navigate("/payment/")
    }
  }


  // const getAddToCart = (id) => {
  //   console.log("id",id)
    // postWithAuthCallWithErrorResponse(
    //   ApiConfig.ADD_PRODUCT_TO_CART,
    //   JSON.stringify({ 
    //     product_id:id,
    //     packet_id:product_detail_item_packets[0].item_packets[0].id,
    //     token:customerData.token,
    //     product_count:1,
    //     language:"en"
    //    })
    // )
    // .then((res) => {
    //   console.log("addtocart", res.json);
     
    //   if  (res.json.status==="true")
    //     {
    //       notifySuccess(res.json.message)
    //     }
    //     else{
    //       notifyError(res.json.message)
    //     }
    // })
    // .catch((err) => console.log(err));
  // };

  
  // useEffect(() => {
  //   if (current.id) {
  //     let dataWithQ = cart_product.filter((item) => item.id === current.id)[0];
  //     setCurrent(dataWithQ);
  //   }
  // }, [cart_product]);
     

const { t } = useTranslation();

useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])
  return (
    <>
      <Navbar />
      <main>


        <div className="cart-bg-main">
          <Container>
            <Row>
              <Col sm={6}>
                <Card className="">
                  <Card.Body>
                    <Card.Title>
                      <h6>{t("Deliver Product To")} 
                      &nbsp;
                      <Link to={"/AddAddress"}>
                      <button type="button" class="btn btn-outline-success">{t("Add Address")}</button>
                    </Link>
                    </h6>

                      <h6>{addressdata && addressdata.address && addressdata.address.user && addressdata.address.user.user_name}</h6>

                    </Card.Title>
                    <Card.Text>
                     
                    <h6 className="text-danger">{addressdata.status === 'false' ? "Please add  your complete address first you Can't Order" : ""} </h6>,&nbsp;
                    {addressdata &&addressdata.address && addressdata.address.house_name },&nbsp;
                      {addressdata &&addressdata.address && addressdata.address.area_name},&nbsp;
                      {addressdata &&addressdata.address && addressdata.address.landmark},&nbsp;
                      {addressdata &&addressdata.address && addressdata.address.pincode},&nbsp;
                      {addressdata &&addressdata.address && addressdata.address.state},&nbsp;
                      {addressdata &&addressdata.address && addressdata.address.district},&nbsp;
                      {addressdata &&addressdata.address && addressdata.address.taluka},&nbsp;
                      {addressdata &&addressdata.address && addressdata.address.village}
                    </Card.Text>
                    <Card.Title>
                      <h6>{addressdata && addressdata.address && addressdata.address.user &&addressdata.address.user.user_mobile}</h6>

                    </Card.Title>
                    <Card.Text>
                    <Link to={"/EditAddress1/"} state={addressdata &&addressdata.address && addressdata.address.id}>
                      <button type="button" class="btn btn-outline-success">{t("Change Address")}</button>
                    </Link>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6}>
                <Card className="">
                  <Card.Body>
                    <Card.Title>
                      <h6>{t("Apply Referral Code")}</h6>
                    </Card.Title>
                    <Card.Text>
                     {t("if you have referral code to grap special discount on this order. please enter your referral code below")}
                    </Card.Text>
                    <br />

                    <Card.Text>
                      <Link to={"/RefCode"}>
                      <p className="cc">
                      {t("click here to view referral code you got from your friends")}
                        </p>
                        </Link>
                    </Card.Text>
                    <Card.Text>

                     <input 
                     className="" 
                     type="text"  
                     placeholder={t("refrel code")}
                     onChange={(e) => {
                      setErrMsg({ ...errMsg, coupon_code: "" });
                      setRefCodeStateCart({ ...ref_codestate_cart, coupon_code: e.target.value });
                    }}
                    
                      />
                      {errMsg.coupon_code && errMsg.coupon_code.length > 0 && (
                      <span className="text-danger">{errMsg.coupon_code}</span>
                      )}
                      &nbsp;&nbsp;
                      <Link to="#" onClick={handleSubmit}>
                      <button type="button" class="btn btn-danger"  >{t("Apply Code")}</button>
                      </Link>
                      <Link to="/MyOffers" >
                      <p className="cc">
                        {t("view more offers and coupons")}
                        <img src={arrow_white} alt="" className="ms-2" />
                      </p>
                      </Link>

                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
              {/* <Col sm={4}>
                <h5>Apply Referral Code</h5>
                <p className="out-heading_color_chnage">Lorem ipsum, dolor sit amet consectetur adipisicing elit..dolor sit amet consectetur adipisicing elit</p>
                <p className="out-heading_color_chnage">Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                <button type="button" class="btn btn-outline-success">Change Address</button>
                <button type="button" class="btn btn-outline-success">Change Address</button>
              </Col> */}

            </Row>
          </Container>

          <hr />

          <div className="cart-main-wrapper">
            <div className="outer-heading">
              <div className="left">
                <p className="out-heading">{t("Product Details")}</p>
                <p className="out-heading qty">{t("")}</p>
              </div>
              <div></div>
            </div>
            {loading ?
                   (
      <ScaleLoader

        color={"#E27A7A"}
        loading={loading}
        // size={10}
        className="loading setloader"
      />
    ) : (
            <div>
            {  cart_product.cart_list &&
            cart_product.cart_list.length > 0 ?   cart_product.cart_list &&
            cart_product.cart_list.map((cart_product_get) => {
              return (
                console.log("cart_product",cart_product_get.no_of_product),
                (
            <div className="cart-box">
              <div className="prdct-qty">
                <div className="left-content ">
                  <div className="image">
                    <img src={image_url+cart_product_get.product_detail.product_image} alt="" />
                  </div>
                  <div className="content">
                    <p className="first-purchase ">
                      {cart_product_get.product_detail.product_name}
                    </p>
                    <span className="current-date ">
                      <s>{cart_product_get.product_detail.grand_total_without_discount} Rs</s>&nbsp;&nbsp;&nbsp;{cart_product_get.product_detail.grand_total} Rs
                    </span>
                    <div>

                      <span className="current-date ">
                        {t("Quantity")}:{cart_product_get.product_detail.item_list[0].item_packets[0].packet_quantity}
                        {cart_product_get.product_detail.item_list[0].item_packets[0].packingunit_id_fk__unit_name} 
                      </span>
                    </div>
                 
                  </div>

                </div>

                <div className="card-add-btn-main">
                <Link to="#" onClick={() => getAddcountminus(
                   cart_product_get && cart_product_get.product_detail.id,
                   cart_product_get && cart_product_get.product_detail && cart_product_get.product_detail.item_list[0] && cart_product_get.product_detail.item_list[0].item_packets[0] && cart_product_get.product_detail.item_list[0].item_packets[0].id,
                   cart_product_get.no_of_product
                  
                  )}>
                  <button className="minus-btn">
                    <img src={min} alt="" />
                  </button>
                  </Link>
                  <input
                    type="text"
                    className="qty-input"
                    size="2"
                    maxLength="2"
                    value={cart_product_get.no_of_product}
                  />
                  {/* cart_product_get.product_detail.item_list[0].item_packets[0].id) */}
                 <Link to="#" onClick={() => getAddcount(
                  
                  cart_product_get && cart_product_get.product_detail.id,
                  cart_product_get && cart_product_get.product_detail && cart_product_get.product_detail.item_list[0] && cart_product_get.product_detail.item_list[0].item_packets[0] && cart_product_get.product_detail.item_list[0].item_packets[0].id
                  
                  )}>
                  <button className="plus-btn">
                    <img src={plus} alt="" />
                  </button>
                  </Link>
                 
                 
                </div>
              </div>
              <div className="delete-btn-main ">
              <Link to="#" onClick={() => getAddressId(cart_product_get.id)}>
                <button className="table-btn">
                  <img src={delete1} alt="" />
                </button>
                </Link>
              </div>
            </div>

                ) 
              );
            }) : 
            <div className="textset"> {t("Cart Is Empty")} </div>
            }
            </div>
    )}
                

          </div>
          <Container>
            <Row>
              <Col  xs={12} sm={12}>
                <Card className="">
                  <Card.Body>
                    <Card.Title>
                      <h6>{t("Price Details")}</h6>
                    </Card.Title>
                    <div>
                    {/* <Table striped bordered hover className="table_c_c "> */}
                    <Table className="fontset1">
   


      
    </Table>

    <Container className="set_view">
<Row className="fontset1">
    <Col xs={6} sm={6}>
    <p className="sads">{t("Total MRP")}</p>
    </Col>
    <Col xs={6} sm={6}>
    <h6 className="sads">{cart_product.grand_total_cart_price}Rs</h6>
    </Col>
    </Row>

    <Row className="fontset1">
    <Col xs={6} sm={6}>
    <p className="sads">{t("Discount On MRP")}</p>
    </Col>
    <Col xs={6} sm={6}>
    <h6 className="sads">
      00 Rs
    </h6>
    </Col>
    </Row>

    <Row className="fontset1">
    <Col xs={6} sm={6}>
    <p className="sads">{t("Coupon Discount")}</p>
    </Col>
    <Col xs={6} sm={6}>
    <h6 className="sads">
    {
    cupon_status==="true" ? offer_deducted_amount :  t("apply coupons")

  }
      
    </h6>
    </Col>
    </Row>

    

    <Row className="fontset1">
    <Col xs={6} sm={6}>
    <p className="sads">{t("Total Amount")}</p>
    </Col>
    <Col xs={6} sm={6}>
    <h6 className="sads">
      {/* {
        coupon_code_status==="false" || cupon_status==="false" 
        ? cart_product.grand_total_cart_price  : final_total_cart_price
        
      }   */}
    
    
  {
    cupon_status==="true" ? final_total_cart_price  :  cart_product.grand_total_cart_price
  }
      </h6>

    </Col>
    </Row>

</Container>
    </div>
                   
                    <hr/>
                    <Card.Text>
                      <Row>
                        <Col xs={4} sm={4}>
                       
                          <h6 className="sads1"> <img src={ic_cart1} alt="" className="ms-2 img_round24" />{t("Original Product")}</h6>
                        </Col>
                        <Col xs={4} sm={4}>
                          <h6 className="sads1"> <img src={ic_cart2} alt="" className="ms-2 img_round24" />{t("Fast Delivery")}</h6>
                        </Col>
                        <Col xs={4} sm={4}>
                          <h6 className="sads1"> <img src={ic_cart3} alt="" className="ms-2 img_round24" />{t("Secure Payments")}</h6>
                        </Col>

                      </Row>
                      <br/>
                      <Row>
                      <Col sm={12}>
                         
                          <a href="https://bharatkrushiseva.com/terms_condition.html" target="_blank">
                          <label className="ccf">
                            {t("By clicking continue you agree to our Terms of Use and acknowledge that you have read our Privacy Policy")}

                          </label>
                          </a>
                            
                        </Col>

                      </Row>
                    </Card.Text>
                    


                  </Card.Body>
                </Card>
              </Col>
            </Row>
           
          </Container>
          <div className="count-cart-bottom-main shadowcard">
            <div className="inner">
              <div className="left">
                <label className="me-2">{t("Number of items in the cart")}</label>
                <label>{cart_product && cart_product.cart_list && cart_product.cart_list.length}</label>
              </div>
              <div className="inner">
                <div className="">
                  <label className="me-2">
                  <Link to="#" onClick={() => getProductDe()} className="btn-quote">
                    <b className="cc-bn ">
                    <button type="button" class="btn btn-danger">
                      {t("Delete All Product")}
                      </button>
                      </b>
                  </Link>
                  </label>
              
                </div>
                
              
                {/* <div className="right"> */}
                {/* <Link to="#" onClick={() => getProductDe()} className="btn-quote">
                    <b className="cc-bn">Delete All Product</b>
                  </Link> */}

                  
                  <div className="right">
                    
          
                    {
                    addressdata.status === 'true' 
                    ?
                    <button type="button" onClick={() => getBuyNow()} class="btn btn-danger">{t("Buy Now")}</button>
                    :
                    <button disabled type="button" class="btn btn-danger">{t("Buy Now")}</button>
                    
                    }

                

                    
                
                </div>
              </div>

            </div>
          </div>
        </div>

        <Footer />
      </main>
    </>
  );
}

export default Cart;
