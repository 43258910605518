import React, { useContext, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse, simplePostCall } from "../../api/ApiServices";
import logo from "../../assets/img/BKS Logo- 279 - 61-01 (6).png";
import logo_new from "../../assets/slide/BKS Logo - webp-01 (1).png";

import { AppContext } from "../../context/AppContext ";
import { notifyError, notifySuccess } from "../../SharedComponents/notify";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";


const PasswordOTP = () => {
  const navigate = useNavigate();
  const { setCustomerData, setLoggedIn} = useContext(AppContext);
  console.log(useContext(AppContext))
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData.token)
  const token = localStorage.getItem("token")
  console.log("token",token);
  const [loading, setLoading] = useState(false);

  const [pincode, setPincode] = useState({
    ecom_pincode: "",
    // token:customerData.token
    // token:token
  });
  const [errMsgPinCode, setErrMsgPinCode] = useState({ ecom_pincode: "" });


  // ==================otp send api login========================
  const handlePinCode = (e) => {
    setLoading(true);
    e.preventDefault();
    if (pincode.ecom_pincode === "") {
      setErrMsgPinCode({ ...errMsgPinCode, ecom_pincode: "Enter your Pin Code number"});
      return;
    }
    else {
      simplePostCall(
        ApiConfig.UPDATE_USER_ECOM_DATA,
        JSON.stringify({ ...pincode, token:token })
      )
      .then((res) => {
        setLoading(false);
        console.log("res", res.json);
        // setData(res.json.data);
      if  (res.status==="true")
      {
        notifySuccess(res.message)
        navigate("/home");
        refresh();
      }
    else{
      notifyError(res.message)
    }

   
      })
      .catch((err) => console.log(err));
    }
    
    
  };
  
  
// ==================otp send api login========================
  const previousPageData = useLocation().state;

  const [state, setState] = useState({
    ...previousPageData,
    otpDigit1: "",
    otpDigit2: "",
    otpDigit3: "",
    otpDigit4: "",
  });
  console.log("state",state);
  const [data, setData] = useState({});
  const handleSubmit = (e) => {
    var otp =
    state.otpDigit1 +
    state.otpDigit2 +
    state.otpDigit3 +
    state.otpDigit4;
  
  simplePostCall(
    ApiConfig.VALIDATEOTPFORMOBILE,
    JSON.stringify({ ...previousPageData, otp, language:state.language, mobile:state.mobile })
  )
  .then((res) => {
    console.log("res", res.status);
    

    if (res.status==="true")
    {
      localStorage.setItem("token", res.token);
      localStorage.setItem("user_name",res.profile.user_name);
      localStorage.setItem("user_mobile",res.profile.user_mobile);
      localStorage.setItem("business_email",res.profile.business_email);
      localStorage.setItem("id",res.profile.id);
      localStorage.setItem("user_image",res.profile.user_image);

      localStorage.setItem("logedin",true);
      localStorage.setItem('lang',"en");
      localStorage.setItem("customerData", JSON.stringify(
        res.profile
      )); 
      setLoggedIn(true)
      
      
    }
    else
    {
      notifyError(res.message)
      navigate("/PasswordOTP");
    }
   
    

    if (res.profile.ecom_pincode==="0")
    {
      toggleShow()
      // setShow=true
    }
    else
    {
      if (res.status === "true") {
        notifySuccess(res.message);
    
        if (previousPageData && previousPageData.product_id) {
          navigate(`/Detailslist/${previousPageData.product_id}`);
          refresh();
        } else {
          navigate("/home");
          refresh();
        }
       
      
    }
  

  }
 
  }
  )
  
  .catch((err) => console.log(err));
// }
};



const refresh = () => window.location.reload(true)
  
// ==================otp send api login========================

  const handleEnter = (event) => {
    var validkeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    if (validkeys.includes(event.key)) {
      event.target.value = event.key;
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1] && form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const [topRightModal, setTopRightModal] = useState(false);

  const toggleShow = () => setTopRightModal(!topRightModal);
  const { t } = useTranslation();
  return (
    <>
      <main className="main-login ">
        <div className="row ">
          {/* left section */}
            <MDBModal
            animationDirection='right'
            show={topRightModal}
            tabIndex='-1'
            setShow={setTopRightModal}
          >

          <MDBModalDialog position='top-right' side>
            <MDBModalContent>
              <MDBModalHeader className='bg-success text-white'>
                <MDBModalTitle>Please fill the field</MDBModalTitle>
                <MDBBtn
                  color='none'
                  className='btn-close btn-close-white'
                  onClick={toggleShow}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className='row'>

                  <div className='col-12'>
                    <Container>
                      <Row>
                        <Col sm={3}>
                        Pin Code
                        </Col>
                        <Col sm={6}>
                          <label>
                            <input type="text"
                             className="form-control"
                             maxLength={10}
                             onChange={(e) => {
                              setErrMsgPinCode({ ...errMsgPinCode, ecom_pincode: "" });
                               setPincode({ ...pincode, ecom_pincode: e.target.value });
                             }}
                            />
                            
                          </label>
                          {
                  <div className="text-center text-danger">
                    <span> {errMsgPinCode.ecom_pincode}</span>
                  </div>
                }
                        </Col>
                        
                        <Col sm={3}>
                          <label>
                          <Link to="#" onClick={handlePinCode}>
                          <button type="button" class="btn btn-danger">Submit</button>
                            </Link>
                          </label>
                        </Col>
                       
                        
                      </Row>
                    </Container>
                  </div>
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
         
          <div className="col-md-6 login-banner-img left">
            <div className="logo-rsponsive">
              <img src={logo_new} alt="" />
            </div>
            <div className="image-back"></div>
          </div>
          {/* right section */}
          <div className="col-md-6 login-form right">
            <div className="form-content">
              <form action="">
                <div className="top-form-text">
                  <div className="back-main">
                    <p className="welcome-text">{t("Enter OTP")}</p>

                    <p className="back-btn-red ">
                      {/* <Link to="/ForgotPassword">Go Back</Link> */}
                      <Link to="/Login">
                        <div className="changecolorpaymenttext">
                        {t("Go Back")}
                        </div>
                        </Link>
                    </p>
                  </div>
                  <div className="btn-shop-home">
                      <MDBBtn className="btn-gray-common" hidden onClick={toggleShow}></MDBBtn>
                    </div>

                  <p className="enter-text">
                  {t("Enter 4 digit Verification code below")}
                  </p>
                  <p className="enter-text">
                 {t("OTP has been")} {state.message}
                  </p>
                </div>
                <div className="email-main-login otp-main">
                  
                 
                  <input type="text" className="otp-input" maxlength="1" size="1"
                maxLength="1"
                value={state.otpDigit1}
                onChange={(e) => {
                  setState({
                    ...state,
                    otpDigit1: e.target.value,
  
                  });
                }}
                   onKeyDown={(e) => {
                    if (String(e.key).match("^[0-9]+$")) {
                      setState({ ...state, otpDigit1: e.key });
                      handleEnter(e);
                    }
                  }}
                  />
                  <input type="text" className="otp-input"
                     maxLength="1"
                     value={state.otpDigit2}
                     onChange={(e) => {
                       setState({
                         ...state,
                         otpDigit2: e.target.value,
       
                       });
                     }}
                     onKeyDown={(e) => {
                       if (String(e.key).match("^[0-9]+$")) {
                         setState({ ...state, otpDigit2: e.key });
                         handleEnter(e);
                       }
                     }}
                     />
                  <input type="text" className="otp-input"
                   maxLength="1"
                   value={state.otpDigit3}
                   onChange={(e) => {
                    setState({
                      ...state,
                      otpDigit3: e.target.value,
    
                    });
                  }}
                   onKeyDown={(e) => {
                     if (String(e.key).match("^[0-9]+$")) {
                       setState({ ...state, otpDigit3: e.key });
                       handleEnter(e);
                     }
                   }}
                 
                  />
                  <input type="text" className="otp-input" 
                    maxLength="1"
                    value={state.otpDigit4}
                    onChange={(e) => {
                     setState({
                       ...state,
                       otpDigit4: e.target.value,
     
                     });
                   }}
                    onKeyDown={(e) => {
                      if (String(e.key).match("^[0-9]+$")) {
                        setState({ ...state, otpDigit4: e.key });
                        handleEnter(e);
                      }
                    }}
                    />
                </div>
                <div className="d-flex mb-4 ">
                <Link to="/login">
                  <p className="have-account">{t("Didn't receive code")} </p>
                  </Link>
                  <Link to="#">
                  
                  {/* <p className="register ">Resend</p> */}
                  <p className="/Login ">Resend</p>
                  </Link>
                </div>
                {/* <Link to="/home"> */}
                <Link to="#">
                  <button className="login-button forgot-btn" onClick={handleSubmit}>
                
                    {t("Verify And Next")}

                    {loading ? (
                    <div
                      class="spinner-border"
                      style={{ textAlign: "right", color: "#FFFBF3" }}
                    />
                  ) : (
                    ""
                  )}
                    
                    </button>
                  
                </Link>
{/* 
                <Link to="/CreateProfile">
                  <button className="login-button forgot-btn">Verify And Next</button>
                  
                </Link> */}

                
                
               

               
              </form>
            </div>
            
          </div>
        </div>
      </main>
    </>
  )
}

export default PasswordOTP