import React from "react";
import Navbar from "../SharedComponents/Navbar";
import imgcard from "../assets/images/image_02.png";
import { Link, NavLink } from "react-router-dom";
import Footer from "../SharedComponents/Footer";
const Offers = () => {
  return (
    <main>
      <Navbar />

      {/* <.................... card-1..................> */}
      <div className=" offers-bg">
        <div className=" inner-card-section  ">
          <div className="offer-card ">
            <div className=" offer-card-inner">
              <div className="img-card">
                <img
                  src={imgcard}
                  alt=""
                  style={{ height: "120px", width: "180px" }}
                  className=""
                />
              </div>
              <div className="offer-content ">
                <p className="card-title">AGNEE STEEL</p>
                <p className="first-purchase ">20% off on first purchase</p>
                <p className="card-text">
                  Buy any steel for the first time and receive flat 20%
                  discount.
                </p>
                <label className="exp-date ">Expiry Date</label>{" "}
                <span className="current-date ">22-9-2022</span>
              </div>
            </div>
            <div className="btn-offer-grab">
              <Link to="/Getquote">
                <button className="grab-btn ">Get Quote</button>
              </Link>
            </div>
          </div>
        </div>

        {/* <.................... card-2..................> */}
        <div className=" inner-card-section mt-4 ">
          <div className="offer-card ">
            <div className=" offer-card-inner">
              <div className="img-card">
                <img
                  src={imgcard}
                  alt=""
                  style={{ height: "120px", width: "180px" }}
                  className=""
                />
              </div>
              <div className="offer-content ">
                <p className="card-title">AGNEE STEEL</p>
                <p className="first-purchase ">20% off on first purchase</p>
                <p className="card-text">
                  Buy any steel for the first time and receive flat 20%
                  discount.
                </p>
                <label className="exp-date ">Expiry Date</label>{" "}
                <span className="current-date ">22-9-2022</span>
              </div>
            </div>
            <div className="btn-offer-grab">
              <Link to="/Getquote">
                <button className="grab-btn ">Get Quote</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Offers;
