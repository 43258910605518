import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Routes, Route, useParams } from 'react-router-dom';
// import logo from "../assets/img/BKS Logo - webp 300-106 0-01-01.png";
import ic_mail from "../assets/images/ic_mail.svg";
import ic_call from "../assets/images/ic_call.svg";
import ic_call_red from "../assets/images/ic_call_red.svg";
import ic_menu from "../assets/images/ic_menu.svg";
import cart1 from "../assets/images/ic_cart1.svg";
import ic_bell from "../assets/images/ic_bell.svg";
import download from "../assets/slide/get-on-google-play_150x.webp";
import flag from "../assets/slide/Flag_of_India.webp";

import logo from "../assets/slide/BKS Logo - webp 300-106-01 (3).png";
import language from "../assets/slide/icons8-languages-25.png";


import { Container, Row, Col } from 'react-grid-system';

import ertugul from "../assets/images/accountp.png";
import ic_back_circle from "../assets/images/ic_back_circle.svg";
import Draggable from "react-draggable";
import ic_search_input from "../assets/images/ic_search_input.svg";
import Account from "./Account";
import { AppContext } from "../context/AppContext ";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { notifyError, notifySuccess } from "./notify";
import Accordion from 'react-bootstrap/Accordion';

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

const Navbar = () => {
  const {id} = useParams();
  console.log("id**********",id)
     
  // const { logedin, setLoggedIn } = useContext(AppContext);
  const image_url = "https://bharatkrushiseva.in/media/"
  const { customerData } = useContext(AppContext);
  console.log("customerData_one", customerData)
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  let currentRoute = location.pathname;
  const logedIn = localStorage.getItem("logedin")
  console.log(logedIn);

    
    
  var lang = localStorage.getItem("lang")


  const [notification, setNotificationsCount] = useState([]);
  console.log("notification", notification)
  const [state, setState] = useState({
    language: lang===null ? "en" : lang,
    token: customerData.token
  });
  useEffect(() => {

    notificationcountdata();
    notificationcountdata1();
    if (logedIn === "true") {
      setSearchProduct({ ...search_product, token: customerData.token })
    }

  }, []);
  const notificationcountdata = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_CART_COUNT,
      JSON.stringify({ ...state, })
    )
      .then((res) => {
        console.log("notif", res.json);
        
        setNotificationsCount(res.json);

      })
      .catch((err) => console.log(err));
  };





  const [search_product, setSearchProduct] = useState({
    search_keyword: "",
    language: lang===null ? "en" : lang,

  });



  const [errMsg, setErrMsg] = useState({ search: "" });
  const [data, setData] = useState({});

  const handleSubmit = (e) => {
    console.log("helo")
    e.preventDefault();
    setLoading(true);
    if (search_product.search_keyword === "") {
      setErrMsg({ ...errMsg, search_keyword: t("Enter Keyword") });
      return;
    }
    else {
      postWithAuthCallWithErrorResponse(
        ApiConfig.SEARCH_PRODUCT,
        JSON.stringify({ ...search_product, language : lang===null ? "en" : lang, })
      )
        .then((res) => {
          setLoading(false);
          console.log("res", res.json);
          setData(res.json.product_list);

          if (res.json.status === "true") {
            notifySuccess(res.json.message)
          }
          else {
            notifyError(res.json.message)
          }

          // console.log("mobile",state.mobile)
          navigate("/SearchProduct", {
            state: {
              data: res.json,
              search_key: search_product.search_keyword
            }
          });

        })
        .catch((err) => console.log(err));
    }
  };

  const [notification_count1, setNotificationsCount1] = useState([]);
  console.log("notification_count1", notification_count1)
  const [noti, setNoti] = useState({
    language: lang===null ? "en" : lang,
    user_id: customerData.id,
    notify_type: "order"
  });
  const notificationcountdata1 = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_USER_NOTIFICATION,
      JSON.stringify({ ...noti, })
    )
      .then((res) => {
        console.log("notif", res.json);
        setNotificationsCount1(res.json);

      })
      .catch((err) => console.log(err));
  };

  const refresh = () => window.location.reload(true)

  const changeLang = (event)=>{
    const lang = event.target.value
    i18n.changeLanguage(lang);
    localStorage.setItem('lang',lang); 
    refresh();
}

const langs= localStorage.getItem('lang',lang); 
console.log("langs",langs)

const { t } = useTranslation();




  return (
    <main className="main-nav">
      <div className="topnavset">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="topnavset1">
                <marquee width="100%" direction="left" height="100px">
                  <div className="topnavset2">
                  
                  { t('Experience super-fast delivery of your agri inputs right at your doorstep with Bharat Krushi Seva') } <u><a href="https://bharatkrushiseva.com/" target="_blank" className="term">{ t('Click now to explore more details about Bharat Krushi Seva') }</a></u>
                  </div>
                </marquee>

              </div>
            </Col>
          </Row>
        </Container>
      </div>


      <div className="topnavset3">
        <Container>
          <Row>
            <Col xs={12} sm={12}>

              <Row>
                <Col xs={6} sm={6}>
                  <div className="topnavset1">
                    
                    <a href="tel:7838269440">
                    <button className="ms-2" class="calltoorder">
                      { t('Call To Order') }: <u> +91- 78382 69440</u>
                    </button>
                    </a>
                   
                    <a href="https://play.google.com/store/apps/details?id=app.bharatkrushiseva.com" target="_blank" className="term">
                      <img className="topnavset4" src={download} alt="" />
                    </a>
                  
                  </div>
                </Col>
               
               
                {/* <Col xs={5} sm={5}>
                  <div className="topnavset8">
                    <a href="https://play.google.com/store/apps/details?id=app.bharatkrushiseva.com" target="_blank" className="term">
                       <img className="topnavset4" src={download} alt="" />
                    </a>
                  </div>
                </Col> */}
                
                {/* <button onClick={ changeLang('en') }>English</button> */}
              {/* <button onClick={ changeLang('hi') }>Hindi</button> */}
              
                <Col xs={6} sm={6} className="topnavset7">
                  
                  <div className="topnavset6 topnavset1">
                  {t("Change Language")} : &nbsp;
                  <img className="topnavset12" src={flag} alt="" />

                  <select onChange={changeLang} name="cars" id="cars" className="topnavset6" value={lang}>
                    <option value="en" >  English </option>
                    <option value="hi" >हिंदी</option>
                    <option value="mr">मराठी</option>
                  </select>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>


      <nav>
        <div className="top-logo-section">
          <div className="left-section">
            <img src={logo} alt="logo" className="main-logo img_round27" />
            {/* <b>Bharat Krushi Seva</b> */}
          </div>
          <div className="right-section">
            <div className="contact-main">

              <div className="main-product-page " id="HomeShopByCategoryMain">
                <div className="search-wrap ">

                  <button className="search-btn-input">

                    <Link to="#" onClick={handleSubmit}>
                      {loading ? (
                        <div
                          class="spinner-border"
                          style={{ textAlign: "right", color: "red" }}
                        />
                      ) : (
                        <img src={ic_search_input} alt="" />
                      )}

                    </Link>
                  </button>

                  <input
                    type="text"
                    class="form-control img_round_245"
                    placeholder={t("Search")}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, search_keyword: "" });
                      setSearchProduct({ ...search_product, search_keyword: e.target.value });
                    }}
                  />
                </div>
                {
                  <div className="text-center text-danger">
                    <span> {errMsg.search_keyword}</span>
                  </div>
                }

              </div>

            </div>

            {
              logedIn === "true"
                ?
                <div
                  className={` ${currentRoute === "/" ? "login-btn-main" : "hide-ac-cart"
                    }`}
                >
                  <Link to="/Login" hidden>
                    <div className="colerschange1">
                      {t("Login/Register")}
                    </div>
                  </Link>
                </div>
                :
                <div
                  className={` ${currentRoute === "/"
                    ||
                    currentRoute === "/Brand"
                    ||
                    currentRoute === "/home"
                    ||
                    currentRoute === "/products"
                    ||
                    currentRoute === "/SpecialComboOffers"
                    ||
                    currentRoute === "/SearchProduct"
                    ||
                    currentRoute === "/Category/"
                    ||
                    currentRoute === "/Detailslist/"
                    ||
                    currentRoute === "/BrandProduct"
                    ||
                    currentRoute === "/TrandingProduct"


                    ? "login-btn-main" : "hide-ac-cart"
                    }`}
                >
                  <Link to="/Login" >
                    <div className="colerschange1">
                    {t("Login/Register")}
                    </div>

                  </Link>





                </div>
            }


            <div
              className={`nav-cart-account-logo ${currentRoute === "/" && "hide-ac-cart"
                }`}
            >


              {
                logedIn === "true"
                  ?
                  <div className="bell cart">
                    <Link to="/Notifications">
                      <img src={ic_bell} alt="" />
                      <span className="cart-badge">
                        <label>{notification_count1 && notification_count1.unread_count}</label>
                      </span>
                    </Link>
                  </div>
                  :
                  <div className="bell cart" hidden>
                    <Link to="/Notifications">
                      <img src={ic_bell} alt="" />
                      <span className="cart-badge">
                        <label>{notification_count1 && notification_count1.unread_count}</label>
                      </span>
                    </Link>
                  </div>
              }

              {
                logedIn === "true"
                  ?

                  <div className="cart">
                    <a href="/Cart">
                      <img src={cart1} alt="" />
                      <span className="cart-badge">
                        <label>{notification && notification.cart_count}</label>
                      </span>
                    </a>
                  </div>
                  :
                  <div className="cart" hidden>
                    <a href="/Cart">
                      <img src={cart1} alt="" />
                      <span className="cart-badge">
                        <label>{notification && notification.cart_count}</label>
                      </span>
                    </a>
                  </div>
              }

              {
                logedIn === "true"
                  ?
                  <div className="account">
                    <Link
                      to="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      {/* <img src={ertugul} alt="" /> */}
                      <img src={image_url + customerData.user_image} alt="" />
                    </Link>
                  </div>
                  :
                  <div className="account" hidden>
                    <Link
                      to="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      {/* <img src={ertugul} alt="" /> */}
                      <img src={image_url + customerData.user_image} alt="" />
                    </Link>
                  </div>
              }



            </div>

            <Account />
          </div>
        </div>
        {/* ==== Menu === */}

        {/* className={({ isActive }) => (isActive ? "nav-active" : "")} */}
        {/* 
        <Container>
            <Row>
              <Col xs={12} sm={8}>
        <div className="main-product-page " id="HomeShopByCategoryMain">
                <div className="search-wrap ">

                  <button className="search-btn-input">

                    <Link to="#" onClick={handleSubmit}>
                      {loading ? (
                        <div
                          class="spinner-border"
                          style={{ textAlign: "right", color: "red" }}
                        />
                      ) : (
                        <img src={ic_search_input} alt="" />
                      )}

                    </Link>
                  </button>


                  <input
                    type="text"
                    class="form-control img_round_245"
                    placeholder="Search"
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, search_keyword: "" });
                      setSearchProduct({ ...search_product, search_keyword: e.target.value });
                    }}
                  />
                    
                </div>
                {
                  <div className="text-center text-danger">
                    <span> {errMsg.search_keyword}</span>
                  </div>
                }
              </div>
              </Col>
              </Row>
              </Container> */}


        <div style={{ 'padding': '10px' }} className="set_search_box2">
          <Container>
            <Row>
              <Col xs={12} sm={12} className="set_search_box1">

                <input
                  type="text"
                  class="form-control"
                  placeholder="Search"
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, search_keyword: "" });
                    setSearchProduct({ ...search_product, search_keyword: e.target.value });
                  }}
                />
                <button type="button" class="btn btn-outline-primary">
                  <Link to="#" onClick={handleSubmit}>
                    {loading ? (
                      <div
                        class="spinner-border"
                        style={{ textAlign: "right", color: "red" }}
                      />
                    ) : (
                      <img src={ic_search_input} alt="" />
                    )}

                  </Link>
                </button>
              </Col>
              {
                <div className="text-center text-danger">
                  <span> {errMsg.search_keyword}</span>
                </div>
              }
              {/* <Col xs={2} sm={2}>
              <button type="button" class="btn btn-outline-primary">Search</button>
             </Col>
              */}
            </Row>
          </Container>
        </div>

        <div className="menu-section">
          <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <img src={ic_menu} alt="" />
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">

                  <li className="nav-item">
                    <NavLink
                      className={`${currentRoute === "/home" || currentRoute === "/"
                        ? "nav-active nav-link"
                        : "nav-link"
                        }`}
                      to="/home"
                    >
                    { t('Home') }
                      

                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-active nav-link" : "nav-link"
                      }
                      to="/Brand"
                    >
                     { t('Brand') }
                    </NavLink>
                  </li>



                  <li className="nav-item">
                    <NavLink
                      className={`${currentRoute === "/products" ||
                        currentRoute === "/Detailslist" ||
                        currentRoute === "/Getquote"

                        ? "nav-active nav-link"
                        : "nav-link"
                        }`}
                      to="/products"
                    >
                      { t('latest offers') }
                      
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={`${currentRoute === "/Detailslist" ||
                        currentRoute === "/Getquote" ||
                        currentRoute === "/SpecialComboOffers"

                        ? "nav-active nav-link"
                        : "nav-link"
                        }`}
                      to="/SpecialComboOffers"
                    >
                      { t('Combo Offers') }
                      
                    </NavLink>
                  </li>


                  <li className="nav-item">
                    <NavLink
                      className={`${currentRoute === "/Detailslist" ||
                        currentRoute === "/Getquote" ||
                        currentRoute === "/RecentViewProduct"

                        ? "nav-active nav-link"
                        : "nav-link"
                        }`}
                      to="/RecentViewProduct"
                    >
                      { t('Recently View Product') }
                      
                    </NavLink>
                  </li>



                  {
                    logedIn === "true"
                      ?
                      <div hidden
                        className={` ${currentRoute === "/"
                          ? "login-btn-main-responsive"
                          : "hide-ac-cart"
                          }`}
                      >
                        <Link to="/Login">{t("Login/Register")}</Link>
                      </div>
                      :
                      <div
                        className={` ${currentRoute === "/"
                            ||
                            currentRoute === "/Brand"
                            ||
                            currentRoute === "/home"
                            ||
                            currentRoute === "/products"
                            ||
                            currentRoute === "/SpecialComboOffers"
                            ||
                            currentRoute === "/RecentViewProduct"
                            ||
                            currentRoute === "/SearchProduct"
                            ||
                            currentRoute === "/Category/"
                            ||
                            currentRoute === "/Detailslist/"
                            ||
                            currentRoute === "/BrandProduct"
                            ||
                            currentRoute === "/TrandingProduct"

                            ? "login-btn-main-responsive"
                            : "hide-ac-cart"
                          }`}
                      >
                        <Link to="/Login" className="term">{t("Login/Register")}</Link>
                      </div>

                  }


                </ul>
              </div>
            </div>
          </nav>
        </div>

        <div
          className={` ${currentRoute === "/" || currentRoute === "/home"
            ? "hide-ac-cart"
            : "route-bar-main"
            }`}
        >
          <div
            className={`${currentRoute === "/products" && "ProductListing"}`}
          >
            <img src={ic_back_circle} alt="" />

            {currentRoute === "/products" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                { t('latest offers') }
              </label>
            )}


        {currentRoute === "/" && (
              <label htmlFor="" className="route-page-name">
{/* 
                {
                 langs===null
                  ?
                  localStorage.setItem('lang',"en")
                  :
                  lang
                } */}
                
                {t("Home")}
              </label>
            )}






            {currentRoute === "/PriceFilter/" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                Price Filter
              </label>
            )}



            {currentRoute === "/Brand" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                {t("All Brands")}
              </label>
            )}

            {currentRoute === "/home/" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                {t("Home")}
              </label>
            )}



            {currentRoute === "/TrandingProduct" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                {t("Tranding Product")}
              </label>
            )}

           {currentRoute === "/RefCode" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                {t("RefCode")}
              </label>
            )}

          {currentRoute === "/ActiveReferCode" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                {t("ActiveReferCode")}
              </label>
            )}

          {currentRoute === "/MyAddress" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                {t("MyAddress")}
              </label>
            )}
            




            {currentRoute === "/BrandProduct" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                {t("Brand Product")}
              </label>
            )}

            {currentRoute === `/Detailslist/${id}` && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                {t("Details list")}
              </label>
            )}


            {currentRoute === "/My_order_detail/" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                  My Order Detail
              </label>
            )}


            {currentRoute === "/payment/" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                {t("payment")}
              </label>
            )}

            {currentRoute === "/SpecialComboOffers" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                { t('Combo Offers') }
              </label>
            )}

            {currentRoute === "/RecentViewProduct" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                { t('Recently View Product') }
              </label>
            )}

            {currentRoute === "/ShopByBrand" && (
              <label htmlFor="" className="route-page-name">
                {/* Product Listing */}
                ShopByBrand
              </label>
            )}

            {currentRoute === "/SearchProduct" && (
              <label htmlFor="" className="route-page-name">
                {t("SearchProduct")}
              </label>
            )}
            {currentRoute === "/Cart" && (
              <label htmlFor="" className="route-page-name">
                {t("cart")}
              </label>
            )}

            {currentRoute === "/payment" && (
              <label htmlFor="" className="route-page-name">
                payment
              </label>
            )}



            {currentRoute === "/Detailslist" && (
              <label htmlFor="" className="route-page-name">
                Product Listing / Details
              </label>
            )}

            {currentRoute === "/Getquote" && (
              <label htmlFor="" className="route-page-name">
                Product Listing / Details / Get Quote
              </label>
            )}

            {currentRoute === "/Category" && (
              <label htmlFor="" className="route-page-name">
                {t("Category")}
              </label>
            )}

            {currentRoute === "/Category/" && (
              <label htmlFor="" className="route-page-name">
               {t("Category")}
              </label>
            )}

            {currentRoute === "/Wishlist" && (
              <label htmlFor="" className="route-page-name">
                {t("Account / Wishlist")}
              </label>
            )}

            {currentRoute === "/ContactUs" && (
              <label htmlFor="" className="route-page-name">
                {t("Account / Contact Us")}
              </label>
            )}

            {currentRoute === "/MyOrders" && (
              <label htmlFor="" className="route-page-name">
                Account / My Orders
              </label>
            )}

            {currentRoute === "/Notifications" && (
              <label htmlFor="" className="route-page-name">
               {t("Notifications")}
              </label>
            )}

            {currentRoute === "/Profile" && (
              <label htmlFor="" className="route-page-name">
                {t("My Profile")}
              </label>
            )}

            {currentRoute === "/About" && (
              <label htmlFor="" className="route-page-name">
                About Us
              </label>
            )}

            {currentRoute === "/MyOrderDetails" && (
              <label htmlFor="" className="route-page-name">
                Account / My Orders / Details
              </label>
            )}

            {currentRoute === "/MyOffers" && (
              <label htmlFor="" className="route-page-name">
                {t("MyOffers")}
              </label>
            )}

            {currentRoute === "/TermsConditions" && (
              <label htmlFor="" className="route-page-name">
                Account / Terms & Conditions
              </label>
            )}

            {currentRoute === "/PrivacyPolicy" && (
              <label htmlFor="" className="route-page-name">
                Account / Privacy Policy
              </label>
            )}



            {currentRoute === "/EditAddress/" && (
              <label htmlFor="" className="route-page-name">
                {t("EditAddress")}
              </label>
            )}

            {currentRoute === "/EditAddress1/" && (
              <label htmlFor="" className="route-page-name">
                {t("EditAddress")}
              </label>
            )}

            {currentRoute === "/AddAddress" && (
              <label htmlFor="" className="route-page-name">
                {t("AddAddress")}
              </label>
            )}

            {/* <label htmlFor="" className="route-page-name">
              Product Listing
            </label> HowWeWork MyOrderDetails Detailslist Getquote offers MyOrders Notifications Profile About*/}
          </div>
        </div>
      </nav>





      <a href="tel:7838269440">
        <div className="fix-call-icon">
          <img src={ic_call_red} alt="" />
        </div>
      </a>

    </main>
  );
};

export default Navbar;
