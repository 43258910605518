import React, { useContext, useEffect, useState } from "react";
import Footer from "../SharedComponents/Footer";
import Navbar from "../SharedComponents/Navbar";
import ReactStars from "react-rating-stars-component";

import offer2 from "../assets/img/offer2.jpg";
import offer4 from "../assets/img/offer4.jpg";
import arrow_white from "../assets/images/view_all_arrow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext ";
import { Container, Row, Col } from 'react-grid-system';
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { notifyError, notifySuccess } from "../SharedComponents/notify";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";


const EditAddress1 = () => {
    const navigate = useNavigate();
    const previous_data = useLocation().state;
    const [data, setData] = useState({});
    console.log("previous_data", previous_data)
    const [state1, setState1] = useState({
        address_id:previous_data,
        house_name: "",
        area_name:"",
        landmark:"",
        pincode:"",
        state:"",
        district:"",
        taluka:"",
        village:"",
        mobile:"",
        
      
      });
      console.log("state1",state1.area_name)  

      const handleSubmit = (e) => {
        e.preventDefault();
        postWithAuthCallWithErrorResponse(
            ApiConfig.UPDATE_ADDRESS,
            JSON.stringify({ ...state1, })
          )
          .then((res) => {
            console.log("res", res.json);
            setData(res.json.data);
            if  (res.json.status==="true")
          {
            notifySuccess(res.json.output)
          }
          else{
            notifyError(res.json.output)
          }
          navigate("/Cart");
          console.log("user_name",state1.user_name)
        });
      }


      const [state, setState] = useState({
        address_id: previous_data
    });
      useEffect(() => {
        AddressDetailUser();
      }, []);
      
      const AddressDetailUser = () => {
        postWithAuthCallWithErrorResponse(
          ApiConfig.ADDRESS_DETAIL,
          JSON.stringify({ ...state, })
        )
        .then((res) => {
            console.log("AddressDetailUser1", res.json.address[0].house_name);
           
            const profile_obj={
                address_id: previous_data,
                house_name:  res.json.address[0].house_name,
                area_name:   res.json.address[0].area_name,
                landmark:    res.json.address[0].landmark,
                pincode:     res.json.address[0].pincode,
                state:       res.json.address[0].state,
                district:    res.json.address[0].district,
                taluka:      res.json.address[0].taluka,
                village:     res.json.address[0].village,
                mobile:      res.json.address[0].mobile
              }
              console.log("profile_obj", profile_obj);
              setState1({
                ...state1,
                ...profile_obj
              });
            
        });
      }

      const { t } = useTranslation();
      useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
      }, [])
    
    
    return (
        <main>
            <Navbar />

            <div className="offers-bg">
                <div className="top-heading">
                    <label htmlFor="" className="heading-red">
                        {t("Edit Your Address")}
                    </label>
                    <p className="paragraph">
                        {t("Shop from top Offers across the world")}
                    </p>
                </div>
                {/* {state1 &&
            state1.map((address) => {
              return (
                console.log(address),
                ( */}
                <div className=" inner-card-section  ">
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("House Name")} </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={state1.house_name}
                                        onChange={(e) => {
                                            setState1({ ...state1, house_name: e.target.value });
                                          }}
                                    />
                                </div>
                               
                            </Col>
                            <Col sm={4}>

                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Area Name")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={state1.area_name}
                                        onChange={(e) => {
                                            setState1({ ...state1, area_name: e.target.value });
                                          }}
                                    />
                                </div>
                               
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Landmark")} </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={state1.landmark}
                                        onChange={(e) => {
                                            setState1({ ...state1, landmark: e.target.value });
                                          }}  
                                    />
                                </div>
                               
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Pin Code")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={state1.pincode}
                                        onChange={(e) => {
                                            setState1({ ...state1, pincode: e.target.value });
                                          }}
                                    />
                                </div>
                                
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("state")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={state1.state}
                                        onChange={(e) => {
                                            setState1({ ...state1, state: e.target.value });
                                          }}
                                    />
                                </div>
                                
                            </Col>
                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("District")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={state1.district}
                                        onChange={(e) => {
                                            setState1({ ...state1, district: e.target.value });
                                          }}
                                    />
                                </div>
                               
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Taluka")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={state1.taluka}
                                        onChange={(e) => {
                                            setState1({ ...state1, taluka: e.target.value });
                                          }}
                                    />
                                </div>
                                
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Village")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={state1.village}
                                        onChange={(e) => {
                                            setState1({ ...state1, village: e.target.value });
                                          }}
                                        
                                    />
                                </div>
                               
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Mobile Number")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={state1.mobile}
                                        onChange={(e) => {
                                            setState1({ ...state1, mobile: e.target.value });
                                          }}
                                    />
                                </div>
                               
                            </Col>
                            <Col sm={12}>
                                <div class="form-group form_set">
                                <Link to="#" onClick={handleSubmit}>
                                    <button type="button" class="btn btn-warning btnfix1">{t("Update Address")}</button>
                                </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                    {/* )
                    );
                    })}  */}

            </div>

            <Footer />
        </main>
    );
};

export default EditAddress1;
