import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  BrowserRouter,
} from "react-router-dom";
import AppState from './context/AppState ';
import ReactGA from 'react-ga';


import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import tEn from './locales/en/transaltion.json';
import tHi from './locales/hi/transaltion.json';
import tMr from './locales/mr/transaltion.json';


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation:tEn 
      },
      hi: {
        translation: tHi
      },
      mr: {
        translation: tMr
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });







  ReactGA.initialize('G-BW0HYHQKSN');

 
  
    // Rest of your component code...
  

// i18next.init({
//   lng: 'en', // if you're using a language detector, do not define the lng option
//   resources: {
//     en: {
//       common: {
//         "HOME": "hello world"
//       }
//     },
//     hi: {
//       common: {
//         "HOME": "ghr"
//       }
//     }
//   }
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  // <I18nextProvider i18n = {i18next}>
  <BrowserRouter>
 <AppState>
   <App />
   </AppState>
  </BrowserRouter>
  // </I18nextProvider>
  
  
   
  
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
