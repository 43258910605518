import React, { useContext, useEffect, useState } from "react";
import Footer from "../SharedComponents/Footer";
import Navbar from "../SharedComponents/Navbar";
import mail_red from "../assets/images/mail_red.svg";
import call_red from "../assets/images/call_red.svg";
import ic_whatsapp from "../assets/images/ic_whatsapp.svg";
import { AppContext } from "../context/AppContext ";
import { Link } from "react-router-dom";
import ApiConfig from "../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import { notifyError, notifySuccess } from "../SharedComponents/notify";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

const ContactUs = () => {
  const {customerData} = useContext(AppContext);
  console.log("customerData_one",customerData.user_name)

  const [state, setState] = useState({
    language: "en",
    user_id: customerData.id,
    full_name: "",
    email: "",
    message: ""
  });
  const [errMsg, setErrMsg] = useState({ mobile: "" });
  const [data, setData] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.message === "") {
      setErrMsg({ ...errMsg, message: "Enter message" });
      return;
    }
    else {
      postWithAuthCallWithErrorResponse(
        ApiConfig.CONTCAT_US_FORM,
        JSON.stringify({ ...state, })
      )
      .then((res) => {
        console.log("res", res.json);
        setData(res.json.data);
        if  (res.json.status==="true")
      {
        notifySuccess(res.json.output)
      }
      else{
        notifyError(res.json.output)
      }
       

     
  
      })
      .catch((err) => console.log(err));
    }

  }

  const { t } = useTranslation();
useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])
    
  return (
    <main>
      <Navbar />
      <div className="main-contact-us">
        <div className="main-contact-card">
          <div className="left-contact">
            <h3 className="contact-heading">
              <span className="black">{t("Get In Touch With Us")} </span>{" "}
              {/* <span className="red"> With Us</span> */}
            </h3>

            <div className="form-contact">
              <div>
                <label htmlFor="">{t("Name")}</label> <br />
                <input 
                type="text" 
                className="input-contact" 
                value={customerData.user_name}
                disabled
                maxLength={10}
                onChange={(e) => {
                  setState({ ...state, full_name: e.target.value });
                }}
                 />
              </div>

              <div>
                <label htmlFor="">{t("Email ID")}</label> <br />
                <input 
                type="email" 
                className="input-contact" 
                disabled
                value={customerData.business_email} 
                onChange={(e) => {
                  setState({ ...state, email: e.target.value });
                }}
                />
              </div>
              

              <div>
                <label htmlFor="">{t("Message")}</label> <br />
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  className="message input-contact"
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, message: "" });
                    setState({ ...state, message: e.target.value });
                  }}
                ></textarea>
                {errMsg.message && errMsg.message.length > 0 && (
                  <span className="text-danger">{errMsg.message}</span>
                )}
              </div>
              <Link to="#" onClick={handleSubmit}>
              <button>{t("Send")}</button>
              </Link>
            </div>
          </div>
          <div className="right-contact">
            <section>
              <p className="heading">{t("Get In Touch With Us")}</p>
              <div className="call ">
                <span>
                  <img src={call_red} alt="" />
                </span>
                <a  href="tel:7838269440">
                <label htmlFor="">+91-7838269440</label>
                </a>
              </div>
              <div className="mail ">
                <span>
                  <img src={mail_red} alt="" />
                </span>
                <label htmlFor="">support@bharatkrushiseva.com</label>
              </div>
              <div className="whatsapp">
                <span>
                  <img src={ic_whatsapp} alt="" />
                </span>
                <a aria-label="Chat on WhatsApp" href="https://wa.me/7838269440">
                <label htmlFor="">+91-7838269440</label>
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default ContactUs;
