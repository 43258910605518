import React, { useContext, useEffect, useState } from "react";
import Navbar from "../SharedComponents/Navbar";
import Slider from "react-slick";
import banner_slide1 from "../assets/slide/slider1.jpg";
import banner_slide2 from "../assets/slide/slider2.jpg";
import banner_slide3 from "../assets/slide/slider3.jpg";
import banner_slide4 from "../assets/slide/slider4.jpg";

import arrow_white from "../assets/images/view_all_arrow.svg";
import Footer from "../SharedComponents/Footer";
import { Link, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "react-multi-carousel/lib/styles.css";
import HoverImage from "react-hover-image";
import howitwork1 from "../assets/slide/howitwork1.png";
import howitwork2 from "../assets/slide/howitwork2.png";
import howitwork3 from "../assets/slide/howitwork3.png";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { AppContext } from "../context/AppContext ";
import { multipartPostCallWithFormDataResponse, postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ScaleLoader } from "react-spinners";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import StarRating from './StarRating';
import bksassurred from "../assets/slide/bksassurred.png";

const SpecialComboOffers = () => {
  const [showDis, setShowDis] = useState(false);
  const [showDis1, setShowDis1] = useState(false);
  

  const discount = () => setShowDis(!showDis);
  const discount1 = () => setShowDis1(!showDis1);
  const [topRightModal, setTopRightModal] = useState(false);
  const toggleShow = () => setTopRightModal(!topRightModal);
  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  const bannerSlidea = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    autoplay: true,

    cssEase: "linear",
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  
  const settings3 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ]
  };
  
  const responsive = {
    superLargeDesktop: {

      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  const image_url = "http://bharatkrushiseva.in/media/"
  const image_url1 = "http://bharatkrushiseva.in"
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData)
  const [loading, setLoading] = useState(false);
  const [combo_offers, setComboOffers] = useState([]);
  console.log("combo_offers", combo_offers)
  const lang = localStorage.getItem("lang")
  console.log("lang",lang)
  const navigate = useNavigate();

  const [combo_offers_state, setComboOffersState] = useState({
    language: lang===null ? "en" : lang,
    token:customerData.token,
  });

  const [my_brand, setBrand] = useState([]);
  console.log("my_brand", my_brand)

  const [brand_state, setBrandState] = useState({
    language: lang===null ? "en" : lang,
  });

  useEffect(() => {
    get_combo_offer();
    get_benner_list_offers();
    get_My_Brand();
    get_My_Category();
 
  }, []);

  const getBrandId = (id) => {
    console.log("id", id)
    setLoading(true);
    postWithAuthCallWithErrorResponse(

      ApiConfig.GET_ECOM_PRODUCT_LIST_BRAND,
      JSON.stringify({
        ...brand_id_on, brand_id: id

      })
    ).then((res) => {
      setLoading(false);

      navigate("/BrandProduct", {
        state: {
          data: res.json,
          search_key: res.json && res.json.product_list[0] && res.json.product_list[0].brand_names

        }
      });
    })
  }

  const get_combo_offer= () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_TRANDING_COMBO_PRODUCT_LIST,
      JSON.stringify({ ...combo_offers_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("product_list", res.json);
        setComboOffers(res.json.product_list);

      })
      .catch((err) => console.log(err));

  };

  const [benner_list, setBennerList] = useState([]);
   console.log("benner_list", benner_list)
 
   const [benner_list_state, setBennerListState] = useState({
     language: lang===null ? "en" : lang,
     banner_on: "homescreen"
   });
 
   const get_benner_list_offers = () => {
     let formdata=new FormData();
     formdata.append("banner_on",benner_list_state.banner_on);
     formdata.append("language",benner_list_state.language);
 
     setLoading(true)
     multipartPostCallWithFormDataResponse(
       ApiConfig.GET_ECOM_BANNER_LIST, formdata
       // JSON.stringify({ ...benner_list_state, })
     )
       .then((res) => {
         setLoading(false)
         console.log("get_benner_list_offers", res.json);
         setBennerList(res.json.banner_data);
 
       })
       .catch((err) => console.log(err));
 
   };

   const get_My_Brand = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_BRAND,
      JSON.stringify({ ...brand_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("my_brand", res.json);
        setBrand(res.json.brand_list);

      })
      .catch((err) => console.log(err));
  }


  const [brand_id_on, setBrand_id_on] = useState({
    language: lang===null ? "en" : lang,
    // token:customerData.token,

  });

   const [my_category, setMyCategory] = useState([]);
   console.log("my_category", my_category)
 
   const [state, setState] = useState({
     language: lang===null ? "en" : lang,
   });
 
   const get_My_Category = () => {
     setLoading(true)
     postWithAuthCallWithErrorResponse(
       ApiConfig.GET_ECOM_CATEGORY,
       JSON.stringify({ ...state, })
     )
       .then((res) => {
         setLoading(false)
         console.log("profile", res.json);
         setMyCategory(res.json.category_list);
 
 
 
       })
       .catch((err) => console.log(err));
 
   };

   const { t } = useTranslation();

   useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem('lang');
    i18n.changeLanguage(currentLang);
  }, [])
 
 
  useEffect(() => {
    handleClick();
  }, [])

  const handleClick = () => {
  
  ReactGA.event({
      category: "Brand",
      action: "Brand Load",
      label:"Brand",
    })
    
  };

  return (
    <div>
      <Navbar />
      <main className="home-main-section">
      <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">

        <div className="sliding-image">
        {loading ?
          (
            <ScaleLoader

              color={"#E27A7A"}
              loading={loading}
              // size={10}
              className="loading setloader"
            />
          ) : (
            <Slider {...settings3} >
            {benner_list &&
              benner_list.map((benner_list_get) => {
                return (
                  console.log("benner_list_get", benner_list_get.redirect_id),
                  (
                    <div >
                      <Link to="#" onClick={() => getBrandId(benner_list_get.redirect_id)}>
                        {/* <img className="img_round52" src={image_url + benner_short_banner_advertise_get.banner_image} alt="" /> */}
                        <div className="img-slide-inner ">
                          <img className="img_round_set_new_slide " src={image_url + benner_list_get.banner_image} alt="" />
                        </div>
                      </Link>
                    </div>
                  )
                );
              })}
          </Slider>
          )}
      </div>
  

        <MDBModal
          animationDirection='right'
          show={topRightModal}
          tabIndex='-1'
          setShow={setTopRightModal}
        >
          <MDBModalDialog position='top-right' side>
            <MDBModalContent>
              <MDBModalHeader className='bg-info text-white'>
                <MDBModalTitle>{t("Filter")}</MDBModalTitle>
                <MDBBtn
                  color='none'
                  className='btn-close btn-close-white'
                  onClick={toggleShow}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>

                <Tabs>
                  <TabList>
                    <Tab>{t("Price")}</Tab>
                    <Tab>{t("Category")}</Tab>
                    <Tab>{t("Brand")}</Tab>
                  </TabList>

                  <TabPanel>
                    <div>
                      <Container>
                        <Row className="term">
                          <Col xs={6} sm={6}>
                            <Link to={"/PriceFilter/"}
                              state={
                                {
                                  min_price: 0,
                                  max_price: 200

                                }
                              }
                            >
                              <div class="form-check" className="ccf">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                <label class="form-check-label" for="exampleRadios1">
                                  Rs 0 - Rs 200
                                </label>
                              </div>
                            </Link>

                            <hr />
                          </Col>

                          <Col xs={6} sm={6}>
                            <Link to={"/PriceFilter/"}
                              state={
                                {
                                  min_price: 200,
                                  max_price: 500

                                }
                              }
                            >
                              <div class="form-check" className="ccf">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                <label class="form-check-label" for="exampleRadios1">
                                  Rs 200 - Rs 500
                                </label>
                              </div>
                            </Link>
                            <hr />
                          </Col>

                          <Col xs={6} sm={6}>
                            <Link to={"/PriceFilter/"}
                              state={
                                {
                                  min_price: 500,
                                  max_price: 1000

                                }
                              }
                            >
                              <div class="form-check" className="ccf">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                <label class="form-check-label" for="exampleRadios1">
                                  Rs 500 - Rs 1000
                                </label>
                              </div>
                            </Link>
                            <hr />
                          </Col>

                          <Col xs={6} sm={6}>
                            <Link to={"/PriceFilter/"}
                              state={
                                {
                                  min_price: 1000,
                                  max_price: 5000

                                }
                              }
                            >
                              <div class="form-check" className="ccf">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                <label class="form-check-label" for="exampleRadios1">
                                  Rs 1000 - Rs 5000
                                </label>
                              </div>
                            </Link>
                            <hr />
                          </Col>

                          <Col xs={6} sm={6}>
                            <Link to={"/PriceFilter/"}
                              state={
                                {
                                  min_price: 5000,
                                  max_price: 10000

                                }
                              }
                            >
                              <div class="form-check" className="ccf">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                <label class="form-check-label" for="exampleRadios1">
                                  Rs 5000 - Rs 10,000
                                </label>
                              </div>
                            </Link>
                            <hr />
                          </Col>

                          <Col xs={6} sm={6}>
                            <Link to={"/PriceFilter/"}
                              state={
                                {
                                  min_price: 10000,
                                  max_price: 50000

                                }
                              }
                            >
                              <div class="form-check" className="ccf">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                                <label class="form-check-label" for="exampleRadios1">
                                  Rs 10,000 - Rs 50,000
                                </label>
                              </div>
                            </Link>
                            <hr />
                          </Col>

                          <Col xs={6} sm={6}>
                            <Link to={"/PriceFilter/"}
                              state={
                                {
                                  min_price: 50000,
                                  max_price: 100000

                                }
                              }
                            >
                              <div class="form-check" className="ccf">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                <label class="form-check-label" for="exampleRadios1">
                                  Rs 50,000 - Rs 100,000
                                </label>
                              </div>
                            </Link>
                            <hr />
                          </Col>

                          <Col xs={6} sm={6}>
                            <Link to={"/PriceFilter/"}
                              state={
                                {
                                  min_price: 100000,
                                  // max_price: "more"

                                }
                              }
                            >
                              <div class="form-check" className="ccf">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                <label class="form-check-label" for="exampleRadios1">
                                  Rs 100,000 - More...
                                </label>
                              </div>
                            </Link>
                            <hr />
                          </Col>



                        </Row>
                      </Container>

                    </div>

                  </TabPanel>
                  <TabPanel>
                    {loading ?
                      (
                        <ScaleLoader

                          color={"#E27A7A"}
                          loading={loading}
                          // size={10}
                          className="loading setloader"
                        />
                      ) : (
                        <Container>
                          <Row>
                            {my_category &&
                              my_category.map((my_category_get) => {
                                return (
                                  console.log("my_category_get", my_category_get),

                                  (

                                    <Col xs={6} sm={6}>
                                      <Link to={"/Category/"}
                                        state={
                                          {
                                            id: my_category_get.id,
                                            category_name: my_category_get.category_name

                                          }

                                        }
                                      >
                                        <div class="form-check" className="ccf">
                                          <input class="form-check-input"
                                            type="radio" name="exampleRadios"
                                            id="exampleRadios1" value="option1"
                                          />

                                          <label class="form-check-label" for="exampleRadios1">
                                            {my_category_get.category_name}
                                          </label>


                                        </div>
                                      </Link>

                                      <hr />

                                    </Col>

                                  )
                                );
                              })}
                            {/* <Col xs={12} sm={12}>
                                        <div class="form-check">
                                        <button type="button" class="btn btn-outline-primary btnsett">Filter</button>
                                        </div>
                                        <hr/>
                                        </Col> */}

                          </Row>
                        </Container>
                      )}
                  </TabPanel>
                  <TabPanel>

                    {loading ?
                      (
                        <ScaleLoader

                          color={"#E27A7A"}
                          loading={loading}
                          // size={10}
                          className="loading setloader"
                        />
                      ) : (

                        <Container>
                          <Row>
                            {my_brand &&
                              my_brand.map((my_brand_get) => {
                                return (
                                  console.log("my_brand_get", image_url + my_brand_get.brand_image),
                                  (
                                    <Col xs={6} sm={6}>
                                      <Link to="#" onClick={() => getBrandId(my_brand_get.id)}>
                                        <div class="form-check" className="ccf">
                                          <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                          <label class="form-check-label" for="exampleRadios1">

                                            {my_brand_get.brand_name}

                                          </label>
                                        </div>
                                      </Link>

                                      <hr />
                                    </Col>
                                  )
                                );
                              })}
                          </Row>
                        </Container>
                      )}
                  </TabPanel>
                </Tabs>

              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
   
        



        
              <Container>
                  <Row>
                    <Col sm={4}>
                    <label htmlFor="" className="heading-black">
                    {t("Shop By Special Combo Offers")}</label>
                    &nbsp;
                  {/* <label htmlFor="" className="heading-red">
                  Special Combo Offers
                </label> */}
                
                    </Col>

                    <Col sm={8}>
                    <div className="btn-shop-home">
                    <MDBBtn className="btn-gray-common" onClick={toggleShow}>
                      {t("Filter")}
                    </MDBBtn><img src={arrow_white} alt="" className="ms-2" />
                    </div>
                    </Col>
                  </Row>
                </Container>
                <br/>
              </div>

              {/* ====================start latest offer================== */}
              {loading ?
                   (
      <ScaleLoader

        color={"#E27A7A"}
        loading={loading}
        // size={10}
        className="loading setloader"
      />
    ) : (
              <div className="latest-offer">
                <Container className="bs1">
                  <Row>
                  {combo_offers &&
                  combo_offers.map((combo_offers_get) => {
                    const results = combo_offers_get.grand_total_without_discount - combo_offers_get.grand_total;
                    const saved = results / combo_offers_get.grand_total_without_discount;
                    const dp = saved * 100;
                    const result1 = dp.toString().slice(0, 5);
                    return (
                      console.log("combo_offers_get", image_url1+combo_offers_get.product_image),
                      (
                    <Col className="flex-0">
                      <Link to={`/Detailslist/${combo_offers_get.id}`} state={combo_offers_get.id}>
                      <Card className="shadowcard mrc" >
                      <div className="mrc2">
                          <button type="button" class="btn btn-warning mrc3">{result1} % OFF</button>
                        </div>
                        {/* <img src={offer1} alt="" /> */}

                        <HoverImage className="image_height_set" src={image_url1+combo_offers_get.product_image} hoverSrc={image_url1+combo_offers_get.product_image} />
                       
                        <Card.Body>

                          <Card.Title>
                            <div className="text_set">
                            {combo_offers_get.product_name}
                            </div>
                          </Card.Title>
                          <Card.Title>
                            <div className="text_set">
                            <div className="rating-container">
                            <StarRating rating={combo_offers_get.average_rating} />
                            &nbsp;
                            <b className="rating-container1" class="btn btn-warning mrc3">
                            {combo_offers_get.average_rating}
                            </b> 
                            </div>
                            </div>
                          </Card.Title>
                          <Card.Text>
                          <div>
                            <label>{t("Available in")} :</label>&nbsp;
                            <label>{combo_offers_get.base_packet_quantity}</label>&nbsp;
                            <label>{combo_offers_get.base_packet_unit}</label>
                            
                            </div>
                          </Card.Text>
                          <Card.Text>
                          <div className="text_set">
                            {combo_offers_get.category.category_name}
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <s>{combo_offers_get.grand_total_without_discount} Rs</s>&nbsp;&nbsp; {combo_offers_get.grand_total} Rs
                          </Card.Text>
                          <Card.Text>
                            {combo_offers_get.is_bks_assured === 1 ? (
                              <img className="set-images" src={bksassurred} alt=""/>                            ) : null}
                          </Card.Text>
                          <Card.Text>
                        <div className="text_set1">
                          {results} Rs {t("Saved")}
                        </div>
                      </Card.Text>
                        </Card.Body>
                        
                      </Card>
                     
                      </Link>
                    </Col>
                      )
                      );
                      })}


                  </Row>
                </Container>
              </div>

    )}
              {/* ====================start latest offer================== */}

              
            </div>
          </section>
        </div>


        {/* ====================How it Works================= */}

        <div className="main-below-slide">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">

                <label htmlFor="" className="heading-red">
                  {t("How it Works")}
                </label>
                <p className="paragraph">
                  {t("To order from us, please follow these simple steps")}
                </p>
              </div>
              {/* ====================How it Works================== */}
              <div>

                <Container>

                  <Row className="hw">

                  <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork1} />
                        <h5 className="foothead">{t("Place an order")}</h5>
                        <p className="footparagraph">
                        {t("Choose from a wide range of daily essentials product's")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork2} />
                        <h5 className="foothead">{t("Your order is on its way")}</h5>
                        <p className="footparagraph">
                        {t("Your Delivery Partner Will Be At Your Door Shortly")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork3} />
                        <h5 className="foothead">{t("Enjoy")}</h5>
                        <p className="footparagraph">
                        {t("Avail the best quality products")}
                        </p>
                      </div>
                    </Col>

                  </Row>

                </Container>

                
              </div>
              {/* ====================How it Works================== */}

            </div>
          </section>
        </div>
{/* ====================How it Works================= */}
      </main>
      <Footer />
    </div>
  );
};

export default SpecialComboOffers;
