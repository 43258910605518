import React, { useEffect } from "react";
import Navbar from "../SharedComponents/Navbar";
import imgcard from "../assets/images/image_02.png";
import { Link, NavLink } from "react-router-dom";
import Footer from "../SharedComponents/Footer";
import ic_cart1 from "../assets/new_images/icon 25-25-01 (1).jpg";
import ic_cart2 from "../assets/new_images/icon 25-25-02 (2).jpg";
import ic_cart3 from "../assets/new_images/icon 25-25-04 (1).jpg";
import { Container, Row, Col } from 'react-grid-system';
import Card from 'react-bootstrap/Card';
import BKS_logo from "../assets/img/contact_us_app-01.png";
import verified from "../assets/img/verified.gif";
import ic_call_red from "../assets/images/ic_call_red.svg";
import ic_whatsapp from "../assets/images/ic_whatsapp.svg";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

const OrderPlace = () => {

  const { t } = useTranslation();
  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem('lang');
    i18n.changeLanguage(currentLang);
  }, [])
 
    
  return (
    <main>
            <Container>
            <Row>
              <Col  xs={12} sm={12}>
                <Card className="">
                  <Card.Body>
                    <Card.Title>
                      <h6>{t("Success")} ...! 
                      &nbsp;  &nbsp;  &nbsp; 
                      <Link  to="/home">
                      <button type="button" class="btn btn-outline-success"> {t("SHOP MORE")}...</button>
                       
                        </Link>
                        </h6>
                    </Card.Title>
                    {/* <Table striped bordered hover className="table_c_c "> */}
                    <hr/>

                    <Card.Text>
                      <Row>
                        <Col xs={12} sm={4}>
                       
                          <h6 className="sads1"> <img src={BKS_logo} alt="" className="ms-2 img_round32" /></h6>
                        </Col>
                        <Col xs={12} sm={4}>
                          <label className="sads1"> <img src={verified} alt="" className="ms-2 img_round34" />
                          <br/>
                          {t("Your Order is Placed Successfully, To confirm your order please contact our krushi helpline Number")}.</label>
                        </Col>
                        <Col xs={12} sm={4}>
                          <h6 className="sads1"> <img src={ic_cart3} alt="" className="ms-2 img_round24" />{t("Secure Payments")}</h6>
                        </Col>

                      </Row>
                      <br/>
                      <Row>
                      

                      </Row>
                    </Card.Text>
                    <hr/>

                    <Card.Text>
                      <Row>
                        <Col xs={12} sm={4}>
                            <div className="mt1-10">
                        <a  href="tel:7838269440">
                        <button type="button" class="btn btn-success order_place-button">
                        <img src={ic_call_red} alt="" />
                            {t("Expert Advice")}
                            </button>
                            </a>
                            </div>
                        </Col>
                        <br/>
                        <Col xs={12} sm={4}>
                        <div className="mt1-10">
                        <a aria-label="Chat on WhatsApp" href="https://wa.me/7838269440">
                        <button type="button" class="btn btn-success order_place-button">
                            {t("Order On Whatsapp")}
                            </button>
                          </a>
                          </div>
                        </Col>
                        <br/>
                        <Col xs={12} sm={4}>
                            <div className="mt1-10">
                        <Link  to="/MyOrders">
                        <button type="button" class="btn btn-success order_place-button">
                            {t("View Order")}
                            </button>
                            </Link>
                            </div>
                            
                        </Col>
                        <br/>

                      </Row>
                      <br/>
                      <Row>
                      

                      </Row>
                    </Card.Text>

                    <hr/>
                    <Card.Text>
                      <Row>
                        <Col xs={4} sm={4}>
                       <div>
                          <h6 className="sads1"> <img src={ic_cart1} alt="" className="ms-2 img_round24" />{t("Original Product")}</h6>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                        <div>
                          <h6 className="sads1"> <img src={ic_cart2} alt="" className="ms-2 img_round24" />{t("Fast Delivery")}</h6>
                        </div>
                        </Col>
                        <Col xs={4} sm={4}>
                        <div>
                          <h6 className="sads1"> <img src={ic_cart3} alt="" className="ms-2 img_round24" />{t("Secure Payments")}</h6>
                        </div>
                        </Col>

                      </Row>
                      <br/>
                      <Row>
                      <Col sm={12}>
                          <label>{t("Title and risk of loss for all products ordered by you shall pass on to you upon Bharat Krushi Seva shipment to the shipping carrier. Rules on COD vary based on transaction value, products, shipping location and other relevant parameters. Bharat Krushi Seva shall retain the right to offer/deny COD for specific cases")}</label>
                        </Col>

                      </Row>
                    </Card.Text>
                    


                  </Card.Body>
                </Card>
              </Col>
            </Row>
           
          </Container>
    </main>
  );
};

export default OrderPlace;
