import React, { useContext, useEffect, useState } from "react";
import Footer from "../SharedComponents/Footer";
import Navbar from "../SharedComponents/Navbar";
import ReactStars from "react-rating-stars-component";

import offer2 from "../assets/img/offer2.jpg";
import offer4 from "../assets/img/offer4.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-grid-system';
import Slider from "react-slick";
import { Detailsimages } from "../SharedComponents/Detailsimages";
import heart from "../assets/images/ic_heart.svg";
import Card from 'react-bootstrap/Card';
import ic_call_red from "../assets/images/ic_call_red.svg";
import ic_whatsapp from "../assets/images/ic_whatsapp.svg";
import { CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react'
import bullet from "../assets/images/ic_bullet_pt.svg";

import Table from 'react-bootstrap/Table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { AppContext } from "../context/AppContext ";
import { postWithAuthCallWithErrorResponse, postWithAuthCallWithFormDataResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { t } from "i18next";
import StarRating from "./StarRating";
import { notifyError, notifySuccess } from "../SharedComponents/notify";

import Modal from 'react-bootstrap/Modal';
import { FaSmile, FaRegSmile, FaRegThumbsUp, FaStar } from 'react-icons/fa'; // Import the desired icon from react-icons
import Button from 'react-bootstrap/Button';
import checkbuyer from "../assets/slide/checkbuyer.png";
import { Stepper } from 'react-form-stepper';
 



const My_order_detail = () => {
    const image_url1 = "http://bharatkrushiseva.in"

    const [my_category, setMyCategory] = useState([]);
    console.log("my_category", my_category)
    const [topRightModal, setTopRightModal] = useState(false);

    const toggleShow = () => setTopRightModal(!topRightModal);
    const navigate = useNavigate();
    const image_url = "http://bharatkrushiseva.in"
    const image_offer_url = "http://bharatkrushiseva.in/media/"
    const { customerData } = useContext(AppContext);
    console.log("customerData", customerData)
    const [loading, setLoading] = useState(false);
    const logedIn = localStorage.getItem("logedin")
    console.log(logedIn);

    const previous_data = useLocation().state;
    console.log("previous_data**************", previous_data)



    const [product_detail, setProductDetail] = useState([]);
    const [product_detail_images, setProductDetailimages] = useState([]);
    const [product_detail_item_packets, setProductDetaiitempackets] = useState([]);
    console.log("product_detail_item_packets", product_detail_item_packets)
    const [product_detail_items_pecifications, setProductDetailtemspecifications] = useState([]);
    const [product_detail_usemethod, setProductDetailitem_usemethod] = useState([]);
    const [product_detail_usemethod_item, setProductDetaiitempacketsitem] = useState([]);
    const [Order_detail, setOrderDetail1] = useState([]);
    console.log("Order_detail", Order_detail)

    const lang = localStorage.getItem("lang")
    console.log("lang",lang)

    useEffect(() => {
        productdetail();
        if (logedIn === "true") {
            // alert("")
            setState({ ...state, token: customerData.token })
        }

    }, []);

    const [Order_Rating, setOrderRating] = useState([]);
    const [FeedBack_List, setFeedbackList] = useState([]);
    console.log("FeedBack_List****************",FeedBack_List)
    
    const [state, setState] = useState({
        language: lang===null ? "en" : lang,
        token: customerData.token, 
        // product_id:previous_data && previous_data.product_id_fk__id,
        order_id:previous_data && previous_data.product_id_fk__id,
    });

    const productdetail = () => {
        setLoading(true)
        postWithAuthCallWithErrorResponse(
            ApiConfig.GET_USER_ORDER_DETAIL_BY_ID,  
            JSON.stringify({ ...state, })
        )
            .then((res) => {
                setLoading(false)
                console.log("profile", res.json);
                setOrderDetail1(res.json.order_detail.order_product);
                setOrderRating(res.json);
                setFeedbackList(res.json.order_detail.feedback_list);
                sessionStorage.setItem("product_packets_id", res.json.product_detail.item_list[0].item_packets[0].id)
            })
            .catch((err) => console.log(err));
    };

// Create Review
  const [showReview, setShowReview] = useState(false);
  const handleCloseReview = () => setShowReview(false);
  const handleShowReview = () => setShowReview(true);
  const [myCreateReviewId, setMyCreateReviewId] = useState([]);
  console.log('myCreateReviewId*************',myCreateReviewId)
  console.log('customerData*************',customerData)

  const [selectedValueImoji, setSelectedImojiValue] = useState(null);
  console.log('selectedValueImoji*************',selectedValueImoji)
  const [selectedPhotoVideoValue, setSelectedPhotoVideoValue] = useState(null);
  console.log('selectedPhotoVideoValue*************',selectedPhotoVideoValue)

  const handleIconClick = (value) => {
    setSelectedImojiValue(value);
  };

  const handleIconClick1 = (value) => {
    setSelectedPhotoVideoValue(value);
  };

  const [formDataReview, setFormDataReview] = useState({
    reason: '',
    photo: null,
    video: null
  });
  
  

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormDataReview({
        ...formDataReview,
        [name]: files[0]
      });
    } else {
      setFormDataReview({
        ...formDataReview,
        [name]: value
      });
    }
  };



  const handleSubmit = () => {
    setLoading(true);
    const add_photovideo_review_state = {
     
      feedback_for: "order",
      language :"en",
      order_id: myCreateReviewId,
      feedback_note: formDataReview.reason,
      feedback_rating:selectedValueImoji,
      feedback_type:selectedPhotoVideoValue,
      feedback_images: formDataReview.photo,  // Assuming a single photo
      feedback_video: formDataReview.video,
      token: logedIn === "true" ? customerData.token : undefined,
    };
    console.log('***********',add_photovideo_review_state)
    const formData= new FormData()
    formData.append("feedback_for","order")
    formData.append("language","en")
    formData.append("order_id",myCreateReviewId)
    formData.append("feedback_note",formDataReview.reason)
    formData.append("feedback_rating",selectedValueImoji)
    formData.append("feedback_type",selectedPhotoVideoValue)

      // Conditionally append image or video
    if (selectedPhotoVideoValue === 'image') {
      formData.append("feedback_images", formDataReview.photo);
    } else if (selectedPhotoVideoValue === 'video') {
      formData.append("feedback_video", formDataReview.video);
    }
    
    formData.append("token",logedIn === "true" ? customerData.token : undefined)

    
    postWithAuthCallWithFormDataResponse(
      ApiConfig.ADD_USER_ECOM_ORDER_FEEDBACK,
      formData
    )
    .then((res) => {
      setLoading(false);
      console.log('feedback*************', res.json);
      // setFeedbackReview(res.json);
      if (res.json.status === "true") {
          notifySuccess(res.json.message)
          
        }

        else {
          notifyError(res.json.message)
        }
        handleCloseReview(); // Close the modal after submitting the report
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });

    console.log("Selected Value: ", selectedValueImoji);
    console.log("Form Data***********: ", formDataReview);
    console.log("Form Data reason***********: ", formDataReview.photo);
    // Call your API here with formData and selectedValue
    handleCloseReview();
  };


    return (
        <main className="details-lists-main">
          
            <Navbar />
            
            {/* model Create Review */}
        <Modal show={showReview} >
                    <Modal.Header >
                    <Modal.Title>{t("Give your feedback")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="card-title">
                        <img src={`${image_offer_url}${customerData.user_image}`} className="set-text-view-more2" alt="User" />
                        &nbsp; {customerData.user_name}
                      </p>
                    </Modal.Body>
                      <p className="set-report-text">
                        {t("your feedback will be valuable for us. please give your feedback on these products")}
                      </p>
                   
                        

                        <div class="form-check mt-2">
                        <Container>
                          <Row>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaSmile size={24} /> </span>
                              </div>
                              <p className="" onClick={() => handleIconClick(2)}>
                                {t("Not bad")} </p>
                              <br/>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaRegSmile size={24} onClick={() => handleIconClick(3)} /> </span>
                              </div>
                              <p className="">{t("Okay")} </p>
                              <br/>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaRegThumbsUp size={24} onClick={() => handleIconClick(4)}/> </span>
                              </div>
                              <p className="">{t("Good")} </p>
                              <br/>
                            </Col>
                            <Col xs={3} sm={3}>
                              <div className="ecom_detail_list_changes">
                                <span> <FaStar size={24} onClick={() => handleIconClick(5)}/> </span>
                              </div>
                              <p className="">{t("Amazing")} </p>
                              <br/>
                            </Col>
                          </Row>
                        </Container>
                        </div>
                        <hr/>

                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1">{t("What is the main reason for your rating?")}</p>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            name="reason"
                            value={formDataReview.reason}
                            onChange={handleChange}
                          />
                        </div>
                        <hr/>
                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1">{t("Add Photo (Optional)")}</p>
                          <input
                            type="file"
                            name="photo"
                            onClick={() => handleIconClick1('image')}
                            className="btn-shop-home"
                            onChange={handleChange}
                          />
                        </div>
                        <hr/>
                        <div className="form-group">
                          <p htmlFor="exampleFormControlTextarea1">{t("Add Video (Optional)")}</p>
                          <input
                            type="file"
                            name="video"
                            onClick={() => handleIconClick1('video')}
                            className="btn-shop-home"
                            onChange={handleChange}
                          />
                        </div>

                      <p className="set-report-text">
                        {t("upload photos or video related to the product like unboxing, Spraying, Product Usage etc")}
                      </p>
                        
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReview}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        {t("confirm")}
                    </Button>
                    </Modal.Footer>
        </Modal>

            {/* <Stepper
              steps={[{ label: 'Step 1' }, { label: 'Step 2' }, { label: 'Step 3' }]}
              activeStep={2}
            /> */}
            <div className="details-bg ">
                <div className="inner-section-cart">
                    <div className="row">
                        <div className="col-lg-4">
                        </div>

                        <Container>
                            <Row>
                                <Col sm={6}>
                                    <label className="e_set">
                                        <label className="text_color"> {t("Basic Info")} : </label>
                                        &nbsp;&nbsp;
                                        ({t("Order By")} {customerData&&customerData.user_name})</label>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <label className="e_set">
                                    <label className="text_color">
                                       {t("Order Code")}:
                                        </label>
                                        &nbsp;&nbsp;
                                         {previous_data&&previous_data.order_code}</label>
                                    <hr />
                                </Col>

                                <Col sm={6}>
                                    <label className="e_set">
                                    <label className="text_color">
                                        {t("Order Date")} :
                                    </label>
                                    &nbsp;&nbsp;
                                         {previous_data&&previous_data.created_at}</label>
                                    <hr />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={6}>
                                    <label className="e_set">
                                    <label className="text_color">
                                        {t("User Name")} : 
                                    </label>
                                    &nbsp;&nbsp;
                                        {customerData&&customerData.user_name}</label>
                                    <hr />
                                </Col>

                                <Col sm={6}>
                                    <label className="e_set">
                                    <label className="text_color">
                                        {t("Buyer Mobile")} :
                                    </label>
                                    &nbsp;&nbsp;
                                         {customerData&&customerData.user_mobile}</label>
                                    <hr />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={6}>
                                    <label className="e_set">
                                    <label className="text_color">
                                        {t("Total Payable Amount")} :
                                    </label>
                                    &nbsp;&nbsp;
                                         {previous_data&&previous_data.total_amount_with_discount}</label>
                                    <hr />
                                </Col>

                                <Col sm={6}>
                                    <label className="e_set">
                                    <label className="text_color">
                                        {t("Amount Without Discount")} : 
                                    </label>
                                    &nbsp; &nbsp;
                                        {previous_data&&previous_data.total_amount_without_discount}</label>
                                    <hr />
                                </Col>
                            </Row>

                            
                        </Container>

                        <Container>
                            <Row>
                            <Col sm={4}>
                                {Order_Rating && Order_Rating.order_detail && Order_Rating.order_detail.feedback_count === 0 && Order_Rating.order_detail.order_status === 'completed' ? (
                                    <div
                                    onClick={() => {
                                        setMyCreateReviewId(Order_Rating && Order_Rating.order_detail.id);
                                        handleShowReview();
                                    }}
                                    style={{
                                        color: '#e74c3c',
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '10px'
                                    }}
                                    >
                                    <p style={{ margin: 0 }}>{t("Rate this product now")}</p>
                                    <StarRating rating="0" />
                                    </div>
                                ) : (
                                    <div className="offer-card">
                                    <div className="offer-card-inner card">
                                        <div className="card-body">
                                        {Array.isArray(FeedBack_List) && FeedBack_List.length > 0 ? (
                                            FeedBack_List.slice(0, 1).map((review, index) => {
                                            const user = FeedBack_List[0].user_id_fk;
                                            return (
                                                <div className="review-details" key={index}>
                                                <p>
                                                    <img src={`${image_offer_url}${user.user_image}`} className="set-text-view-more2" alt="User" />
                                                    &nbsp; {user.user_name}
                                                </p>
                                                <p>{user.user_mobile}</p>
                                                <p>
                                                    <StarRating rating={FeedBack_List[0].feedback_rating} />
                                                    <label className="set-text-view-more1">
                                                    <img className="set-text-view-more2" src={checkbuyer} alt="" />
                                                    {t("Valid Buyer")}
                                                    </label>
                                                </p>
                                                <p>
                                                    <span className="set-review-font">{FeedBack_List[0].feedback_note}</span>
                                                </p>
                                                <p>{new Date(FeedBack_List[0].created_at).toLocaleString()}</p>
                                                </div>
                                            );
                                            })
                                        ) : (
                                            <p>{t("No reviews available")}</p>
                                        )}
                                        </div>
                                    </div>
                                    </div>
                                )}
                                <hr />
                                </Col>
                                <Col sm={4}>
                                    <label className="e_set">
                                        <label className="text_color"> {t("Basic Info")} : </label>
                                        &nbsp;&nbsp;
                                        ({t("Order By")} {customerData&&customerData.user_name})</label>
                                    <hr />
                                </Col>
                                <Col sm={4}>
                                    <label className="e_set">
                                    <label className="text_color">
                                       {t("Order Code")}:
                                        </label>
                                        &nbsp;&nbsp;
                                         {previous_data&&previous_data.order_code}</label>
                                    <hr />
                                </Col>
                                

                                
                            </Row>
                            </Container>

                    </div>
                </div>

                    

              


                <div className=" detail-card top-heading-heart  mt-4">
                    <div className="top-head">
                        <div className=" bdr-btm  ">
                            {/* <p className="over-text">Order Detail </p> */}
                            {/* <button className="getquote-btn1 ms-2">Download Invoice</button> */}
                            <button className="getquote-btn1 ms-2">{t("Order Contain")}</button>
                        </div>
                    </div>
                    <div class="outer-wrapper">
                        <div class="table-wrapper">
                            <table Table scroll_bar>
                                <thead className="table header_color_change">
                           
                                    <tr>
                                      
                                        <th>{t("Product Name")}</th>
                                        <th>{t("Product Image")}</th>
                                        <th>{t("Product Type")}</th>
                                        <th>{t("Product price")}</th>
                                        <th>{t("Action")}</th>
                                        {/* <th>Items</th> */}
                                    </tr>
                                    
                                </thead>
                                <tbody>
                                {Order_detail &&
                                Order_detail.map((order_product_list) => {
                                return (
                                    console.log("order_product_list**************",order_product_list),
                                
                                    (
                                    <tr>
                                        
                                        <td>{order_product_list && order_product_list.product_id_fk__product_name}</td>

                                        <td>
                                        <div className="img-card">
                                           
                                           <img src={order_product_list && image_url+order_product_list.product_id_fk__product_image} alt="" className="" />
                                        </div>
                                        </td>

                                        <td>
                                        <div className="img-card">
                                            {order_product_list && order_product_list.product_id_fk__product_type}
                                           
                                        </div>
                                        </td>
                                        <td>
                                        <div className="img-card">
                                            {order_product_list && order_product_list.product_item[0] && order_product_list.product_item[0].item_id_fk__packet_quantity} 
                                            {order_product_list && order_product_list.product_item[0] && order_product_list.product_item[0].item_id_fk__unit_name} 
                                            <br></br>

                                            {order_product_list && order_product_list.product_item[0] && order_product_list.product_item[0].grand_item_id_fk__packet_base_price} Rs/-
                                        </div>
                                        </td>
                                        <td>
                                        <Link to={`/Detailslist/${order_product_list && order_product_list.product_id_fk__id}`}>
                                          <button type="button" class="btn btn-outline-primary">{t("Buy Again")}</button>
                                        </Link>
                                        </td>
                                    </tr>
                                     ) 
                                     );
                                   })}
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </main>
    );
};

export default My_order_detail;
