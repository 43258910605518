import { AppContext } from "./AppContext ";

import React, { useState } from "react";

const AppState = (props) => {

  const [customerData, setCustomerData] = useState({
    
    token: localStorage.getItem("token")? localStorage.getItem("token"):null,
    user_name: localStorage.getItem("user_name")? localStorage.getItem("user_name"):null,
    user_mobile: localStorage.getItem("user_mobile")? localStorage.getItem("user_mobile"):null,
    business_email: localStorage.getItem("business_email")? localStorage.getItem("business_email"):null,
    id: localStorage.getItem("id")? localStorage.getItem("id"):null,
    user_image: localStorage.getItem("user_image")? localStorage.getItem("user_image"):null,
    
    profile_detail:localStorage.getItem("customerData")? localStorage.getItem("customerData"):null,
 

  });
  const [userDetails, SetUserDetails] = useState(
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );
  const [logedIn, setLoggedIn ] = useState(localStorage.getItem("loggedIn") ?localStorage.getItem("loggedIn"):false)
  return (
    <AppContext.Provider
      value={{
        customerData,
        setCustomerData,
        SetUserDetails,
        userDetails,
        logedIn, setLoggedIn,
       
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
