import React, { useState } from "react";
import Navbar from "../SharedComponents/Navbar";
import Slider from "react-slick";
import banner_slide1 from "../assets/img/slidee1.jpg";
import banner_slide2 from "../assets/img/slider2.jpg";
import banner_bg_03 from "../assets/img/slider3.jpg";

import banner_bg_04 from "../assets/img/slider4.jpeg";
import offer1 from "../assets/img/offer1.jpg";
import offer2 from "../assets/img/offer2.jpg";
import offer3 from "../assets/img/offer3.jpg";
import offer4 from "../assets/img/offer4.jpg";
import crop from "../assets/img/crop.jpeg";
import agriged from "../assets/img/agriged.jpeg";
import Crop_Protection from "../assets/img/Crop_Protection.jpg";



import product_image_04 from "../assets/images/product_image_04.png";

import image_01 from "../assets/images/image_01.png";
import graphic_about from "../assets/images/graphic_about.svg";
import image_02 from "../assets/images/image_02.png";
import arrow_white from "../assets/images/view_all_arrow.svg";
import product_image_01 from "../assets/img/offer1.jpg";
import ic_cart from "../assets/images/ic_cart.svg";
import ic_heart from "../assets/images/ic_heart.svg";
import ic_heart_active from "../assets/images/ic_heart_active.svg";
import product_image_02 from "../assets/img/offer2.jpg";
import Footer from "../SharedComponents/Footer";
import how_it_works_image from "../assets/images/how_it_works_image.svg";
import { Link } from "react-router-dom";
import ic_search_input from "../assets/images/ic_search_input.svg";

import ic_call_red from "../assets/images/ic_call_red.svg";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HoverImage from "react-hover-image";



const ShopByBrand = () => {
  const [showDis, setShowDis] = useState(false);
  const [showDis1, setShowDis1] = useState(false);

  const discount = () => setShowDis(!showDis);
  const discount1 = () => setShowDis1(!showDis1);
  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  const bannerSlidea = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    autoplay: true,

    cssEase: "linear",
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };



  return (
    <div>
      <Navbar />
      <main className="home-main-section">
        {/* <Link to="/Getquote"> */}
        <div className="sliding-image">
          {/* <div className="btn-on-slide">
            <Link to="/Getquote">Grab It Today</Link>
          </div> */}
          <Slider {...bannerSlidea}>
            {/* <Link to="/Getquote"> */}
            <div className="img-slide-inner">
              <img src={banner_slide1} alt="" />
            </div>
            {/* </Link> */}
            <div>
              <img src={banner_slide2} alt="" />
            </div>
            <div className="img-slide">
              <img src={banner_bg_03} alt="" />
            </div>
            <div className="img-slide">
              <img src={banner_bg_04} alt="" />
            </div>
          </Slider>
        </div>
        {/* </Link> */}
        <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="main-product-page " id="HomeShopByCategoryMain">
                <div className="search-wrap ">
                  <button className="search-btn-input">
                    <img src={ic_search_input} alt="" />
                  </button>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="top-heading">
                <label htmlFor="" className="heading-black">
                 Shop By
                </label>{" "}
                <label htmlFor="" className="heading-red">
                Brand
                </label>{" "}
                <label htmlFor="" className="heading-red">
                Agri
                </label>
                <p className="paragraph">
                  Shop from top Offers across the world
                </p>
              </div>

              {/* ====================start latest offer================== */}
              <div className="latest-offer">
                <Container>
                  <Row>
                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer1} alt="" /> */}
                        <HoverImage src={offer1} hoverSrc={offer2} />
                        <Card.Body>
                          
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer2} alt="" /> */}
                        <HoverImage src={offer2} hoverSrc={offer1} />
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer3} alt="" /> */}
                        <HoverImage src={offer3} hoverSrc={offer1} />
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer4} alt="" /> */}
                        <HoverImage src={offer4} hoverSrc={offer3} />
                        
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer1} alt="" /> */}
                        <HoverImage src={offer1} hoverSrc={offer3} />
                        
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer2} alt="" /> */}
                        <HoverImage src={offer2} hoverSrc={offer4} />
                        
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer3} alt="" /> */}
                        <HoverImage src={offer3} hoverSrc={offer4} />
                        
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer4} alt="" /> */}
                        <HoverImage src={offer4} hoverSrc={offer2} />
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer1} alt="" /> */}
                        <HoverImage src={offer1} hoverSrc={offer3} />
                        
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer2} alt="" /> */}
                        <HoverImage src={offer2} hoverSrc={offer4} />
                        
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>

                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer3} alt="" /> */}
                        <HoverImage src={offer3} hoverSrc={offer4} />
                        
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="shadowcard" style={{ width: '15rem' }}>
                        {/* <img src={offer4} alt="" /> */}
                        <HoverImage src={offer4} hoverSrc={offer2} />
                        <Card.Body>
                          <Card.Title>Weedmar Super</Card.Title>
                          <Card.Text>
                            Crop Protection
                          </Card.Text>
                          <Card.Text>
                            650.0 Rs
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
              {/* ====================start latest offer================== */}

              <div className="btn-shop-home">
                <Link to="/products">
                  <button className="btn-gray-common">
                    View more <img src={arrow_white} alt="" className="ms-2" />
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ShopByBrand;
