import React, { useContext, useEffect, useState } from "react";
import Footer from "../SharedComponents/Footer";
import Navbar from "../SharedComponents/Navbar";
import ic_search_input from "../assets/images/ic_search_input.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import arrow_white from "../assets/images/view_all_arrow.svg";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import banner_slide1 from "../assets/new_images/Combo offers-01 (1).jpg";
import banner_slide2 from "../assets/new_images/Latest offer (1).jpg";
import banner_bg_03 from "../assets/new_images/Home- slider_1.jpg";
import banner_bg_04 from "../assets/new_images/Home- slider_4.jpg";
import HoverImage from "react-hover-image";


import howitwork1 from "../assets/slide/howitwork1.png";
import howitwork2 from "../assets/slide/howitwork2.png";
import howitwork3 from "../assets/slide/howitwork3.png";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { AppContext } from "../context/AppContext ";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import ReactPaginate from 'react-paginate';
import { useTranslation } from "react-i18next";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const SearchProduct = () => {
  const [showDis, setShowDis] = useState(false);
  const [showDis1, setShowDis1] = useState(false);

  const discount = () => setShowDis(!showDis);
  const discount1 = () => setShowDis1(!showDis1);
  const [topRightModal, setTopRightModal] = useState(false);

  const toggleShow = () => setTopRightModal(!topRightModal);
  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  

  const bannerSlidea = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    autoplay: true,

    cssEase: "linear",
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  const image_url = "http://bharatkrushiseva.in/media/"
  const image_url1 = "http://bharatkrushiseva.in"
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData)
  const [latest_offers, setLatestOffers] = useState([]);
  console.log("latest_offers", latest_offers)
  const lang = localStorage.getItem("lang")
  console.log("lang",lang)

  const previousPageData = useLocation().state;
  console.log("previousPageData",previousPageData)

  const [latest_offers_state, setletestOffersState] = useState({
    language: lang===null ? "en" : lang,
    token:customerData.token,
    page_no:1
  });

  useEffect(() => {
    get_latest_offer();
  }, [latest_offers_state]);

  const get_latest_offer= () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_PRODUCT_LIST,
      JSON.stringify({ ...latest_offers_state, })
    )
      .then((res) => {
        console.log("setletestOffersState", res.json);
        setLatestOffers(res.json);

      })
      .catch((err) => console.log(err));

  };

  const changePage = ({ selected }) => {
    setletestOffersState({
     ...latest_offers_state,
     page_no:selected+1
    })
 
   };


   const { t } = useTranslation();

   useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem('lang');
    i18n.changeLanguage(currentLang);
  }, [])


  return (
    <main className="home-main-section">
      <Navbar />

        <MDBModal
          animationDirection='right'
          show={topRightModal}
          tabIndex='-1'
          setShow={setTopRightModal}
        >

          <MDBModalDialog position='top-right' side>
            <MDBModalContent>
              <MDBModalHeader className='bg-success text-white'>
                <MDBModalTitle>Filter By</MDBModalTitle>
                <MDBBtn
                  color='none'
                  className='btn-close btn-close-white'
                  onClick={toggleShow}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className='row'>

                  <div className='col-12'>
                    <Container>
                      <Row>
                        <Col sm={4}>
                          Price
                        </Col>
                        <Col sm={8}>
                          <label>
                            <input type="radio" id="age1" name="age" value="30" />
                            &nbsp; Rs. 0 - Rs.200
                          </label>
                          <br/>
                        
                          <label>
                            <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 200 - Rs.500
                          </label>
                        </Col>
                         <Col sm={4}>
                          Category
                        </Col>
                        <Col sm={8}>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 500 - Rs.1000
                          </label>
                          <br/>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 1000 - Rs.5000
                          </label>
                        </Col>
                        <Col sm={4}>
                          Brand
                        </Col>
                        <Col sm={8}>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 5000 - Rs.10,000
                          </label>
                          <br/>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 10,000 - Rs.50,000
                          </label>
                        </Col>

                        <Col sm={4}>
                          Unit
                        </Col>
                        <Col sm={8}>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 50,000 - Rs.100,000
                          </label>
                          <br/>
                          <label>
                          <input type="radio" id="age2" name="age" value="60" />
                            &nbsp; Rs. 100,000 - More...
                          </label>
                        </Col>
                  
                      </Row>
                    </Container>
                  </div>
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        
        
{/* =========================All Product============================== */}
      <div className="main-below-slide">
          <section className="bckground-color-shop ">
            <div className="shop-section ">
            
              <div className="top-heading">
              <Container>
                  <Row>
                    <Col sm={4}>
                    <label htmlFor="" className="heading-black">
                    {t("Search Keyword")}: </label>
                    &nbsp;
                  <label htmlFor="" className="heading-red">
                  {previousPageData.search_key}
                 </label>
                
                    </Col>

                    <Col sm={8}>
                    {/* <div className="btn-shop-home">
                      <MDBBtn className="btn-gray-common" onClick={toggleShow}>Filter</MDBBtn><img src={arrow_white} alt="" className="ms-2" />
                    </div> */}
                    </Col>
                  </Row>
                </Container>
                
              </div>

              {/* ====================start latest offer================== */}
              <div className="latest-offer">
                <Container className="bs1">
                  <Row>

                  { previousPageData && previousPageData.data && previousPageData.data.product_list.length > 0 ?
                 previousPageData && previousPageData.data && previousPageData.data.product_list.map((latest_offers_get) => {
                  const results = latest_offers_get.grand_total_without_discount - latest_offers_get.grand_total;
                    const saved = results / latest_offers_get.grand_total_without_discount;
                    const dp = saved * 100;
                    const result1 = dp.toString().slice(0, 5);
                    return (
                      console.log("latest_offers_get",latest_offers_get && image_url1+latest_offers_get.product_image),
                      (
                    <Col className="flex-0">
                      <Link to={`/Detailslist/${latest_offers_get.id}`} state={latest_offers_get.id}>
                      
                      <Card className="shadowcard mrc" >
                      <div className="mrc2">
                          <button type="button" class="btn btn-warning mrc3">{result1} % OFF</button>
                        </div>
                        {/* <img src={offer1} alt="" /> */}

                        <img className="image_height_set" src={latest_offers_get && image_url1+latest_offers_get.product_image} />
                        <Card.Body>

                          <Card.Title>
                            <div className="text_set">
                              {latest_offers_get.product_name}
                              </div>
                       
                          </Card.Title>

                          <Card.Text>
                          <div>
                            <label>{t("Available in")} :</label> &nbsp;
                            <label>{latest_offers_get && latest_offers_get.base_packet_quantity}</label>&nbsp;
                            <label>{latest_offers_get && latest_offers_get.base_packet_unit}</label>
                          </div>
                          </Card.Text>

                          <Card.Text>
                          <div className="text_set">
                          {latest_offers_get && latest_offers_get.category && latest_offers_get.category.category_name}
                          </div>
                          </Card.Text>
                          <Card.Text>
                            <s>{latest_offers_get && latest_offers_get.grand_total_without_discount} Rs</s>&nbsp;&nbsp;{latest_offers_get.grand_total} Rs
                          </Card.Text>
                          <Card.Text>
                          <div className="text_set1">
                            {results} Rs {t("Saved")}
                          </div>
                        </Card.Text>
                        </Card.Body>
                      </Card>
                      </Link>
                    </Col>
                      )
                      );
                      }) :
                    <div>{t("No data Availabe in Search Product")}</div>
                    }


                  </Row>
                </Container>
              </div>
              {/* ====================start latest offer================== */}

              <div className="btn-shop-home">
                <Link to="/products">
                  <ReactPaginate
                      previousLabel={t("Previous")}
                      nextLabel={t("Next")}
                      pageCount={
                        latest_offers.next_page
                      }
                      onPageChange={changePage}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                      // forcePage={currentPage}
                    />
                </Link>
              </div>
            </div>
          </section>
        </div>

        {/* ====================How it Works================= */}

        <div className="main-below-slide">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">

                <label htmlFor="" className="heading-red">
                  {t("How it Works")}
                </label>
                <p className="paragraph">
                  {t("To order from us, please follow these simple steps")}.
                </p>
              </div>
              {/* ====================How it Works================== */}
              <div>

                <Container>

                  <Row className="hw">

                  <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork1} />
                        <h5 className="foothead">{t("Place an order")}</h5>
                        <p className="footparagraph">
                        {t("Choose from a wide range of daily essentials product's")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork2} />
                        <h5 className="foothead">  {t("Your order is on its way")}</h5>
                        <p className="footparagraph">
                        {t("Your Delivery Partner Will Be At Your Door Shortly")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork3} />
                        <h5 className="foothead">{t("Enjoy")}</h5>
                        <p className="footparagraph">
                        {t("Avail the best quality products")}
                        </p>
                      </div>
                    </Col>

                  </Row>

                </Container>

                
              </div>
              {/* ====================How it Works================== */}

            </div>
          </section>
        </div>
{/* ====================How it Works================= */}
{/* =========================All Product============================== */}
      <Footer />
    </main>
  );
};

export default SearchProduct;
