import React, { useContext, useEffect, useState } from "react";
import Navbar from "../SharedComponents/Navbar";
import Footer from "../SharedComponents/Footer";
import noti_icon from "../assets/img/notification_icon.svg";
import ic_tick from "../assets/img/ic_tick.svg";
import { AppContext } from "../context/AppContext ";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import { ScaleLoader } from "react-spinners";

const Notifications = () => {
  const image_url="http://bharatkrushiseva.in/media/"
    const {customerData} = useContext(AppContext);
    console.log("customerData",customerData)
    const [loading, setLoading] = useState(false);

    const [notification, setNotification] = useState([]);

    const lang = localStorage.getItem("lang")
    console.log("lang",lang)

    console.log("notification",notification)
    const [state, setState] = useState({
      user_id: customerData.id,
      language:lang,
      notify_type:"order"

    });
    
    useEffect(() => {
      getuser_notification();
    }, []);
    const getuser_notification = () => {
      setLoading(true)
      postWithAuthCallWithErrorResponse(
        ApiConfig.GET_USER_NOTIFICATION,
        JSON.stringify({ ...state, })
      ) 
      .then((res) => {
        setLoading(false)
        console.log("profile", res.json);
        setNotification(res.json.notifications);
       
      })
      .catch((err) => console.log(err));
    };

const { t } = useTranslation();

useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])
  return (
    <main>
      <Navbar />
      {loading ?
                   (
      <ScaleLoader

        color={"#E27A7A"}
        loading={loading}
        // size={10}
        className="loading setloader"
       
      />
    ) : (
      <div className="main-notification">
    {notification && notification.length > 0 ? (
      notification.map((notifications) => {
        return (
          console.log(notifications),
          (
            <div className="noti-box" key={notifications.id}>
              <div className="icon-para-main">
                <div className="icon">
                  <span>
                    {/* <img src={notification_icon} alt="" /> */}
                    <img src={noti_icon} alt="" />
                  </span>
                </div>
                <div className="paragraph-noti">
                  <b>{notifications.notify_title}</b>
                  <p>
                    {notifications.notify_message}
                    &nbsp; &nbsp; &nbsp; 
                    <img src={ic_tick} alt="" />
                  </p>
                  <div className="time-date">
                    <label className="me-2">{notifications.notify_date}</label>
                    <label>{notifications.notify_time}</label>
                  </div>
                </div>
              </div>
            </div>
        )
      );
    })
  ) : (
    <div className="no-notifications">
      <p style={{textAlign:"center"}}>No notifications available</p>
    </div>
  )}
</div>

      )}
      <Footer />
    </main>
  );
};

export default Notifications;
