import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import Navbar from "../SharedComponents/Navbar";
import Footer from "../SharedComponents/Footer";
import { useLocation } from "react-router-dom";
import { AppContext } from "../context/AppContext ";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import StarRating from "./StarRating";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import ReactPaginate from 'react-paginate';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { notifyError, notifySuccess } from "../SharedComponents/notify";






const ProductReview = () => {
  const logedIn = localStorage.getItem("logedin");
  const previous_data = useLocation().state;
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [myReview, setMyReview] = useState([]);
  const [my_pagination, setMyReviewPagination] = useState([]);
  console.log("my_pagination**********",my_pagination.next_page)
  
  const {id} = useParams();
  console.log("id**********",id)

  

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0); // Added state for page count
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const image_url = "http://bharatkrushiseva.in"
  const image_offer_url = "http://bharatkrushiseva.in/media/"
  const imgcard = "./assets/images/checkbuyer.png"; // replace with your actual image card path

  useEffect(() => {
    let currentLang = localStorage.getItem('lang');
    i18n.changeLanguage(currentLang);
  }, [])
  

  // add feedback report api
  const [myReviewId, setMyReviewId] = useState([]);
  console.log('myReviewId***************',myReviewId)

  const [show, setShow] = useState(false);
  const [selectedReason, setSelectedReason] = useState(" ");
  console.log('selectedReason***************',selectedReason)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const get_feedback_report = () => {
    setLoading(true);
    const add_feedback_review_state = {
      feedback_id: myReviewId,
      reporting_reason: selectedReason,
      token: logedIn === "true" ? customerData.token : undefined,
    };

    postWithAuthCallWithErrorResponse(
        ApiConfig.ADD_PRODUCT_FEEDBACK,
        JSON.stringify(add_feedback_review_state)
      )
      .then((res) => {
        setLoading(false);
        console.log('feedback*************', res.json);
        // setFeedbackReview(res.json);
        if (res.json.status === "true") {
            notifySuccess(res.json.message)
            
          }
  
          else {
            notifyError(res.json.message)
          }
        handleClose(); // Close the modal after submitting the report
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    };
    


  
  useEffect(() => {
    const get_My_review = (pageNo) => {
      setLoading(true);
      const productReview = {
        language: lang === null ? "en" : lang,
        product_id: id,
        feedback_for: 'product',
        token: logedIn === "true" ? customerData.token : undefined,
        page_no:pageNo
      };

      postWithAuthCallWithErrorResponse(
        ApiConfig.GET_PRODUCT_REVIEWS,
        JSON.stringify(productReview)
      )
      .then((res) => {
        setLoading(false);
        setMyReview(res.json.feedback_list);
        setMyReviewPagination(res.json);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    };

    get_My_review(page);
  }, [logedIn, customerData.token, lang, previous_data, page]);

  const changePage = ({ selected }) => {
    setPage(selected + 1);
  };


  //review report api call


  

  return (
    <div>
      <Navbar />


      
      <div className="review-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="offers-bg">
            {Array.isArray(myReview) && myReview.length > 0 ? (
              myReview.map((review, index) => {
                const feedback = review.feedback_id_fk;
                const user = feedback.user_id_fk;
                console.log('feedback************',feedback.id)
                return (
                  <div className="inner-card-section" key={index}>
                    <div className="offer-card">
                      <div className="offer-card-inner">
                        {feedback.feedback_type === 'image' && feedback.feedback_images.length > 0 ? (
                          feedback.feedback_images.map((image, imgIndex) => (
                            <div className="img-card" key={imgIndex}>
                              <img
                                src={`${image_url}${image.feedback_image}`} 
                                alt={`Feedback ${imgIndex}`}
                                style={{ height: "200px", width: "180px" }}
                                className=""
                              />
                            </div>
                          ))
                        ) : feedback.feedback_type === 'video' && feedback.feedback_video ? (
                          <div className="img-card">
                            <video controls autoPlay muted className="video-class setvideohw"  style={{ height: "200px", width: "180px" }}>
                              <source src={`${image_url}${feedback.feedback_video}`} type="video/mp4" />
                            </video>
                          </div>
                        ) : null}
                        <div className="offer-content">
                          <p className="card-title">
                            <img src={`${image_offer_url}${user.user_image}`} className="set-text-view-more2" alt="User" />
                            &nbsp; {user.user_name}
                          </p>
                          <p className="first-purchase">
                            <StarRating rating={feedback.feedback_rating} />
                          </p>
                          <p className="card-text">
                            <img className="set-text-view-more2" src={imgcard} alt="" /> 
                            {t("Valid Buyer")}
                          </p>
                          <p className="card-text">
                            {feedback.feedback_note}
                          </p>
                          <label className="exp-date">Created Date</label>
                          <span className="current-date">{new Date(feedback.created_at).toLocaleString()}</span>
                        </div>
                      </div>
                      <div className="btn-offer-grab">
                        <label className="set-report-button" 
                         onClick={()=>{
                            setMyReviewId(feedback.id)
                            handleShow()
                         }
                            
                            }>
                          {t("report")}
                        </label>

                        
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No reviews available</p>
            )}
          </div>
        )}
        {/* model */}
        <Modal show={show} >
                    <Modal.Header >
                    <Modal.Title>{t("Report this post")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t("Reason for reporting this post")}</Modal.Body>
                    <p className="set-report-text">{t("Your report is anonymous, except if your reporting an intellectual property infringement")}</p>
                   
                        <div class="form-check">
                            <input class="form-check-input" type="radio"  name="reportReason" value="Sexual content" id="flexRadioDefault1" onChange={handleReasonChange}  />
                            <label class="form-check-label" for="flexRadioDefault1">
                                {t("Sexual content")}
                            </label>
                        </div>

                        <div class="form-check mt-2">
                                <input class="form-check-input" type="radio" name="reportReason" value="Violent or repulsive content" id="flexRadioDefault2" onChange={handleReasonChange}  />
                                <label class="form-check-label" for="flexRadioDefault1">
                                    {t("Violent or repulsive content")}
                                </label>
                        </div>

                        <div class="form-check mt-2">
                            <input class="form-check-input" type="radio" name="reportReason" value="Spam or misleading" id="flexRadioDefault3"  onChange={handleReasonChange}/>
                            <label class="form-check-label" for="flexRadioDefault1">
                                {t("Spam or misleading")}
                            </label>
                        </div>
                        
                        <div class="form-check mt-2">
                            <input class="form-check-input" type="radio" name="reportReason" value="Other" id="flexRadioDefault4"  onChange={handleReasonChange}/>
                            <label class="form-check-label" for="flexRadioDefault1">
                                {t("Other")}
                            </label>
                        </div>
                        
                        <p class="mt-2 text-center" className="set-report-text">{t("This will help us to resolve future problems")}</p>
                        
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={get_feedback_report}>
                        {t("confirm")}
                    </Button>
                    </Modal.Footer>
                </Modal>

        <div className="btn-shop-home">
          <Link to="/productReview">
            <ReactPaginate
              previousLabel={t("Previous")}
              nextLabel={t("Next")}
              pageCount={my_pagination.next_page}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </Link>
        </div>
        </div>

        
      
      <Footer />
    </div>
  );
};

export default ProductReview;
