import React, { useState, Component, useContext, useEffect } from "react";
import Navbar from "../SharedComponents/Navbar";

import Slider from "react-slick";
import banner_slide1 from "../assets/slide/slider1.jpg";
import banner_slide2 from "../assets/slide/slider2.jpg";
import banner_slide3 from "../assets/slide/slider3.jpg";
import banner_slide4 from "../assets/slide/slider4.jpg";
import banner_bg_03 from "../assets/new_images/Home- slider_1.jpg";
import banner_bg_04 from "../assets/new_images/Home- slider_4.jpg";
import banner_bg_05 from "../assets/new_images/3 Banners-01 (2).jpg";
import poster1 from "../assets/poster_images/WEL_-_COME_BASH_OFFER-01.jpg";
import poster2 from "../assets/poster_images/Shop_by_category-01_w4dFlV5.jpg";
import poster3 from "../assets/poster_images/Shop_by_category-02_RG3ytm5.jpg";
import poster4 from "../assets/poster_images/Shop_by_category-03.jpg";
import poster5 from "../assets/poster_images/BKS_Welcome-01_D3d7l2b.jpg";
import poster6 from "../assets/poster_images/BKS_Welcome-02_Zvi39Zx.jpg";
import poster7 from "../assets/poster_images/BKS_Welcome-03_gmzUjBm.jpg";
import poster8 from "../assets/poster_images/2_Banners-02_b5NC2jq.jpg";
import poster9 from "../assets/poster_images/WhatsApp_Image_2023-04-21_at_3.07.43_PM.jpeg";
import poster10 from "../assets/poster_images/WhatsApp_Image_2023-04-21_at_3.07.43_PM_1.jpeg";
import poster11 from "../assets/poster_images/WhatsApp_Image_2023-04-21_at_3.07.43_PM_2.jpeg";

import poster12 from "../assets/poster_images/WhatsApp_Image_2023-04-21_at_12.06.41_PM_4.jpeg";
import poster13 from "../assets/poster_images/WhatsApp_Image_2023-04-21_at_12.06.41_PM_5_PMlK06N.jpeg";
import poster14 from "../assets/poster_images/WhatsApp_Image_2023-04-21_at_12.06.41_PM_6.jpeg";
import poster15 from "../assets/poster_images/WhatsApp_Image_2023-04-21_at_12.06.41_PM_7.jpeg";
import poster16 from "../assets/poster_images/2_Banners-01_E5Xemkn.jpg";
import banner_bg_06 from "../assets/new_images/3 Banners-03 (3).jpg";

import banner_bg_07 from "../assets/new_images/hone-slider3.jpg";




import sponsord_by1 from "../assets/img1/3 Banners-01.jpg";
// import sponsord_by1 from "../assets/img/sponsord_by1.webp";
import sponsord_by2 from "../assets/img1/3 Banners-02 (1).jpg";
import sponsord_by3 from "../assets/img1/3 Banners-03 (1).jpg";
import sponsord_by4 from "../assets/img/sponsord_by4.webp";
import sponsord_by5 from "../assets/img/sponsord_by5.webp";
import sponsord_by6 from "../assets/img/sponsord_by6.webp";

import latest_offer1 from "../assets/img/latest_offer1.webp";
import latest_offer2 from "../assets/img/latest_offer2.webp";
import latest_offer3 from "../assets/img/latest_offer3.webp";
import latest_offer4 from "../assets/img/latest_offer4.avif";
import latest_offer5 from "../assets/img/latest_offer5.avif";
import latest_offer6 from "../assets/img/latest_offer6.avif";


import latest_offer7 from "../assets/new_images/BKS Welcome-01 (2).jpg";
import latest_offer8 from "../assets/new_images/BKS Welcome-02 (2).jpg";
import latest_offer9 from "../assets/new_images/BKS Welcome-03 (3).jpg";
import latest_offer10 from "../assets/new_images/Home- slider_2.jpg";






import combo_offer1 from "../assets/img/combo_offer1.webp";
import combo_offer2 from "../assets/img/combo_offer2.webp";
import combo_offer3 from "../assets/img/combo_offer3.webp";
import combo_offer4 from "../assets/img/combo_offer4.webp";
import combo_offer5 from "../assets/img/combo_offer5.webp";
import combo_offer6 from "../assets/img/combo_offer6.webp";


import screen_showing1 from "../assets/img/screen_showing1.webp";
import screen_showing2 from "../assets/img/screen_showing2.webp";

import featurs_u1 from "../assets/new_images/Agreo-01 (2).jpg";
import featurs_u2 from "../assets/new_images/Agreo-02 (2).jpg";
import featurs_u3 from "../assets/new_images/Agreo-03 (2).jpg";
import featurs_u4 from "../assets/new_images/Agreo-04 (2).jpg";
import featurs_u5 from "../assets/new_images/Agreo-05 (2).jpg";
import featurs_u6 from "../assets/new_images/Agreo-06 (2).jpg";
import featurs_u7 from "../assets/new_images/Agreo-07 (2).jpg";

import must_try1 from "../assets/img/must_try1.webp";
import must_try2 from "../assets/img/must_try2.webp";
import must_try3 from "../assets/img/must_try3.webp";
import must_try4 from "../assets/img/must_try4.webp";

import howitwork1 from "../assets/slide/howitwork1.png";
import howitwork2 from "../assets/slide/website images-02 (1).png";
import howitwork3 from "../assets/slide/howitwork3.png";


import sponsord_product1 from "../assets/img/sponsord_product1.avif";
import sponsord_product2 from "../assets/img/sponsord_product2.avif";
import sponsord_product3 from "../assets/img/sponsord_product3.avif";
import sponsord_product4 from "../assets/img/sponsord_product4.png";
import sponsord_product5 from "../assets/img/ps5.avif";
import sponsord_product6 from "../assets/img/sponsord_product6.avif";

import sponsord_product7 from "../assets/img/sponsord_product7.png";
import sponsord_product8 from "../assets/img/sponsord_product8.avif";
import sponsord_product9 from "../assets/img/sponsord_product9.avif";
import sponsord_product10 from "../assets/img/sponsord_product10.avif";
import sponsord_product11 from "../assets/img/sponsord_product11.avif";
import sponsord_product12 from "../assets/img/sponsord_product12.avif";
import sponsord_product13 from "../assets/img/sponsord_product13.avif";
import sponsord_product14 from "../assets/img/sponsord_product14.avif";
import sponsord_product15 from "../assets/img/sponsord_product15.png";
import sponsord_product16 from "../assets/img/sponsord_product16.avif";

import sponsord_product17 from "../assets/img/sponsord_product17.jpg";
import sponsord_product18 from "../assets/img/sponsord_product18.png";
import sponsord_product19 from "../assets/img/sponsord_product19.png";
import sponsord_product20 from "../assets/img/sponsord_product20.png";
import sponsord_product21 from "../assets/img/sponsord_product21.avif";
import sponsord_product22 from "../assets/img/sponsord_product22.avif";
import sponsord_product23 from "../assets/img/sponsord_product23.avif";
import sponsord_product24 from "../assets/img/sponsord_product24.avif";
import sponsord_product25 from "../assets/img/sponsord_product25.avif";
import sponsord_product26 from "../assets/img/sponsord_product26.avif";
import sponsord_product27 from "../assets/img/sponsord_product27.avif";
import sponsord_product28 from "../assets/img/sponsord_product28.jpg";
import sponsord_product29 from "../assets/img/sponsord_product29.jpg";
import sponsord_product30 from "../assets/img/sponsord_product30.avif";
import sponsord_product31 from "../assets/img/sponsord_product31.avif";
import sponsord_product32 from "../assets/img/sponsord_product32.avif";

import sponsord_product33 from "../assets/img/sponsord_product33.jpg";
import sponsord_product34 from "../assets/img/sponsord_product34.jpg";
import sponsord_product35 from "../assets/img/sponsord_product35.png";
import sponsord_product36 from "../assets/img/sponsord_product36.avif";
import sponsord_product37 from "../assets/img/sponsord_product37.avif";
import sponsord_product38 from "../assets/img/sponsord_product38.avif";
import sponsord_product39 from "../assets/img/sponsord_product39.avif";
import sponsord_product40 from "../assets/img/sponsord_product40.avif";

import sponsord_product41 from "../assets/img/sponsord_product41.avif";
import sponsord_product42 from "../assets/img/sponsord_product42.avif";
import sponsord_product43 from "../assets/img/sponsord_product43.avif";
import sponsord_product44 from "../assets/img/sponsord_product44.avif";
import sponsord_product45 from "../assets/img/sponsord_product45.avif";
import sponsord_product46 from "../assets/img/sponsord_product46.avif";
import sponsord_product47 from "../assets/img/sponsord_product47.avif";
import sponsord_product48 from "../assets/img/sponsord_product48.avif";
import sponsord_product49 from "../assets/img/sponsord_product49.avif";
import sponsord_product50 from "../assets/img/sponsord_product50.avif";





import offer1 from "../assets/img/offer1.jpg";
import offer2 from "../assets/img/offer2.jpg";
import offer3 from "../assets/img/offer3.jpg";
import offer4 from "../assets/img/offer4.jpg";
import crop from "../assets/new_images/Shop by category-01 (1).jpg";
import crop_protection from "../assets/new_images/Shop by category-02 (1).jpg";
import seeds from "../assets/new_images/Shop by category-03 (1).jpg";
import irrigation_Accessories from "../assets/new_images/Shop by category-04 (1).jpg";
import Allied_Agribusiness from "../assets/new_images/Shop by category-05 (1).jpg";
import Agricultural_Implements from "../assets/new_images/Shop by category-06 (2).jpg";

import brand1 from "../assets/new_images/Brand category-01.jpg";
import brand2 from "../assets/new_images/Brand category-02.jpg";
import brand3 from "../assets/new_images/Brand category-03.jpg";
import brand4 from "../assets/new_images/Brand category-04 (2).jpg";
import brand5 from "../assets/new_images/Brand category-05.jpg";
import brand6 from "../assets/new_images/Brand category-06 (2).jpg";
import brand7 from "../assets/new_images/Brand category-07 (2).jpg";
import brand8 from "../assets/new_images/Brand category-08.jpg";

import arrow_white from "../assets/images/view_all_arrow.svg";
import ic_heart from "../assets/images/ic_heart.svg";
import Footer from "../SharedComponents/Footer";
import { Link, useNavigate } from "react-router-dom";
import ic_search_input from "../assets/images/ic_search_input.svg";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HoverImage from "react-hover-image";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import StarRating from './StarRating';

import SlidingPanel from 'react-sliding-side-panel';



import { CountdownCircleTimer } from 'react-countdown-circle-timer'


import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from "../context/AppContext ";
import { getWithAthCallithtext, getWithAuthCallWithErrorResponse, getWithAuthCallWithErrorResponsenew, multipartPostCallWithFormDataResponse, postWithAuthCallWithErrorResponse, simpleGetCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ScaleLoader } from "react-spinners";
import { CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CButton, CCollapse, CCard, CCardBody } from '@coreui/react'
import { notifyError, notifySuccess } from "../SharedComponents/notify";
import ReactGA from 'react-ga';

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
// import {useTranslation} from 'react-i18next';
import bksassurred from "../assets/slide/bksassurred.png";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 80,
  strokeWidth: 6
};
const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time set_timer1">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};
const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

const Home = () => {

  const [showDis, setShowDis] = useState(false);
  const [showDis1, setShowDis1] = useState(false);

  const discount = () => setShowDis(!showDis);
  const discount1 = () => setShowDis1(!showDis1);

  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = stratTime + 243248; // use UNIX timestamp in seconds

  const remainingTime = endTime - stratTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;
  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  const bannerSlidea = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    autoplay: true,

    cssEase: "linear",
    beforeChange: function (currentSlide, nextSlide) {

    },
    afterChange: function (currentSlide) {

    },
  };

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          variableWidth: false,
          centerMode: false,
        },
      },
    ]




  };

  const settings4 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: false,
          centerMode: false,
        },
      },
    ]
  };


  const settings5 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ]
  };


  const settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ]
  };



  const settings3 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ]
  };
  const responsive = {
    superLargeDesktop: {

      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };


  const [openPanel, setOpenPanel] = useState(false);
  const [show, setShow] = useState(false);

  const image_url = "https://bharatkrushiseva.in/media/"
  const image_url1 = "https://bharatkrushiseva.in"
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData)
  const [my_category, setMyCategory] = useState([]);
  console.log("my_category", my_category)
  const logedIn = localStorage.getItem("logedin")
  console.log(logedIn);




  ReactGA.initialize('G-BW0HYHQKSN');
 
 useEffect(()=>
 {
  ReactGA.pageview(window.location.pathname + window.location.search);
 },[]);



 
  const lang = localStorage.getItem("lang")
  console.log("lang",lang)
  

  const [topRightModal, setTopRightModal] = useState(false);
  const toggleShow = () => setTopRightModal(!topRightModal);

  const [visible, setVisible] = useState(false)
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)


  const navigate = useNavigate();

  const [state, setState] = useState({
    language: lang===null ? "en" : lang,
  });

  const [my_brand, setBrand] = useState([]);
  console.log("my_brand", my_brand)

  const [brand_state, setBrandState] = useState({
    language: lang===null ? "en" : lang,
  });

  const [todays_offers, setTodayOffers] = useState([]);
  console.log("todays_offers", todays_offers)

  const [todays_offers_state, setTodayOffersState] = useState({
    language: lang===null ? "en" : lang,
    token: customerData.token,
  });


  const [combo_offers, setComboOffers] = useState([]);
  console.log("combo_offers", combo_offers)

  const [combo_offers_state, setComboOffersState] = useState({
    language: lang===null ? "en" : lang,
    token: customerData.token,
  });



  const [loading, setLoading] = useState(false);

  useEffect(() => {
    get_My_Category();
    get_My_Brand();
    get_today_offer();
    get_combo_offer();
    get_latest_offer();
    get_active_offers();
    get_benner_list_offers();
    get_benner_list_advertise_offers();
    get_benner_list_category_banner();
    get_benner_list_advertise1_banner();
    get_benner_list_short_banner();
    get_benner_list_brand_banner();
    get_benner_sponserd_data();
    {
      if (logedIn === "true") {
        setletestOffersState({ ...latest_offers_state, token: customerData.token })
      }
    }
  }, []);

  const [latest_offers, setLatestOffers] = useState([]);
  console.log("latest_offers", latest_offers)

  const [latest_offers_state, setletestOffersState] = useState({
    language: lang===null ? "en" : lang,
    // token: customerData.token,
  });





  const get_My_Category = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_CATEGORY,
      JSON.stringify({ ...state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("profile", res.json);
        setMyCategory(res.json.category_list);
        sessionStorage.setItem("cupon_status", false)
        sessionStorage.setItem("coupon_code_status", false)

       


      })
      .catch((err) => console.log(err));

  };

  const get_My_Brand = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_BRAND,
      JSON.stringify({ ...brand_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("my_brand", res.json);
        setBrand(res.json.brand_list);

      })
      .catch((err) => console.log(err));

  };

  const get_today_offer = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_TRANDING_PRODUCT_LIST,
      JSON.stringify({ ...todays_offers_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("my_brand", res.json);
        setTodayOffers(res.json.product_list);

      })
      .catch((err) => console.log(err));

  };


  const get_combo_offer = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_TRANDING_COMBO_PRODUCT_LIST,
      JSON.stringify({ ...combo_offers_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("product_list", res.json);
        setComboOffers(res.json.product_list);

      })
      .catch((err) => console.log(err));

  };


  const get_latest_offer = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_PRODUCT_LIST,
      JSON.stringify({ ...latest_offers_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("setletestOffersState", res.json);
        setLatestOffers(res.json.product_list);

      })
      .catch((err) => console.log(err));

  };






  const [active_offers_key, setActiveOffersKey] = useState([]);
  console.log("active_offers_key", active_offers_key)

  const [active_offers_key_state, setactiveofferskeyOffersState] = useState({
    page_key: "home",
  });



  const get_active_offers = () => {
    console.log("hello")
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_SECTION,
      JSON.stringify({ ...active_offers_key_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("active_offers_key_state", res.json);
        setActiveOffersKey(res.json.sections);
       

      })
      .catch((err) => console.log(err));

  };



  const [benner_list, setBennerList] = useState([]);
  console.log("benner_list", benner_list)

  const [benner_list_state, setBennerListState] = useState({
    // language: lang,
    language : lang===null ? "en" : lang,
    banner_on: "homescreen"
  });

  const get_benner_list_offers = () => {
    let formdata = new FormData();
    formdata.append("banner_on", benner_list_state.banner_on);
    formdata.append("language", benner_list_state.language);

    setLoading(true)
    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_ECOM_BANNER_LIST, formdata
      // JSON.stringify({ ...benner_list_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("get_benner_list_offers", res.json);
        setBennerList(res.json.banner_data);

      })
      .catch((err) => console.log(err));

  };


  const [benner_list_advertise, setBennerListAdvertise] = useState([]);
  console.log("benner_list", benner_list)

  const [benner_list_advertise_state, setBennerListAdvertiseState] = useState({
    language: lang===null ? "en" : lang,
    banner_on: "advertise",
    section_key: "section_1"
  });

  const get_benner_list_advertise_offers = () => {
    let formdata = new FormData();
    formdata.append("banner_on", benner_list_advertise_state.banner_on);
    formdata.append("language", benner_list_advertise_state.language);
    formdata.append("section_key", benner_list_advertise_state.section_key);

    setLoading(true)
    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_ECOM_BANNER_LIST, formdata
      // JSON.stringify({ ...benner_list_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("get_benner_list_offers", res.json);
        setBennerListAdvertise(res.json.banner_data);

      })
      .catch((err) => console.log(err));

  };


  const [benner_list_category_banner, setBennerListcategory_banner] = useState([]);
  console.log("benner_list_category_banner", benner_list_category_banner)

  const [benner_list_category_banner_state, setBennerListAdvertisecategory_banner] = useState({
    language: lang===null ? "en" : lang,
    banner_on: "category_banner",
    section_key: "section_1"
  });

  const get_benner_list_category_banner = () => {
    let formdata = new FormData();
    formdata.append("banner_on", benner_list_category_banner_state.banner_on);
    formdata.append("language", benner_list_category_banner_state.language);
    formdata.append("section_key", benner_list_category_banner_state.section_key);

    setLoading(true)
    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_ECOM_BANNER_LIST, formdata
      // JSON.stringify({ ...benner_list_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("get_benner_list_offers", res.json);
        setBennerListcategory_banner(res.json.banner_data);

      })
      .catch((err) => console.log(err));

  };


  const [benner_list_category_advertise1, setBennerListcategory_advertise1] = useState([]);
  console.log("benner_list_category_advertise1", benner_list_category_advertise1)

  const [benner_list_category_advertise1_state, setBennerListAdvertisecategory_advertise1] = useState({
    language: lang===null ? "en" : lang,
    banner_on: "advertise",
    // section_key: "section_1"
  });

  const get_benner_list_advertise1_banner = () => {
    let formdata = new FormData();
    formdata.append("banner_on", benner_list_category_advertise1_state.banner_on);
    formdata.append("language", benner_list_category_advertise1_state.language);

    setLoading(true)
    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_ECOM_BANNER_LIST, formdata
      // JSON.stringify({ ...benner_list_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("get_benner_list_offers", res.json);
        setBennerListcategory_advertise1(res.json.banner_data);

      })
      .catch((err) => console.log(err));

  };



  const [benner_short_banner_advertise, setBennerShortBanner] = useState([]);
  console.log("benner_short_banner_advertise", benner_short_banner_advertise)

  const [benner_list_short_banner_state, setBennerListShortBanner] = useState({
    language: lang===null ? "en" : lang,
    banner_on: "short_banner",
    // section_key: "section_1"
  });

  const get_benner_list_short_banner = () => {
    let formdata = new FormData();
    formdata.append("banner_on", benner_list_short_banner_state.banner_on);
    formdata.append("language", benner_list_short_banner_state.language);

    setLoading(true)
    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_ECOM_BANNER_LIST, formdata
      // JSON.stringify({ ...benner_list_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("get_benner_list_offers", res.json);
        setBennerShortBanner(res.json.banner_data);

      })
      .catch((err) => console.log(err));

  };


  const [benner_brand_banner, setBennerBrandBanner] = useState([]);
  console.log("benner_brand_banner", benner_brand_banner)

  const [benner_list_brand_banner, setBennerListBranBanner] = useState({
    language: lang===null ? "en" : lang,
    banner_on: "brand_banner",
    // section_key: "section_1"
  });

  const get_benner_list_brand_banner = () => {
    let formdata = new FormData();
    formdata.append("banner_on", benner_list_brand_banner.banner_on);
    formdata.append("language", benner_list_brand_banner.language);

    setLoading(true)
    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_ECOM_BANNER_LIST, formdata
      // JSON.stringify({ ...benner_list_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("get_benner_list_offers", res.json);
        setBennerBrandBanner(res.json.banner_data);

      })
      .catch((err) => console.log(err));

  };


  const [benner_sponserd_product, setBennerSponserdProductnew] = useState([]);
  console.log("benner_sponserd_product", benner_sponserd_product)

  const [benner_list_sponserd_product_state, setBennerListSponserProductState] = useState({
    language: lang===null ? "en" : lang,
    banner_on: "sponsered_product",
    // section_key: "section_1"
  });

  const get_benner_sponserd_data = () => {
    let formdata = new FormData();
    formdata.append("banner_on", benner_list_sponserd_product_state.banner_on);
    formdata.append("language", benner_list_sponserd_product_state.language);

    setLoading(true)
    multipartPostCallWithFormDataResponse(
      ApiConfig.GET_ECOM_BANNER_LIST, formdata
      // JSON.stringify({ ...benner_list_state, })
    )
      .then((res) => {
        setLoading(false)
        console.log("get_benner_list_offers", res.json);
        setBennerSponserdProductnew(res.json.banner_data);

      })
      .catch((err) => console.log(err));

  };

  useEffect(() => {
    if (logedIn === "true") {
      setBrand_id_on({ ...brand_id_on, token: customerData.token })
    }

  }, []);


  const [brand_id_on, setBrand_id_on] = useState({
    language: lang===null ? "en" : lang,
    // token:customerData.token,

  });



  const getBrandId = (id) => {
    console.log("id", id)
    setLoading(true);
    postWithAuthCallWithErrorResponse(

      ApiConfig.GET_ECOM_PRODUCT_LIST_BRAND,
      JSON.stringify({
        ...brand_id_on, brand_id: id
      })
    ).then((res) => {
      setLoading(false);
      console.log("address_id", res.json.product_list[0].brand_names);

      navigate("/BrandProduct", {
        state: {
          data: res.json,
          search_key: res.json && res.json.product_list[0] && res.json.product_list[0].brand_names

        }
      });
    })
  }



  const [filter_category, setFilterCategory] = useState({
    language: lang===null ? "en" : lang,
    token:customerData.token,
    // category_id:"",
  });

  const getFilterId = (id) => {
    console.log("id",id)
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_ECOM_PRODUCT_LIST,
      JSON.stringify({...filter_category, category_id: id})
    ).then((res) => {
      console.log("address_id", res.json);

      if  (res.json.status==="true")
        {

          notifySuccess(res.json.output)
        }
        else{
          notifyError(res.json.output)
        }
    })
    .catch((err) => console.log(err));

  };

  const changeLang = (l)=>{
    //alert('OK');
  return ()=>{
    //alert('ok '+l);
    //Now change the language
    //object.member();
    i18n.changeLanguage(l);

    //Now set the current language in local storage
    localStorage.setItem('lang',l);

  }
}

const { t } = useTranslation();
useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])


useEffect(() => {
  handleClick();
}, [])

  const handleClick = () => {
 
  ReactGA.event({
      category: "Home",
      action: "Home Load",
      label:"Home",
    })
    
  };


  return (
    <div>

      <Navbar />

      {/* <button onClick={handleClick}>Click Me</button> */}

      <main className="home-main-section">

      {/* <h1>{ t('Home') }</h1> */}
      {/* <button onClick={ changeLang('en') }>English</button>
      <button onClick={ changeLang('hi') }>Hindi</button> */}

      {/* <button onClick={refresh}>Refresh</button> */}
        {/* ===========================shop by category========================= */}


        <div className="main-below-slide">
          <section className="bckground-color-shop sbccc">
            <div className="shop-section">
              <div className="top-heading">
                <MDBModal
                  animationDirection='right'
                  show={topRightModal}
                  tabIndex='-1'
                  setShow={setTopRightModal}
                >
                  <MDBModalDialog position='top-right' side>
                    <MDBModalContent>
                      <MDBModalHeader className='bg-info text-white'>
                        <MDBModalTitle>Filter By</MDBModalTitle>
                        <MDBBtn
                          color='none'
                          className='btn-close btn-close-white'
                          onClick={toggleShow}
                        ></MDBBtn>
                      </MDBModalHeader>
                      <MDBModalBody>

                        <Tabs>
                          <TabList>
                            <Tab>Price</Tab>
                            <Tab>Category</Tab>
                            <Tab>Brands</Tab>
                          </TabList>

                          <TabPanel>
                            <div>
                              <Container>
                                <Row>
                                  <Col xs={6} sm={6}>
                                    <Link to={"/PriceFilter/"}
                                      state={
                                        {
                                          min_price: 0,
                                          max_price: 200

                                        }
                                      }
                                    >
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                        <label class="form-check-label topnavset4" for="exampleRadios1">
                                          Rs 0 - Rs 200
                                        </label>
                                      </div>
                                    </Link>

                                    <hr />
                                  </Col>

                                  <Col xs={6} sm={6}>
                                    <Link to={"/PriceFilter/"}
                                      state={
                                        {
                                          min_price: 200,
                                          max_price: 500

                                        }
                                      }
                                    >
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                        <label class="form-check-label topnavset4" for="exampleRadios1">
                                          Rs 200 - Rs 500
                                        </label>
                                      </div>
                                    </Link>
                                    <hr />
                                  </Col>

                                  <Col xs={6} sm={6}>
                                    <Link to={"/PriceFilter/"}
                                      state={
                                        {
                                          min_price: 500,
                                          max_price: 1000

                                        }
                                      }
                                    >
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                        <label class="form-check-label topnavset4" for="exampleRadios1">
                                          Rs 500 - Rs 1000
                                        </label>
                                      </div>
                                    </Link>
                                    <hr />
                                  </Col>

                                  <Col xs={6} sm={6}>
                                    <Link to={"/PriceFilter/"}
                                      state={
                                        {
                                          min_price: 1000,
                                          max_price: 5000

                                        }
                                      }
                                    >
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                        <label class="form-check-label topnavset4" for="exampleRadios1">
                                          Rs 1000 - Rs 5000
                                        </label>
                                      </div>
                                    </Link>
                                    <hr />
                                  </Col>

                                  <Col xs={6} sm={6}>
                                    <Link to={"/PriceFilter/"}
                                      state={
                                        {
                                          min_price: 5000,
                                          max_price: 10000

                                        }
                                      }
                                    >
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                        <label class="form-check-label topnavset4" for="exampleRadios1">
                                          Rs 5000 - Rs 10,000
                                        </label>
                                      </div>
                                    </Link>
                                    <hr />
                                  </Col>

                                  <Col xs={6} sm={6}>
                                    <Link to={"/PriceFilter/"}
                                      state={
                                        {
                                          min_price: 10000,
                                          max_price: 50000

                                        }
                                      }
                                    >
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                                        <label class="form-check-label topnavset4" for="exampleRadios1">
                                          Rs 10,000 - Rs 50,000
                                        </label>
                                      </div>
                                    </Link>
                                    <hr />
                                  </Col>

                                  <Col xs={6} sm={6}>
                                    <Link to={"/PriceFilter/"}
                                      state={
                                        {
                                          min_price: 50000,
                                          max_price: 100000

                                        }
                                      }
                                    >
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                        <label class="form-check-label topnavset4" for="exampleRadios1">
                                          Rs 50,000 - Rs 100,000
                                        </label>
                                      </div>
                                    </Link>
                                    <hr />
                                  </Col>

                                  <Col xs={6} sm={6}>
                                    <Link to={"/PriceFilter/"}
                                      state={
                                        {
                                          min_price: 100000,
                                          // max_price: "more"

                                        }
                                      }
                                    >
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                        <label class="form-check-label topnavset4" for="exampleRadios1">
                                          Rs 100,000 - More...
                                        </label>
                                      </div>
                                    </Link>
                                    <hr />
                                  </Col>



                                </Row>
                              </Container>

                            </div>

                          </TabPanel>
                          <TabPanel>
                            {loading ?
                              (
                                <ScaleLoader

                                  color={"#E27A7A"}
                                  loading={loading}
                                  // size={10}
                                  className="loading setloader"
                                />
                              ) : (
                                <Container>
                                  <Row>
                                    {my_category &&
                                      my_category.map((my_category_get) => {
                                        return (
                                          console.log("my_category_get", my_category_get),

                                          (

                                            <Col xs={6} sm={6}>
                                              <Link to={"/Category/"}
                                                state={
                                                  {
                                                    id: my_category_get.id,
                                                    category_name: my_category_get.category_name

                                                  }

                                                }
                                              >
                                                <div class="form-check">
                                                  <input class="form-check-input"
                                                    type="radio" name="exampleRadios"
                                                    id="exampleRadios1" value="option1"
                                                  />

                                                  <label class="form-check-label topnavset4" for="exampleRadios1">
                                                    {my_category_get.category_name}
                                                  </label>


                                                </div>
                                              </Link>

                                              <hr />

                                            </Col>

                                          )
                                        );
                                      })}
                                    {/* <Col xs={12} sm={12}>
                                        <div class="form-check">
                                        <button type="button" class="btn btn-outline-primary btnsett">Filter</button>
                                        </div>
                                        <hr/>
                                        </Col> */}

                                  </Row>
                                </Container>
                              )}
                          </TabPanel>
                          <TabPanel>

                            {loading ?
                              (
                                <ScaleLoader

                                  color={"#E27A7A"}
                                  loading={loading}
                                  // size={10}
                                  className="loading setloader"
                                />
                              ) : (

                                <Container>
                                  <Row>
                                    {my_brand &&
                                      my_brand.map((my_brand_get) => {
                                        return (
                                          console.log("my_brand_get", image_url + my_brand_get.brand_image),
                                          (
                                            <Col xs={6} sm={6}>
                                              <Link to="#" onClick={() => getBrandId(my_brand_get.id)}>
                                                <div class="form-check">
                                                  <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                                  <label class="form-check-label topnavset4" for="exampleRadios1">

                                                    {my_brand_get.brand_name}

                                                  </label>
                                                </div>
                                              </Link>

                                              <hr />
                                            </Col>
                                          )
                                        );
                                      })}
                                  </Row>
                                </Container>
                              )}
                          </TabPanel>
                        </Tabs>

                      </MDBModalBody>
                    </MDBModalContent>
                  </MDBModalDialog>
                </MDBModal>
                {/* <Container>
                  <Row>
                    <Col xs={12} sm={12}>
                      <div className="btn-shop-home">
                        <MDBBtn className="btn-gray-common" onClick={toggleShow}>
                          Filter
                        </MDBBtn><img src={arrow_white} alt="" className="ms-2" />
                      </div>
                    </Col>
                  </Row>
                </Container> */}


                <br />
              </div>

              {/* ====================start latest offer================== */}
              {loading ?
                (
                  <ScaleLoader

                    color={"#E27A7A"}
                    loading={loading}
                    // size={10}
                    className="loading setloader"
                  />
                ) : (
                  <Slider {...settings1}>


                    {my_category &&
                      my_category.map((my_category_get) => {
                        return (
                          console.log("my_category_get", my_category_get),

                          (
                            <div className="setseed">

                              <Link to={"/Category/"}
                                state={
                                  {
                                    id: my_category_get.id,
                                    category_name: my_category_get.category_name

                                  }

                                }
                              >
                                <img className="img_round" src={image_url + my_category_get.category_image} alt="" />

                                <Card.Body>
                                  <Card.Title>
                                    <span className="topnavset5">
                                    {my_category_get.category_name}
                                    </span>
                                    </Card.Title>
                                </Card.Body>
                              </Link>
                            </div>
                          )
                        );
                      })}
                  </Slider>

                )}
            </div>
          </section>
        </div>





        {loading ?
          (
            <ScaleLoader

              color={"#E27A7A"}
              loading={loading}
              // size={10}
              className="loading setloader"
            />
          ) : (
            <div className="main-below-slide">
              <div>
                {active_offers_key &&
                  active_offers_key.map((active_offers_key_get) => {
                    return (
                      console.log("active_offers_key_get", active_offers_key_get.is_active),

                      (
                        <div
                          style={
                            active_offers_key_get.is_active === 1
                              ?
                              { display: "" }
                              :
                              { display: "none" }
                          }
                        >
                          <section className="bckground-color-shop">
                            <div className="shop-section">
                              {/* ====================screen images offer================== */}

                              {benner_list_advertise &&
                                benner_list_advertise.map((benner_list_advertise_get) => {
                                  return (
                                    console.log("benner_list_advertise_get", benner_list_advertise_get),

                                    (
                                      <div>
                                        {/* <Link to={"/Category/"}
                                state={
                                  {
                                    id: benner_list_advertise_get.redirect_id,
                                    category_name: benner_list_advertise_get.category_name

                                  }
                                  
                                }
                              > */}
                                        <Link to="#" onClick={() => getBrandId(benner_list_advertise_get.redirect_id)}>
                                          <img className="img_round4" src={image_url + benner_list_advertise_get.banner_image} alt="" />
                                        </Link>
                                      </div>
                                    )
                                  );
                                })}


                              {/* ====================start latest offer================== */}
                              <br />
                            </div>
                          </section>


                        </div>
                      )
                    );
                  })}




              </div>
            </div>

          )}




        {/* ===========================shop by category ========================= */}


        <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section ">

              {/* ====================start latest offer================== */}
              {loading ?
                (
                  <ScaleLoader

                    color={"#E27A7A"}
                    loading={loading}
                    // size={10}
                    className="loading setloader"
                  />
                ) : (
                  <Slider {...settings3} >
                    {benner_list &&
                      benner_list.map((benner_list_get) => {
                        return (
                          console.log("benner_list_get", benner_list_get.redirect_id),
                          (
                            <div >
                              <Link to="#" onClick={() => getBrandId(benner_list_get.redirect_id)}>
                                {/* <img className="img_round52" src={image_url + benner_short_banner_advertise_get.banner_image} alt="" /> */}
                                <div className="img-slide-inner ">
                                  <img className="img_round_set_new_slide " src={image_url + benner_list_get.banner_image} alt="" />
                                </div>
                              </Link>
                            </div>
                          )
                        );
                      })}



                  </Slider>
                )}
              {/* ====================start latest offer================== */}

            </div>
          </section>
        </div>
        {/* ======================banner slider ======================== */}
        {/* <div className="sliding-image">
          {loading ?
            (
              <ScaleLoader

                color={"#E27A7A"}
                loading={loading}
                // size={10}
                className="loading"
                style={{ left: "100%" }}
              />
            ) : (
              <Slider {...bannerSlidea}>
                {benner_list &&
                  benner_list.map((benner_list_get) => {
                    return (
                      console.log("benner_list_get", benner_list_get.redirect_id),

                      (
                        <Link to="#" onClick={() => getBrandId(benner_list_get.redirect_id)}>
                          <div className="img-slide-inner">
                            <img src={image_url + benner_list_get.banner_image} alt="" />
                          </div>
                        </Link>
                      )
                    );
                  })}
              </Slider>
            )}
        </div> */}
        {/* ======================banner slider ======================== */}






        {/* ===========================shop by brand==================== */}




        {/* ===================slider=================== */}

        {/* ====================Todays Offers================= */}
        <div className="main-below-slide">

          <section className="bckground-color-shop" >
            <div className="shop-section">
              <div className="top-heading">

                <Container>
                  <Row>
                    <Col xs={12} sm={9}>
                      <label htmlFor="" className="heading-black">
                      { t('Popular Item') }
                      </label>
                      <p className="paragraph">
                      { t('Boost your farm s productivity with our best-selling agriculture product - trusted and loved by farmers everywhere!') }
                        
                      </p>
                    </Col>
                    <Col xs={12} sm={3}>
                      <div className="btn-shop-home">
                        <Link to="/TrandingProduct">
                          <button className="btn-gray-common">
                          { t('View All') } <img src={arrow_white} alt="" className="ms-2" />
                          </button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Container>



                {/* {" "}
                <label htmlFor="" className="heading-red">
                <div className="btn-shop-home">
                <Link to="/TrandingProduct">
                  <button className="btn-gray-common">
                    View All <img src={arrow_white} alt="" className="ms-2" />
                  </button>
                </Link>
              </div>
                </label> */}



              </div>
              {/* ====================todays offers================== */}
              {loading ?
                (
                  <ScaleLoader

                    color={"#E27A7A"}
                    loading={loading}
                    // size={10}
                    className="loading setloader"
                  />
                ) : (
                  <div className="latest-offer">
                    <Carousel className="pad5"

                      responsive={responsive}>
                      {todays_offers &&
                        todays_offers.map((todays_offers_get) => {
                          const results = todays_offers_get.grand_total_without_discount - todays_offers_get.grand_total;
                          const saved = results / todays_offers_get.grand_total_without_discount;
                          const dp = saved * 100;
                          const result1 = dp.toString().slice(0, 5);
                          return (
                            console.log("average_rating", todays_offers_get.average_rating),
                           

                            (
                              <div className="">
                                <div style={{ width: '15rem' }}>
                                  <Link to={`/Detailslist/${todays_offers_get.id}`} state={todays_offers_get.id}>
                                    {/* <Link to={"/Detailslist/"} 
                          state={
                            {
                             id:todays_offers_get.id,
                             
                            }
                          }
                          > */}
                                    <Card className="shadowcard mrc  " >
                                      <div className="mrc2">
                                        <button type="button" class="btn btn-warning mrc3">{result1} % OFF</button>

                                      </div>
                                      {/* <img src={offer1} alt="" /> */}
                                      <HoverImage className="image_height_set " src={image_url1 + todays_offers_get.product_image} hoverSrc={image_url1 + todays_offers_get.product_image} />

                                      <Card.Body className="">
                                        <Card.Title>
                                          <div className="text_set">
                                              {todays_offers_get.product_name}
                                          </div>
                                        </Card.Title>

                                        <Card.Title>
                                          <div className="text_set">
                                              <div className="rating-container">
                                                <StarRating rating={todays_offers_get.average_rating} />
                                                &nbsp;
                                                <b className="rating-container1"  class="btn btn-warning mrc3">
                                                  {todays_offers_get.average_rating}
                                                </b>  
                                              </div>
                                          </div>
                                        </Card.Title>

                                         <Card.Text>
                                          <div>
                                            <b>{t("Available in")} : </b> &nbsp;
                                            
                                            <label>{todays_offers_get.base_packet_quantity}</label>&nbsp;
                                            <label>{todays_offers_get.base_packet_unit}</label> &nbsp;
                                           
                                          </div>
                                        </Card.Text>

                                        <Card.Text>
                                          <div className="text_set text_set3">
                                            {todays_offers_get.category.category_name}
                                          </div>
                                        </Card.Text>
                                        <Card.Text>
                                          <s>{todays_offers_get.grand_total_without_discount} Rs</s> &nbsp;&nbsp;
                                          <label className="text_set2"> {todays_offers_get.grand_total} Rs</label>

                                        </Card.Text>

                                        <Card.Text>
                                          {todays_offers_get.is_bks_assured === 1 ? (
                                            <img className="set-images" src={bksassurred} alt=""/>
                                          ) : null}
                                        </Card.Text>


                                        <Card.Text>
                                          <div className="text_set1">
                                            {results} Rs {t("Saved")}
                                          </div>

                                        </Card.Text>
                                      </Card.Body>

                                    </Card>
                                  </Link>
                                </div>
                              </div>
                            )
                          );
                        })}
                    </Carousel>
                  </div>
                )}
              {/* ====================Todays Offers================== */}


            </div>
          </section>
        </div>

        {/* ====================sponser product================= */}

        {/* ===================slider=================== */}
        <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section">

              {/* ====================start latest offer================== */}
              <Slider {...settings2}>
                {benner_short_banner_advertise &&
                  benner_short_banner_advertise.map((benner_short_banner_advertise_get) => {
                    return (
                      console.log("benner_short_banner_advertise_get", benner_short_banner_advertise_get),
                      (
                        <div>
                          <Link to="#" onClick={() => getBrandId(benner_short_banner_advertise_get.redirect_id)}>
                            <img className="img_round52" src={image_url + benner_short_banner_advertise_get.banner_image} alt="" />
                          </Link>
                        </div>
                      )
                    );
                  })}



              </Slider>
              {/* ====================start latest offer================== */}

            </div>
          </section>
        </div>

        <div className="main-below-slide">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">


                <label htmlFor="" className="heading-black">
                  {t("In-Demand Brands")}
                  
                </label>{" "}
                {/* <label htmlFor="" className="heading-red">
                  In Focus
                </label> */}
                <p className="paragraph">
                  {t("Discover the most sought-after brands in the agriculture industry - experience top-notch quality and performance for your farm!")}
                </p>
              </div>
              {/* ====================sponser product================== */}
              <div className="latest-offer">
                <Container>
                  <Row>
                    {benner_brand_banner &&
                      benner_brand_banner.map((benner_brand_banner_get) => {
                        return (
                          console.log("benner_brand_banner", benner_brand_banner_get),
                          (
                            <Col sm={3}>
                              {/* <Link 
                      to={
                        benner_brand_banner_get.redirect_to==="brand"
                        ?
                        "/Brand"
                        :
                        "/Brand"
                        }> */}
                              <Link to="#" onClick={() => getBrandId(benner_brand_banner_get.redirect_id)}>
                                <img className="img_round5" src={image_url + benner_brand_banner_get.banner_image} alt="" />
                              </Link>
                            </Col>
                          )
                        );
                      })}

                  </Row>
                </Container>
              </div>
              {/* ====================start latest offer================== */}

              {/* <div className="btn-shop-home">
                <Link to="/products">
                  <button className="btn-gray-common">
                    View All <img src={arrow_white} alt="" className="ms-2" />
                  </button>
                </Link>
              </div> */}
            </div>
          </section>
        </div>

        {/* ====================sponser product================= */}
        <div className="main-below-slide">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">

                <Container>
                  <Row>
                    <Col xs={12} sm={9}>
                      <label htmlFor="" className="heading-black">
                        {t("Combo offers")}
                      </label>
                      <p className="paragraph">
                        {t("Get everything you need in one convenient package with our agriculture combo offers - the perfect way to save time and money on your farming supplies!")}
                        
                      </p>
                    </Col>
                    <Col xs={12} sm={3}>
                      <div className="btn-shop-home">
                        <Link to="/SpecialComboOffers">
                          <button className="btn-gray-common">
                          { t('View All') } <img src={arrow_white} alt="" className="ms-2" />
                          </button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Container>



              </div>
              {/* ====================sponser product================== */}
              {loading ?
                (
                  <ScaleLoader

                    color={"#E27A7A"}
                    loading={loading}
                    // size={10}
                    className="loading setloader"
                  />
                ) : (
                  <div className="latest-offer">
                    <Carousel className="pad5"

                      responsive={responsive}>
                      {combo_offers &&
                        combo_offers.map((combo_offers_get) => {
                          const results = combo_offers_get.grand_total_without_discount - combo_offers_get.grand_total;
                          const saved = results / combo_offers_get.grand_total_without_discount;
                          const dp = saved * 100;
                          const result1 = dp.toString().slice(0, 5);

                          return (
                            console.log("combo_offers_get", image_url1 + combo_offers_get.product_image),
                            (
                              <div>
                                <div className="" style={{ width: '15rem' }}>
                                  <Link to={`/Detailslist/${combo_offers_get.id}`} state={combo_offers_get.id}>
        
                                    <Card className="shadowcard mrc" >
                                      {/* <img src={offer1} alt="" /> */}
                                      <div className="mrc2">
                                        <button type="button" class="btn btn-warning mrc3">{result1} % OFF</button>

                                      </div>

                                      <HoverImage className="image_height_set" src={image_url1 + combo_offers_get.product_image} hoverSrc={image_url1 + combo_offers_get.product_image} />
                                      <Card.Body>

                                        <Card.Title>
                                            
                                          <div className="text_set">
                                            {combo_offers_get.product_name}
                                          </div>
                                        </Card.Title>

                                        <Card.Title>
                                          <div className="text_set">
                                              <div className="rating-container">
                                                <StarRating rating={combo_offers_get.average_rating} />
                                                &nbsp;
                                                <b className="rating-container1" class="btn btn-warning mrc3">
                                                  {combo_offers_get.average_rating}
                                                </b>  
                                              </div>
                                          </div>
                                        </Card.Title>

                                        <Card.Text>
                                          <div>
                                            <label>{t("Available in")} :</label> &nbsp;
                                            <label>{combo_offers_get.base_packet_quantity}</label>
                                            <label>{combo_offers_get.base_packet_unit}</label>
                                          </div>
                                        </Card.Text>
                                        <Card.Text>
                                          <div className="text_set text_set3">
                                            {combo_offers_get.category.category_name}
                                          </div>
                                        </Card.Text>
                                        <Card.Text>
                                          <s>{combo_offers_get.grand_total_without_discount} Rs</s>&nbsp;&nbsp; {combo_offers_get.grand_total} Rs
                                        </Card.Text>

                                        <Card.Text>
                                          {combo_offers_get.is_bks_assured === 1 ? (
                                            <img className="set-images" src={bksassurred} alt=""/>
                                            ) : null}
                                        </Card.Text>

                                      </Card.Body>
                                    </Card>
                                  </Link>

                                </div>
                              </div>
                            )
                          );
                        })}
                    </Carousel>


                  </div>
                )}
              {/* ====================start latest offer================== */}


            </div>
          </section>
        </div>

        {/* ====================sponser product================= */}






        <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">
                <label htmlFor="" className="heading-black">
                  {t("Don't miss out")}
                </label>{" "}
                {/* <label htmlFor="" className="heading-red">
                  This Out
                </label> */}
                <p className="paragraph">
                  {t("Don't miss out on our exclusive offers - the ultimate solution for any farmer looking to elevate their farm to the next level!")}
                  
                </p>
              </div>

              {/* ====================screen images offer================== */}

              <Link to="#" onClick={() => getBrandId(benner_list_category_advertise1[1].redirect_id)}>
                <img className="img_round4" src={benner_list_category_advertise1 && benner_list_category_advertise1[1] && image_url + benner_list_category_advertise1[1].banner_image} alt="" />
              </Link>
              {/* ====================start latest offer================== */}

            </div>
          </section>
        </div>




        <div className="main-below-slide">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">
                <label htmlFor="" className="heading-black">
                  {t("Handpicked for you")}
                </label>{" "}
                {/* <label htmlFor="" className="heading-red">
                  For You
                </label> */}
                <p className="paragraph">
                  {t("Experience personalized farming solutions with our handpicked selection - tailored to meet the unique needs of your farm")}
                </p>
              </div>
              {/* ====================sponser product================== */}
              <div className="latest-offer">
                <Container>
                  <Row>
                    {/* benner_sponserd_product */}
                    {benner_sponserd_product &&
                      benner_sponserd_product.map((benner_sponserd_product_get) => {
                        return (
                          console.log("benner_sponserd_product_get", benner_sponserd_product_get),
                          (
                            <Col sm={3}>
                              <Link to={`/Detailslist/${benner_sponserd_product_get.redirect_id}`} state={benner_sponserd_product_get.redirect_id}>
                                {/* <Link to="#" onClick={() => getBrandId(benner_sponserd_product_get.redirect_id)}> */}
                                <img className="img_round5" src={image_url + benner_sponserd_product_get.banner_image} alt="" />
                              </Link>
                            </Col>
                          )
                        );
                      })}

                  </Row>
                </Container>
              </div>
              {/* ====================start latest offer================== */}

              {/* <div className="btn-shop-home">
                <Link to="/products">
                  <button className="btn-gray-common">
                    View All <img src={arrow_white} alt="" className="ms-2" />
                  </button>
                </Link>
              </div> */}
            </div>
          </section>
        </div>




        <div className="main-below-slide">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">

                <Container>
                  <Row>
                    <Col xs={12} sm={9}>
                      <label htmlFor="" className="heading-black">
                        {t("Find the newest discounts")}
                      </label>
                      <p className="paragraph">
                        {t("Uncover the latest deals and save big on your farming needs - shop our newest discounts and take your farm to the next level!")}
                      </p>
                    </Col>
                    <Col xs={12} sm={3}>
                      <div className="btn-shop-home">
                        <Link to="/products">
                          <button className="btn-gray-common">
                          { t('View All') } <img src={arrow_white} alt="" className="ms-2" />
                          </button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Container>



              </div>



              {/* ====================start latest offer================== */}
              {loading ?
                (
                  <ScaleLoader

                    color={"#E27A7A"}
                    loading={loading}
                    // size={10}
                    className="loading setloader"
                  />
                ) : (
                  <div className="latest-offer">
                    <Carousel className="pad5"

                      responsive={responsive}>

                      {latest_offers &&
                        latest_offers.map((latest_offers_get) => {
                          const results = latest_offers_get.grand_total_without_discount - latest_offers_get.grand_total;
                          const saved = results / latest_offers_get.grand_total_without_discount;
                          const dp = saved * 100;
                          const result1 = dp.toString().slice(0, 5);
                          return (
                            console.log("latest_offers_get", image_url1 + latest_offers_get.product_image),
                            (
                              <div>
                                <div className="" style={{ width: '15rem' }}>
                                <Link to={`/Detailslist/${latest_offers_get.id}`} state={latest_offers_get.id}>
                                    <Card className="shadowcard mrc" >
                                    <div className="mrc2">
                                        <button type="button" class="btn btn-warning mrc3">{result1} % OFF</button>
                                      </div>
                                      {/* <img src={offer1} alt="" /> */}

                                      <HoverImage className="image_height_set" src={image_url1 + latest_offers_get.product_image} hoverSrc={image_url1 + latest_offers_get.product_image} />
                                      <Card.Body>
                                     

                                        <Card.Title>

                                          <div className="text_set">
                                            {latest_offers_get.product_name}
                                          </div>

                                        </Card.Title>
                                        <Card.Title>
                                            <div className="text_set">
                                              <div className="rating-container">
                                              <StarRating rating={latest_offers_get.average_rating} />
                                              &nbsp;
                                              <b className="rating-container1" class="btn btn-warning mrc3">
                                              {latest_offers_get.average_rating}
                                              </b> 
                                              </div>
                                            </div>
                                      </Card.Title>

                                        <Card.Text>
                                          <div>
                                            <label>{t("Available in")}: </label>&nbsp;
                                            <label>{latest_offers_get.base_packet_quantity} </label>&nbsp;
                                            <label>{latest_offers_get.base_packet_unit} </label>
                                          </div>
                                        </Card.Text>

                                        <Card.Text>
                                          <div className="text_set text_set3">
                                            {latest_offers_get.category.category_name}
                                          </div>
                                        </Card.Text>
                                        <Card.Text>
                                          <s>{latest_offers_get.grand_total_without_discount} Rs</s>&nbsp;&nbsp;{latest_offers_get.grand_total} Rs
                                        </Card.Text>
                                        <Card.Text>
                                          {latest_offers_get.is_bks_assured === 1 ? (
                                            <img className="set-images" src={bksassurred} alt=""/>
                                            ) : null}
                                        </Card.Text>
                                        <Card.Text>
                                          <div className="text_set1">
                                            {results} Rs {t("Saved")}
                                          </div>
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  </Link>

                                </div>
                              </div>
                            )
                          );
                        })}
                    </Carousel>
                  </div>
                )}
              {/* ====================start latest offer================== */}

            </div>
          </section>
        </div>




        {/* ===================slider=================== */}








        {/* ===================slider=================== */}

        <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">
                <label htmlFor="" className="heading-black">
                  {t("Don't miss out")}
                </label>{" "}
                {/* <label htmlFor="" className="heading-red">
                  This Out
                </label> */}
                <p className="paragraph">
                {t("Don't miss out on our exclusive offers - the ultimate solution for any farmer looking to elevate their farm to the next level!")}
                </p>
              </div>
              {/* ====================screen images offer================== */}

              {/* <Link
               to={
                benner_list_category_advertise1&&benner_list_category_advertise1[0]&&"/"+benner_list_category_advertise1[0].redirect_to==="brand"
                ?
                "/Brand"
                :
                "/Brand"
                }> */}
              <Link to="#" onClick={() => getBrandId(benner_list_category_advertise1[0].redirect_id)}>
                <img className="img_round4" src={benner_list_category_advertise1 && benner_list_category_advertise1[0] && image_url + benner_list_category_advertise1[0].banner_image} alt="" />
                {/* <input type={"file" }id="profilePic" style={{display:"none"}}  onChange={(e)=>SetUserDetails({...userDetails,profile_pic:e.target.files[0]})}/> */}
              </Link>


              {/* ====================start latest offer================== */}
              <br />
            </div>
          </section>
        </div>

        {/* ===================slider=================== */}



        <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section">

              <div className="top-heading">

                <Container>
                  <Row>
                    <Col xs={12} sm={9}>
                      <label htmlFor="" className="heading-black">
                        {t("Shop By Brand")}
                      </label>
                      <p className="paragraph">
                     {t("Discover the most sought-after brands in the agriculture industry - experience top-notch quality and performance for your farm!")}
                      </p>
                    </Col>
                    <Col xs={12} sm={3}>
                      <div className="btn-shop-home">
                        <Link to="/Brand">
                          <button className="btn-gray-common">
                          { t('View All') } <img src={arrow_white} alt="" className="ms-2" />
                          </button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Container>



              </div>

              {/* ====================start latest offer================== */}
              {loading ?
                (
                  <ScaleLoader

                    color={"#E27A7A"}
                    loading={loading}
                    // size={10}
                    className="loading setloader"
                  />
                ) : (
                  <Slider {...settings1} className="sbbpt">
                    {my_brand &&
                      my_brand.map((my_brand_get) => {
                        return (
                          console.log("my_brand_get", image_url + my_brand_get.brand_image),
                          (
                            <div >
                              <Link to="#" onClick={() => getBrandId(my_brand_get.id)}>
                                <img className="img_round2 hover-shadow" src={image_url + my_brand_get.brand_image} alt="" />
                              </Link>
                              {/* <Card.Body>
                      <Card.Title>{my_brand_get.brand_name}</Card.Title>
                    </Card.Body> */}
                            </div>
                          )
                        );
                      })}


                  </Slider>

                )}



            </div>
          </section>
        </div>




        <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section">

              {/* ====================start latest offer================== */}
              <Slider {...settings2}>
                {benner_list &&
                  benner_list.map((benner_list_get) => {
                    return (
                      console.log("benner_list_get", benner_list_get),

                      (
                        <div>
                          <Link to="#" onClick={() => getBrandId(benner_list_get.redirect_id)}>
                            <img className="img_round3" src={image_url + benner_list_get.banner_image} alt="" />
                          </Link>
                        </div>
                      )
                    );
                  })}

              </Slider>
              {/* ====================start latest offer================== */}

            </div>
          </section>
        </div>


        {/* ===================slider=================== */}
        {/* <div className="main-below-slide">
          <section className="bckground-color-shop">
            <div className="shop-section"> */}

        {/* ====================screen images offer================== */}
        {/* <Link to={benner_list_category_advertise1&&benner_list_category_advertise1[1]&&"/"+benner_list_category_advertise1[1].redirect_to}>
              <img className="img_round4" src={benner_list_category_advertise1&&benner_list_category_advertise1[1]&&image_url+benner_list_category_advertise1[1].banner_image} alt="" />
              </Link> */}
        {/* ====================start latest offer================== */}
        {/* <div className="btn-shop-home">
                <Link to="/TrandingProduct">
                  <button className="btn-gray-common">
                    View All <img src={arrow_white} alt="" className="ms-2" />
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </div> */}
        {/* ===================slider=================== */}



        {/* ====================How it Works================= */}


        <div className="main-below-slide how_it_work_padding">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">

                <label htmlFor="" className="heading-red">
                  {t("How it Works")}
                </label>
                <p className="paragraph">
                  {t("To order from us, please follow these simple steps")}
                </p>
              </div>
              {/* ====================How it Works================== */}
              <div>

                <Container>

                  <Row className="hw">

                  <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork1} />
                        <h5 className="foothead">{t("Place an order")}</h5>
                        <p className="footparagraph">
                        {t("Choose from a wide range of daily essentials product's")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center ">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork2} />
                        <h5 className="foothead">{t("Your order is on its way")}</h5>
                        <p className="footparagraph">
                        {t("Your Delivery Partner Will Be At Your Door Shortly")}
                        </p>
                      </div>
                    </Col>

                    <Col xs={4} sm={4} className="text-center">
                      <div className="hwdiv" >
                        <img className="imghw" src={howitwork3} />
                        <h5 className="foothead">{t("Enjoy")}</h5>
                        <p className="footparagraph">
                        {t("Avail the best quality products")}
                        </p>
                      </div>
                    </Col>

                  </Row>

                </Container>

                
              </div>
              {/* ====================How it Works================== */}

            </div>
          </section>
        </div>




        {/* <div className="main-below-slide">

          <section className="bckground-color-shop">
            <div className="shop-section">
              <div className="top-heading">

                <label htmlFor="" className="heading-red">
                  How it Works
                </label>
                <p className="paragraph">
                  To order from us, please follow these simple steps.
                </p>
              </div>
        
              <div className="latest-offer">

                <div className="latest-offer">
                  <Container>
                    <Row className="workDiv">
                      <Col xs={12} sm={4}>
                        <Card style={{ width: '15rem' }}>
                        <Card.Img variant="top"  src={howitwork1} />
                          <Card.Body>
                            <Card.Title>Place an order</Card.Title>
                            <Card.Text>
                            Choose from a wide range of daily essentials 
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} sm={4}>
                      <Card style={{ width: '15rem' }}>
             
                          <Card.Img variant="top" src={howitwork2} />
                          <Card.Body>
                            <Card.Title>Your order is on its way</Card.Title>
                            <Card.Text>
                            Your Deliver Partner Will Be At Your Door Shortly 
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} sm={4}>
                      <Card style={{ width: '15rem' }}>
                      <Card.Img variant="top" src={howitwork3} />
                          <Card.Body>
                            <Card.Title>Enjoy</Card.Title>
                            <Card.Text>
                            Choose from a wide range of daily essentials
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                  
                </div>
              </div>
    

            </div>
          </section>
        </div> */}

        {/* ====================How it Works================= */}


        {/* ===================special combo offers============================ */}
        <div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
