import React, { useContext, useEffect, useState } from "react";
import Footer from "../SharedComponents/Footer";
import Navbar from "../SharedComponents/Navbar";
import ReactStars from "react-rating-stars-component";

import offer2 from "../assets/img/offer2.jpg";
import offer4 from "../assets/img/offer4.jpg";
import arrow_white from "../assets/images/view_all_arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext ";
import { Container, Row, Col } from 'react-grid-system';
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { notifyError, notifySuccess } from "../SharedComponents/notify";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";


const MyAddress = () => {
    const image_url = "http://bharatkrushiseva.in/media/"
    const { customerData } = useContext(AppContext);
    const navigate = useNavigate();
    // console.log("customerData", customerData)
    // console.log("image_url", image_url + customerData.user_image)

    const [state, setState] = useState({
        house_name: "",
        area_name: "",
        landmark:"",
        pincode:"",
        state:"",
        user_id: customerData.id,
        district:"",
        taluka:"",
        village:"",
        mobile:""

    });

    const [errMsg, setErrMsg] = useState({
        house_name: "",
        area_name: "",
        landmark:"",
        pincode:"",
        state:"",
        district:"",
        taluka:"",
        village:"",
        mobile:""
         });
    const [data, setData] = useState({});
    const handleSubmit = (e) => {
    e.preventDefault();
    if (state.house_name === "") {
        setErrMsg({ ...errMsg, house_name: t("Enter House Name") });
        return;
    }
    if (state.area_name === "") {
        setErrMsg({ ...errMsg, area_name: t("Enter Area Name") });
        return;
        }

        if (state.landmark === "") {
        setErrMsg({ ...errMsg, landmark: t("Enter landmark")});
        return;
        }
        if (state.pincode === "") {
        setErrMsg({ ...errMsg, pincode: t("Enter pincode") });
        return;
        }
        if (state.state === "") {
        setErrMsg({ ...errMsg, state: t("Enter state") });
        return;
        }

        if (state.district === "") {
        setErrMsg({ ...errMsg, district: t("Enter district") });
        return;
        }
        if (state.taluka === "") {
        setErrMsg({ ...errMsg, taluka: t("Enter taluka") });
        return;
        }
        if (state.village === "") {
        setErrMsg({ ...errMsg, village: t("Enter village") });
        return;
        }
        if (state.mobile === "") {
        setErrMsg({ ...errMsg, mobile: t("Enter mobile") });
        return;
        }
        else{
        postWithAuthCallWithErrorResponse(
            ApiConfig.ADD_NEW_ADDRESS,
            JSON.stringify({ ...state, })
            )
            .then((res) => {
            console.log("res", res.json);
            setData(res.json.data);
            if  (res.json.status==="true")
          {
            notifySuccess(res.json.message)
          }
          else{
            notifyError(res.json.message)
          } 
            
            navigate("/MyAddress");
        
            })
            .catch((err) => console.log(err));
        
        }
              
        }

    const { t } = useTranslation();
useEffect(() => {
  //alert('Page is loaded successfully');
  //get the current language stored in the local storage
  let currentLang = localStorage.getItem('lang');
  i18n.changeLanguage(currentLang);
}, [])
    return (
        <main>
            <Navbar />

            <div className="offers-bg">
                <div className="top-heading">
                    <label htmlFor="" className="heading-red">
                        {t("My Address")}
                    </label>
                    <p className="paragraph">
                        {t("Shop from top Offers across the world")}
                    </p>
                </div>

                <div className=" inner-card-section  ">
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("House Name")} </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"

                                        onChange={(e) => {
                                            setErrMsg({ ...errMsg, house_name: "" });
                                            setState({ ...state, house_name: e.target.value });
                                        }}
                                    />
                                </div>
                                {
                                    <div className="text-center text-danger">
                                        <span> {errMsg.house_name}</span>
                                    </div>
                                }
                            </Col>
                            <Col sm={4}>

                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Area Name")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"

                                        onChange={(e) => {
                                            setErrMsg({ ...errMsg, area_name: "" });
                                            setState({ ...state, area_name: e.target.value });
                                        }}
                                    />
                                </div>
                                {
                                    <div className="text-center text-danger">
                                        <span> {errMsg.area_name}</span>
                                    </div>
                                }
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Landmark")} </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        onChange={(e) => {
                                            setErrMsg({ ...errMsg, landmark: "" });
                                            setState({ ...state, landmark: e.target.value });
                                        }}
                                    />
                                </div>
                                {
                                    <div className="text-center text-danger">
                                        <span> {errMsg.landmark}</span>
                                    </div>
                                }
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Pin Code")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        onChange={(e) => {
                                            setErrMsg({ ...errMsg, pincode: "" });
                                            setState({ ...state, pincode: e.target.value });
                                        }}
                                    />
                                </div>
                                {
                                    <div className="text-center text-danger">
                                        <span> {errMsg.pincode}</span>
                                    </div>
                                }
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("state")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        onChange={(e) => {
                                            setErrMsg({ ...errMsg, state: "" });
                                            setState({ ...state, state: e.target.value });
                                        }}
                                    />
                                </div>
                                {
                                    <div className="text-center text-danger">
                                        <span> {errMsg.state}</span>
                                    </div>
                                }
                            </Col>
                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("district")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        onChange={(e) => {
                                            setErrMsg({ ...errMsg, district: "" });
                                            setState({ ...state, district: e.target.value });
                                        }}
                                    />
                                </div>
                                {
                                    <div className="text-center text-danger">
                                        <span> {errMsg.district}</span>
                                    </div>
                                }
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Taluka")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        onChange={(e) => {
                                            setErrMsg({ ...errMsg, taluka: "" });
                                            setState({ ...state, taluka: e.target.value });
                                        }}
                                    />
                                </div>
                                {
                                    <div className="text-center text-danger">
                                        <span> {errMsg.taluka}</span>
                                    </div>
                                }
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Village")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        onChange={(e) => {
                                            setErrMsg({ ...errMsg, village: "" });
                                            setState({ ...state, village: e.target.value });
                                        }}
                                    />
                                </div>
                                {
                                    <div className="text-center text-danger">
                                        <span> {errMsg.village}</span>
                                    </div>
                                }
                            </Col>

                            <Col sm={4}>
                                <div class="form-group form_set">
                                    <label for="exampleInputEmail1" className="fon_size_fix">{t("Mobile Number")}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        onChange={(e) => {
                                            setErrMsg({ ...errMsg, mobile: "" });
                                            setState({ ...state, mobile: e.target.value });
                                        }}
                                    />
                                </div>
                                {
                                    <div className="text-center text-danger">
                                        <span> {errMsg.mobile}</span>
                                    </div>
                                }
                            </Col>
                            <Col sm={12}>
                                <div class="form-group form_set">
                                <Link to="#" onClick={handleSubmit}>
                                    <button type="button" class="btn btn-warning btnfix1">{t("Add Addres")}</button>
                                </Link>
                                </div>
                            </Col>





                        </Row>
                    </Container>
                </div>
            </div>

            <Footer />
        </main>
    );
};

export default MyAddress;
